
import "./checkout-address-form-v1"
import "./checkbox-v1"
import CheckoutAddressUtils from "./checkout-address-utils-v1"
import { CF2Component } from 'javascript/lander/runtime'

export default class CheckoutBillingAddressV1 extends CF2Component {

constructor(el, runtimeSel) {
super(el, runtimeSel)
}

mount () {
    this.type = 'billing'
    this.getCheckbox().addEventListener('click', (evt) => {
      evt.stopPropagation();
      const value = !!evt.target.checked
      Checkout.store.billingSameAsShipping.set(value)
      if (!value) {
        const shippingData = Checkout.store.shipping.get()
        const newBillingData = CheckoutAddressUtils.parseAddressByFields(shippingData, this.fields, false)
        Checkout.store.billing.set(newBillingData)
      }
    })

    Checkout.store.billingSameAsShipping.listen((value) => {
      this.getCheckbox().checked = value
      if (value) {
        this.hideForm()
      } else {
        this.showForm()
      }
    })

    nanostores.computed([Checkout.computed.hasPhysicalProducts, Checkout.store.billingSameAsShipping], 
      (hasPhysicalProducts, billingSameAsShipping) => ({hasPhysicalProducts, billingSameAsShipping})).
      subscribe(({hasPhysicalProducts, billingSameAsShipping}) => {
        if (hasPhysicalProducts) {
          this.showBillingSameAsShipping()
          if (billingSameAsShipping) {
            this.hideForm()
          } else {
            this.showForm()
          }
        } else {
          this.hideBillingSameAsShipping()
          this.showForm()
        }
      })

    nanostores.computed([Checkout.store.payment.type], (paymentType) => paymentType != 'apple-pay').subscribe(display => {
      if (display) {
        this.show()
      } else {
        this.hide()
      }
    })
  }

  show() {
    this.element.classList.remove('elHide')
  }
  hide() {
    this.element.classList.add('elHide')
  }
  showForm() {
    this.element.querySelector('.elBillingForm').classList.remove('elHide')
  }
  hideForm() {
    this.element.querySelector('.elBillingForm').classList.add('elHide')
  }
  getCheckboxContainer() {
    return this.element.querySelector('.elShippingSameAsBillingContainer')
  }
  getCheckbox() {
    return this.getCheckboxContainer().querySelector('input')
  }
  showBillingSameAsShipping() {
    this.getCheckboxContainer().classList.remove('elHide')
  }
  hideBillingSameAsShipping() {
    this.getCheckboxContainer().classList.add('elHide')
  }



}

window["CheckoutBillingAddressV1"] = CheckoutBillingAddressV1

