
           import  { atom, map, computed, onMount } from "nanostores"
          import CheckoutInputValidator from "./checkout-input-validator-v1"
import ExistingOrders from "./checkout-helpers-existing-orders-v1"
import CheckoutAddressUtils from "./checkout-address-utils-v1"
          // One-Click Checkout Flow
  const AuthMode = {
    Initializing: "initializing",
    AlreadySavedMode: "started-in-saved-mode",
    AwaitingEmail: "awaiting-email",
    FetchingEmailCheck: "fetching-email-check",
    AwaitingOtp: "awaiting-otp",
    FetchingOtpValidation: "fetching-otp-validation",
    InvalidOtp: "invalid-otp",
    ValidOtp: "valid-otp",
    FetchingResendOtp: "fetching-resend-otp",
    FailedResendOtp: "failed-resend-otp",
    TryingAutoPhoneVerification: "trying-auto-phone-verification",
    SuggestingPhoneVerification: "suggesting-phone-verification",
    RequestingPhoneVerification: "requesting-phone-verification",
    RequestingAnotherPhoneVerificationCode: "requesting-another-phone-code",
    SentPhoneVerificationOtp: "sent-phone-verification-otp",
    FailedRequestPhoneVerification: "failed-request-phone-verification",
    SubmittingPhoneVerificationCode: "submitting-phone-verification",
    PhoneVerificationSubmitError: "error-submitting-phone-verification",
    PhoneVerificationSucceeded: "notify-phone-verified",
    FailedPhoneVerification: "failed-phone-verification",
    ContinuingAsSaved: "continuing-as-saved",
    ContinuingAsGuest: "continuing-as-guest"
  }

    const PhoneVerificationOtpStates = new Set([      
      AuthMode.RequestingAnotherPhoneVerificationCode,
      AuthMode.SubmittingPhoneVerificationCode,
      AuthMode.PhoneVerificationSubmitError,
      AuthMode.SentPhoneVerificationOtp,
      AuthMode.FailedPhoneVerification
    ])

    const PhoneVerificationRequestStates = new Set([
      AuthMode.SuggestingPhoneVerification,      
      AuthMode.RequestingPhoneVerification,      
      AuthMode.FailedRequestPhoneVerification      
    ])

    const PhoneVerificationDialogStates = new Set([
      AuthMode.PhoneVerificationSucceeded,    
    ]      
      .concat([...PhoneVerificationRequestStates])
      .concat([...PhoneVerificationOtpStates])
    )

  // states that require OTP login dialog be displayed
  const AuthDialogStates = new Set([
    AuthMode.AwaitingOtp,
    AuthMode.FetchingOtpValidation,
    AuthMode.InvalidOtp,
    AuthMode.FetchingResendOtp,
    AuthMode.FailedResendOtp,
    // AuthMode.TryingAutoPhoneVerification,
    ...PhoneVerificationDialogStates
  ])

  
  const AuthFlow = {
    ['*']: {
      submitOtp: AuthMode.FetchingOtpValidation,      
      resendOtp: AuthMode.FetchingResendOtp,
      changeOtpSource: AuthMode.FetchingResendOtp,
      continueAsGuest: AuthMode.ContinuingAsGuest
    },
    [AuthMode.Initializing]: {
        alreadySaved: AuthMode.AlreadySavedMode,
        noUser: AuthMode.AwaitingEmail
    },
    [AuthMode.AwaitingEmail]: {
        gotEmail: AuthMode.FetchingEmailCheck
    },
    [AuthMode.FetchingEmailCheck]: {
        sentOtp: AuthMode.AwaitingOtp,
        unrecognized: AuthMode.ContinuingAsGuest
    },
    [AuthMode.AwaitingOtp]: {
        submitOtp: AuthMode.FetchingOtpValidation,
        resendOtp: AuthMode.FetchingResendOtp,
    },
    [AuthMode.FetchingOtpValidation]: {
        validOtp: AuthMode.ValidOtp,        
        invalidOtp: AuthMode.InvalidOtp
    },
    [AuthMode.ValidOtp]: {
        continueAsSaved: AuthMode.ContinuingAsSaved,        
        suggestPhoneVerification: AuthMode.SuggestingPhoneVerification,
    },
    [AuthMode.InvalidOtp]: {
      resendOtp: AuthMode.FetchingResendOtp,      
    },     
    [AuthMode.FetchingResendOtp]: {
      sentOtp: AuthMode.AwaitingOtp,
      resendFailed: AuthMode.FailedResendOtp
    },
    [AuthMode.FailedResendOtp]: {

    },
    // phone verification flow
    [AuthMode.SuggestingPhoneVerification]:{
      skipPhoneVerification: AuthMode.ContinuingAsSaved,
      requestPhoneVerification: AuthMode.RequestingPhoneVerification,
    },
    [AuthMode.RequestingPhoneVerification]: {
      skipPhoneVerification: AuthMode.ContinuingAsSaved,
      sentPhoneVerificationOtp: AuthMode.SentPhoneVerificationOtp,
      failStartPhoneVerification: AuthMode.FailedRequestPhoneVerification,
      requestPhoneVerification: AuthMode.RequestingPhoneVerification
    },
    [AuthMode.FailedRequestPhoneVerification]: {
      skipPhoneVerification: AuthMode.ContinuingAsSaved,
      requestPhoneVerification: AuthMode.RequestingPhoneVerification
    },
    [AuthMode.RequestingAnotherPhoneVerificationCode]: {
      skipPhoneVerification: AuthMode.ContinuingAsSaved,
      sentPhoneVerificationOtp: AuthMode.SentPhoneVerificationOtp,
      failStartPhoneVerification: AuthMode.FailedRequestPhoneVerification,
      requestPhoneVerification: AuthMode.RequestingAnotherPhoneVerificationCode
    },
    [AuthMode.SentPhoneVerificationOtp]: {
      skipPhoneVerification: AuthMode.ContinuingAsSaved,
      requestPhoneVerification: AuthMode.RequestingAnotherPhoneVerificationCode,
      submitPhoneVerificationCode: AuthMode.SubmittingPhoneVerificationCode
    },
    [AuthMode.SubmittingPhoneVerificationCode]:{
      skipPhoneVerification: AuthMode.ContinuingAsSaved,
      verificationSucceeded: AuthMode.PhoneVerificationSucceeded,
      verificationFailed: AuthMode.FailedPhoneVerification,
      verificationSubmitError: AuthMode.PhoneVerificationSubmitError,
    },
    [AuthMode.FailedPhoneVerification]:{
      skipPhoneVerification: AuthMode.ContinuingAsSaved,
      submitPhoneVerificationCode: AuthMode.SubmittingPhoneVerificationCode,
      requestPhoneVerification: AuthMode.RequestingAnotherPhoneVerificationCode
    },
    [AuthMode.PhoneVerificationSucceeded]: {
      continueAsSaved: AuthMode.ContinuingAsSaved
    }
  }
 
  import { CF2ComponentSingleton } from 'javascript/lander/runtime'

  class CheckoutHelpersAuth extends CF2ComponentSingleton {
    constructor () {
      super()
    }
    initializeOneClickCheckoutFlow () {
      Checkout.auth = this
      // store
      
      this.store = Checkout.store.auth = {
        flow: map({
          from: undefined, 
          event: undefined,
          to: AuthMode.Initializing
        }),
        otpRequestFailure: map({
          time: undefined,
          retry_in: 0,
          error: undefined
        }),
        otpLoginSource: atom(undefined),
        phoneVerificationRequest: map({
          time: undefined,
          count: 0,
          rejections: 0,
          error: undefined
        }),
        phoneVerificationOtp: map({
          time: undefined,
          count: 0,
          rejections: 0,
          error: undefined
        })
      }
      this.store.mode = computed(this.store.flow, (change => change.to))
      
      const
        requireLogin = computed([this.store.mode], mode => AuthDialogStates.has(mode)),
        otpLoginOptions = computed(Checkout.store.contact_pending_auth, partialContact => {
          const options = []
          if (partialContact) {
            if (partialContact.is_phone_verified === true) {
              options.push('phone_number')
            }
            if (partialContact.email) {
              options.push('email')
            }
          }
          return options
        }),        
        suggestingPhoneVerification = computed([this.store.mode], mode => PhoneVerificationDialogStates.has(mode)),
        showingPhoneVerificationRequest = computed([this.store.mode], mode => PhoneVerificationRequestStates.has(mode)), 
        showingPhoneOtp = computed([this.store.mode], mode => PhoneVerificationOtpStates.has(mode)),         
        validGuestEmail = computed(
          [Checkout.store.checkout.mode, Checkout.store.contact, Checkout.computed.contactErrors, Checkout.computed.hideContactInformationForm],
          (mode, { email }, errors, hideContactInformationForm) => mode === "guest" && !errors?.fields?.email && !hideContactInformationForm && email
        ),
        // retrySeconds = $retrySeconds,
        submittingPhoneVerification = computed(Checkout.store.auth.mode, mode => (
          mode === AuthMode.SubmittingPhoneVerificationCode 
        )),
        RequestingPhoneVerification = computed(Checkout.store.auth.mode, mode => (
          mode === AuthMode.RequestingPhoneVerification
        )),
        phoneVerificationMessages = computed(Checkout.store.auth.mode, mode => (
          mode === AuthMode.RequestingPhoneVerification ?
            "Requesting a new code..." :
          // // TODO
          // mode === AuthMode.SentAnotherPhoneVerificationOtp ?
          //   "Resent code. Please check your messages." :
          ''
        )),
        phoneVerificationRequestErrorMsg = computed(Checkout.store.auth.mode, mode => {
          if ([
            AuthMode.FailedRequestPhoneVerification,
          ].includes(mode)) {
            const { numberTaken, errors } = Checkout.store.auth.flow.get().data 
            if (numberTaken) {
              return 'Sorry, that phone number is already taken'
            }
            if (error) {
            // this should not usually happen
              return `Error: ${error}` 
            }
            // There may be a network connection error
            return 'Unknown error. Please check your connection and try again.'
          }
          return ''
        }),        
        phoneVerificationErrorMsg = computed(Checkout.store.auth.mode, mode => {
          if (mode === AuthMode.FailedPhoneVerification) {
            return 'Invalid code. Please check and try again.'
          }
          if ([            
            AuthMode.PhoneVerificationSubmitError
          ].includes(mode)) {
            return 'Unknown error. Please check your connection and try again.'
          }
          return ''
        }),
        //showingPhoneVerificationRequest = computed([this.store.mode], mode => PhoneVerificationRequestStates.has(mode)),        
        submittingOtp = computed(Checkout.store.auth.mode, mode => (
          mode === AuthMode.FetchingOtpValidation 
        )),
        requestingAnotherOtp = computed(Checkout.store.auth.mode, mode => {
          return mode === AuthMode.FetchingResendOtp
        }),
        resentOtp = atom(false),
        otpRequestError = computed(Checkout.store.auth.otpRequestFailure, ({ error }) => error)
      ;

      otpLoginOptions.subscribe(options => {
        if (options && this.store.otpLoginSource.get() === undefined) {
          this.store.otpLoginSource.set(options[0])            
        }
      })
      // computed retry time
      const retrySeconds = atom(0);
      onMount(retrySeconds, () => {
        let ticking
        const unsub = this.store.otpRequestFailure.subscribe(({ time, retry_in }) => {
          stop()
          if (!retry_in) return;
          const t = new Date()
          t.setSeconds(t.getSeconds() + retry_in)
          
          const update = () => {
            const now = new Date()
            let diff = t - now
            if (diff <= 0) {
              diff = 0
              stop();                
            }
            retrySeconds.set(Math.ceil(diff/1000))
          }
          update()
          ticking = setInterval(update, 1000)
        })
        return () => {
          unsub() 
          stop()
        }
        function stop () {
          if (ticking) {
            clearInterval(ticking)
            ticking = undefined
          }
        }      
      })

      this.computed = Checkout.computed.auth = {
        validGuestEmail,
        requireLogin,
        otpLoginOptions,
        showingPhoneOtp, 
        submittingOtp,
        requestingAnotherOtp,
        retrySeconds,
        resentOtp,
        otpRequestError,
        suggestingPhoneVerification,
        showingPhoneVerificationRequest,
        submittingPhoneVerification,
        RequestingPhoneVerification,
        phoneVerificationRequestErrorMsg,
        phoneVerificationMessages,
        phoneVerificationErrorMsg
      }    
      // listeners
      Checkout.store.checkout.mode.listen((mode) => {
        if (mode === 'guest') {
          this.continueAsGuest()
          this.fetchSignOff()
            .catch(error => { console.log('error signing off', error) })
        }
      })
      this.store.flow.listen((change) => {
        this.onFlowChange(change)
      })
      const uniqueEmailsFound = new Set()
      this.computed.validGuestEmail.subscribe(email => {
        if (!email || uniqueEmailsFound.has(email)) return
        uniqueEmailsFound.add(email)
        this.send('gotEmail')
        this.fetchAuthentication(email)
      })
      // determine next state based on whether already in saved mode
      if (Checkout.store.checkout.mode.get() === 'saved') {
        this.send('alreadySaved')
      } else {
        this.send('noUser')
      }
    }
    onFlowChange ({ event, from, to, data }) {
      // when valid otp, auto-transition depending on source
      if (to === AuthMode.ValidOtp) {
        const { source } = data
        const lacksVerifiedPhone = 
          source === 'email' &&
          Checkout.store.contact_pending_auth.get().is_phone_verified !== true &&
          !sessionStorage.getItem('skipped-phone-verification')
        const isSmsEnabled = Checkout.store.contact_pending_auth.get().is_sms_enabled
        const nextEvent = isSmsEnabled && lacksVerifiedPhone ? 'suggestPhoneVerification' : 'continueAsSaved'
        Promise.resolve().then(() => this.send(nextEvent))        
      }
    }
    send (eventId, data) {
      const modeName = this.store.mode.get()
      const modeConfig = AuthFlow[modeName]
      let nextMode = modeConfig && modeConfig[eventId] || AuthFlow['*'][eventId]
      if (nextMode) {
        // nextMode = this.beforeSend(eventId, payload, nextMode) ?? nextMode
        this.store.flow.set({
          from: this.store.mode.get(),
          event: eventId,
          to: nextMode,
          data,
        })
        // this.store.mode.set(nextMode)
      }      
    }
    requestPhoneVerification () {
      const count = Checkout.auth.store.phoneVerificationRequest.get().count ?? 0      
      Checkout.auth.store.phoneVerificationRequest.setKey('count', count+1)
      this.fetchAddVerificationNumber()
        .then((result) => {
          if (result.ok) {
            Checkout.auth.store.phoneVerificationRequest.setKey('error', undefined)
            this.send('sentPhoneVerificationOtp')
          } else {            
            const count = Checkout.auth.store.phoneVerificationRequest.get().rejections ?? 0
            Checkout.auth.store.phoneVerificationRequest.setKey('rejections', count+1)
            this.send('failStartPhoneVerification', result)
          }
        })
        .catch(err => {
          console.log('request phone verification failed', err)
          Checkout.auth.store.phoneVerificationRequest.setKey('error', err)
          this.send('failStartPhoneVerification')
        })
      this.send('requestPhoneVerification')
    }

    /*
      /user_pages/api/v1/contacts/request_authentication.json
      Param: email
      Response:
      📌 400: {result: false, errors: 'Invalid email'}
      📌 404 (contact not found): {result: false}
      ✅ 200: {result: true, contact: {first_name: '', email: '', uuid: '', masked_phone: '', is_verified: '', is_phone_verified: '' }}
    */
    fetchAuthentication(email) {
      fetch(
        '/user_pages/api/v1/contacts/request_authentication.json?' + 
          new URLSearchParams({ email }), 
        { method: 'GET', }
      )
        .then(res => this.fetchedAuthentication(res))
        .catch(err => {
          // currently if this background operation fails, 
          // we just silently quit because user does not care
          // perhaps add retry/backoff logic here
          console.log('fetch exception', err)
        })

    }
    fetchedAuthentication (res) {
      if (res.ok) {
        return res.json().then(({ result, contact }) => {
          const sentOTP = !!result
          if (sentOTP) {            
            Checkout.store.contact_pending_auth.set({...(contact ?? {}), authenticated: false })
            this.send('sentOtp')
          }              
        })
      }
    }
    submitOtp (code) {
      this.send('submitOtp')
      this.fetchValidateCode(code)
    }
    resendCode (optionalSource) {
      this.send('resendOtp', { optionalSource })
      return this.fetchResendCode(optionalSource)
    }
    fetchResendCode(optionalSource) {
      const { email } = Checkout.store.contact_pending_auth.get()
      const source = optionalSource || Checkout.auth.store.otpLoginSource.get()
      this.store.otpRequestFailure.set({
        time: -1,
        retry_in: 0
      })
      return fetch(
        '/user_pages/api/v1/contacts/resend_otp.json', 
        {
          method: 'POST',
          headers: { "Content-Type": "application/json"},        
          body: JSON.stringify({          
            source: optionalSource || source,
            email
          }),
        }
      )
        .then(res => {
          if(res.ok) {
            Checkout.auth.store.otpLoginSource.set(source)            
          }
          return res
        })
        .then(res => this.fetchedResendCode(res))
        .catch(err => {
          this.fetchedResendCode(undefined, err)
        })    
    }
    fetchedResendCode (res, error) {    
      let sent 
      const fail = ({ retry_in, error } = {}) => {
        const time = new Date()
        this.store.otpRequestFailure.set({
          time,
          retry_in,
          error
        })
        this.send('resendFailed', { retry_in, time, error })
      }     
      if (res) {
        if(res.ok) {        
          return res.json().then((props) => {
            const { result, data } = props
            sent = result
            if (sent) {
              this.send('sentOtp')
            } else { 
              fail()
            }
          })
        } else {
          if (res.status === 400) {
            return res.json().then((props) => {
              const { retry_in } =  props
              fail({ retry_in })
            }).catch(fail)
          }
        }
      }
      fail({ error })
    }
    /*
      PATCH: /user_pages/api/v1/contacts/validate_and_sign_in.json
        2a. Param: {otp: '', email: '', source: 'email' | 'phone_number' }
        2b. Response
      404 (contact not found): {result: false}
      400 (invalid OTP): {result: false}
      200: {result: true, contact: {first_name: ‘’, email: ‘’, uuid: ‘’, masked_phone: ‘’, is_verified: ‘’, is_phone_verified: ‘’, payment_methods: [], shipping_addresses: [], billing_addresses: [] }}
    */
    fetchValidateCode (otp) {
      const { email, masked_phone, is_phone_verified } = Checkout.store.contact_pending_auth.get()
      const source = Checkout.auth.store.otpLoginSource.get()
      fetch('/user_pages/api/v1/contacts/validate_and_sign_in', {
        method: 'POST',
        credentials: 'include',
        headers: { "Content-Type": "application/json"},
        body: JSON.stringify({
          email,
          otp, 
          source,
        }),
      })
        .then(res => {
          if (res.ok) {
            return res.json().then(data => {
              const { result, contact } = data
              if (result) {
                this.backfillContactFields(contact)
                ExistingOrders.fetch(false).then(() => {
                  // NOTE: Possibly this will update the state of checkout to reactivation or upgradeDowngrade
                  ExistingOrders.updateStore()

                  // NOTE: We want to set saved mode only when the selected cart item is
                  // not updatable. If the item is updatable - it will automatically switch to
                  // upgradeDowngrade or reactivate mode and we dont need the intermediate switch
                  // to the saved mode.
                  if (!ExistingOrders.isSelectedCartItemUpdatable) {
                    Checkout.store.checkout.mode.set('saved')
                  } else {
                    // NOTE: We wanna have saved state as one of the last states of checkout
                    // Else when we switch from upgrade product to normal product -
                    // we will not be able to identify whether to switch to guest or saved mode
                    Checkout.store.checkout.lastModeIndependentOfCartItems.set("saved")
                  }

                  this.send('validOtp', { source })
                })
              } else {
                this.otpFailed()
              }
            })
          } else {
            this.otpFailed()
          }
        })        
        // Update the state with the received response
        .catch(err => {
          console.log('fetch auth exception', err)
          this.otpFailed()
        })
    }
    fetchAddVerificationNumber () {    
      console.log('fetchAddVerificationNumber')
      let { email, phone_number } = Checkout.store.contact.get()  
      return fetch('/user_pages/api/v1/contacts/request_otp_to_add_new_number', {
        method: 'POST',
        headers: { "Content-Type": "application/json"},
        body: JSON.stringify({
          email,
          phone_number,           
        }),
      }).then(res => {
        if (res.ok) {
          return res
        }
        return res.json().then(({ result, errors }) => {
          const errorResult = {
            ok: false,
            errors,
            numberTaken: errors === 'Phone number already taken'
          }
          return errorResult
        })
      })

    }
    submitVerifyPhoneCode (otp) {
      let { email, phone_number } = Checkout.store.contact.get()
      this.send('submitPhoneVerificationCode')
      const fail = (err) => {        
        
      }
      return fetch('/user_pages/api/v1/contacts/validate_and_associate_phone_number', {
        method: 'POST',
        headers: { "Content-Type": "application/json"},
        credentials: "same-origin",
        body: JSON.stringify({
          email,
          phone_number,
          otp    
        }),
      })
        .then(res => {
          if (res.ok) {
            this.send('verificationSucceeded')
          } else {
            this.send('verificationFailed')
          }
        })        
        .catch(err => {
          console.log('fetch auth exception', err)
          this.send('verificationSubmitError', { error: err })
        })
    }

    continueAsGuest () {            
      this.send('continueAsGuest')              
    }
    fetchSignOff () {
      // fire and forget sign_out
      return fetch('/contacts/sign_out', {
        method: 'DELETE',
        headers: { "Content-Type": "application/json"},
        credentials: "same-origin",        
      })
      .then(res => {        
        if (!res.ok) {
          console.log('sign_out failed', res)
          throw new Error("Sign out failed")
        }
        return res
      })
    }
    
    otpFailed () {
      this.send('invalidOtp')
    }

    backfillContactFields (data) {      
      const {
        email,
        first_name,
        last_name,
        phone_number,
        uuid,
        is_phone_verified,
        is_sms_enabled,
        masked_phone,
        payment_methods,
        billing_addresses,
        shipping_addresses,
      } = data

      let newBilling = {}
      if(billing_addresses && billing_addresses.length > 0) {
        const backfilled_billing_addresses = CheckoutAddressUtils.backfillAddressesId(billing_addresses)
        newBilling = backfilled_billing_addresses[0]
        Checkout.store.billing.set(newBilling)
        Checkout.store.billing_addresses.set(backfilled_billing_addresses)
      }else {
        Checkout.store.billing_addresses.set([])
      }

      let newShipping = {}
      if(shipping_addresses && shipping_addresses.length > 0) {
        const backfilled_shipping_addresses = CheckoutAddressUtils.backfillAddressesId(shipping_addresses)
        newShipping = backfilled_shipping_addresses[0]
        Checkout.store.shipping.set(newShipping)
        Checkout.store.shipping_addresses.set(backfilled_shipping_addresses)
      }else {
        Checkout.store.shipping_addresses.set([])
      }

      Checkout.store.billingSameAsShipping.set(Boolean(newBilling.id && newBilling.id == newShipping.id))

      if (payment_methods && payment_methods.length > 0) {
        Checkout.store.paymentMethods.set(
          [
            ...Checkout.store.paymentMethods.get() || [],
            ...payment_methods
          ]
        ) 
        if (!Checkout.store.payment.id.get()) {
          Checkout.store.payment.id.set(payment_methods[0].id)
        }

        Checkout.store.contact.set({
          ...Checkout.store.contact.get(),
          email,
          first_name,
          last_name,
          phone_number,
        })

        Checkout.store.contact_pending_auth.set({
          ...Checkout.store.contact.get(),
          email,
          first_name,
          last_name,
          phone_number,
          uuid,
          is_phone_verified,
          is_sms_enabled,
          masked_phone,
          authenticated: true,
        })

      }      
    }
    skipPhoneVerification () {
      sessionStorage.setItem("skipped-phone-verification", true)
      this.send('skipPhoneVerification')
    }
    continueAfterPhoneVerification () {
      this.send('continueAsSaved')
    }    
  }
  const checkoutHelpersAuth = CheckoutHelpersAuth.getInstance()
  export default checkoutHelpersAuth
        