import '../dist/checkout-v2.css'
import '../blueprints/checkout-input-validator-v1.ts'
import '../blueprints/checkout-number-formatter-v1.ts'
import '../blueprints/checkout-path-utils-v1.ts'
import '../blueprints/checkout-address-utils-v1.ts'
import '../blueprints/checkout-helpers-critical-errors-v1.ts'
import '../blueprints/checkout-helpers-cart-v1.ts'
import '../blueprints/checkout-helpers-existing-orders-v1.ts'
import '../blueprints/checkout-helpers-summary-v1.ts'
import '../blueprints/checkout-helpers-data-loader-v1.ts'
import '../blueprints/checkout-helpers-submit-v1.ts'
import '../blueprints/checkout-helpers-auth-v1.ts'
import '../blueprints/checkout-helpers-digital-wallet-v1.ts'
import '../blueprints/checkout-helpers-machine-v1.ts'
import '../blueprints/checkout-helpers-store-v1.ts'
import '../blueprints/checkout-globals-v1.ts'
import '../blueprints/checkout-saved-multiple-payments-preview-v1.ts'
import '../blueprints/checkout-configuration-error-v1.ts'
import '../blueprints/checkout-tos-v1.ts'
import '../blueprints/checkout-submit-notification-v1.ts'
import '../blueprints/checkout-coupon-form-v1.ts'
import '../blueprints/checkout-threeds-frame-v1.ts'
import '../blueprints/checkout-product-image-carousel-v1.ts'
import '../blueprints/checkout-order-summary-v1.ts'
import '../blueprints/checkout-address-form-v1.ts'
import '../blueprints/checkout-billing-address-v1.ts'
import '../blueprints/checkout-shipping-address-v1.ts'
import '../blueprints/checkout-multi-step-v2.ts'
import '../blueprints/checkout-login-v2.ts'
import '../blueprints/checkout-product-card-v2.ts'
import '../blueprints/checkout-product-card-bump-v2.ts'
import '../blueprints/checkout-product-select-v2.ts'
import '../blueprints/checkout-product-select-bump-v2.ts'
import '../blueprints/checkout-shipping-profile-select-v1.ts'
import '../blueprints/checkout-multiple-payments-v2.ts'
import '../blueprints/checkout-contact-form-v1.ts'
import '../blueprints/checkout-saved-address-form-v1.ts'
import '../blueprints/checkout-saved-billing-address-v1.ts'
import '../blueprints/checkout-saved-contact-details-v1.ts'
import '../blueprints/checkout-saved-multiple-payments-v1.ts'
import '../blueprints/checkout-loading-v1.ts'
import '../blueprints/checkout-order-details-content-v1.ts'
import '../blueprints/checkout-order-details-v1.ts'
import '../blueprints/checkout-submit-button-v1.ts'
import '../blueprints/checkout-v2.ts'
