
          
          
          import { CF2ComponentSingleton } from 'javascript/lander/runtime'
  import { CFErrorWithCause } from 'javascript/lander/utils/error_with_cause'
  
  class CheckoutHelpersCriticalErrors extends CF2ComponentSingleton {
    ERROR_CODES = {
      EMPTY_PRODUCTS_ERROR: 'EMPTY_PRODUCTS_ERROR',
      EMPTY_PAYMENT_METHODS_ERROR: 'EMPTY_PAYMENT_METHODS_ERROR',
      UNEXPECTED_INITIALIZATION_ERROR: 'UNEXPECTED_INITIALIZATION_ERROR',
      FETCH_FEATURE_FLAGS_ERROR: 'FETCH_FEATURE_FLAGS_ERROR',
      FETCH_COUNTRIES_STATES_ERROR: 'FETCH_COUNTRIES_STATES_ERROR',
      PAI_INITIALIZATION_SCRIPT_ERROR: 'PAI_INITIALIZATION_SCRIPT_ERROR',
      PAI_INITIALIZATION_READY_TIMEOUT_ERROR: 'PAI_INITIALIZATION_READY_TIMEOUT_ERROR',
      FETCH_EXISTING_ORDERS_ERROR: 'FETCH_EXISTING_ORDERS_ERROR',
      FETCH_PHONE_UTILS_ERROR: 'FETCH_PHONE_UTILS_ERROR',
    }

    displayAndLogCriticalError(checkoutCriticalError) {
      const criticalErrors = Checkout.store.criticalErrors.get()
      const originalError = checkoutCriticalError.cause
      if (originalError instanceof CFFetcherError) {
        const fetchError = originalError
        Checkout.store.criticalErrors.set([
          ...criticalErrors,
          { code: checkoutCriticalError.message, fetchErrorType: fetchError.type }
        ])
      } else {
        Checkout.store.criticalErrors.set([
          ...criticalErrors,
          { code: checkoutCriticalError.message }
        ])
      }
      // NOTE: this prints the stack traces with all causes in the console and also sends it to bugsnag
      // we need to render then separately here because the stacktrace showing all error causes is still 
      // not supported in browsers. So this call does the job of implementing it
      console.error(CFstackWithCauses(checkoutCriticalError))
      window.Bugsnag?.notify(checkoutCriticalError)
    }
  }

  const checkoutHelpersCriticalErrors = CheckoutHelpersCriticalErrors.getInstance()
  checkoutHelpersCriticalErrors.CheckoutCriticalError = class extends CFErrorWithCause {
    constructor(message, options) {
      super(message, options)
      this.name = 'CheckoutCriticalError'
    }
  }
  export default checkoutHelpersCriticalErrors
        