
import CheckoutHelpersMachine from "./checkout-helpers-machine-v1"
import { CF2Component } from 'javascript/lander/runtime'

export default class CheckoutMultiStepV2 extends CF2Component {

constructor(el, runtimeSel) {
super(el, runtimeSel)
}

FORM_STRUCTURE_BY_TOTAL_STEPS = {
    2: [['contact', 'shipping'], ['products', 'shippingOption', 'billing', 'payment', 'tos']],
    3: [['products'], ['contact', 'shipping'], ['shippingOption', 'billing', 'payment', 'tos']]
  }
  FORM_BY_STEP = Object.entries(this.FORM_STRUCTURE_BY_TOTAL_STEPS).reduce((acc1, item) => {
    const [totalSteps, form] = item
    acc1[totalSteps] = {}
    form.reduce((acc2, form, index) => {
      form.forEach((field) => {
        acc2[field] = index
      })
      return acc2
    }, acc1[totalSteps])
    return acc1
  }, {})

  mount() {
    this.totalSteps = parseInt(this.totalSteps)
    this.totalStepsArray = [ ...Array(this.totalSteps).keys() ]
    const body = this.element.querySelectorAll('.elMultiStepBody')
    const headers = this.element.querySelectorAll('.elMultiStepHeader')
    
    this.steps = this.totalStepsArray.map((i) => {
      return {
        body: body[i],
        header: headers[i]
      }
    })

    headers.forEach((header, stepNumber) => {
      header.addEventListener('click', (evt) => {
        evt.preventDefault();
        this.setStep(stepNumber)
      })
    })

    const buttons = this.element.querySelectorAll('[href="#!next-step"], [href="#!prev-step"], [href="#!open-step"]')
    buttons.forEach((button) => {
      const href = button.getAttribute('href')
      if(href === "#!next-step") {
        button.addEventListener('click', (evt) => {
          evt.preventDefault()
          if(button.dataset.disabled == 'true') return
          this.nextStep()
        })
      } else if(href === "#!prev-step") {
        button.addEventListener('click', (evt) => {
          evt.preventDefault()
          if(button.dataset.disabled == 'true') return
          this.prevStep()
        })
      }
    })
    
    Object.entries(Checkout.computed.errorsByName).forEach(([name, computed]) => {
      computed.subscribe((error) => {
        const currentStep = Checkout.store.checkout.step.get()
        let currentStepWithError = currentStep
        let currentErrorElement = this.element.querySelector('.elMultiStepBody[data-step-state="active"]').querySelector('.checkout-general-errors-wrapper')
        const stepForField = this.FORM_BY_STEP[this.totalSteps][name]
        if (stepForField != undefined) {
          if (Checkout.utils.hasErrors(error) && stepForField < currentStep) {
            // NOTE: usually we should avoid setting state within listeners in components,
            // ideally listeners in HTML components should only be changes to the DOM
            // but in this case we can make an exception as there is only multi step element in the DOM
            // the other reason is that the multi step element will be the one knowing
            // the form structure even if its dynamic.
            //
            // In the future we can refactor this logic and move this to another module
            // then we will need to think how to send the form structure to this global module
            // in the case it gets rendered dynamically
            this.setStep(stepForField)
            Checkout.store.incrScrollToFirstVisibleError.set(
              Checkout.store.incrScrollToFirstVisibleError.get() + 1
            )
          }
          currentErrorElement = this.element.querySelector(`.elMultiStepBody[data-step-number="${stepForField + 1}"]`).querySelector('.checkout-general-errors-wrapper')
          currentStepWithError = stepForField
        }
        const currentFormErrorStructure = this.FORM_STRUCTURE_BY_TOTAL_STEPS[this.totalSteps][currentStepWithError]
        const errors = currentFormErrorStructure.reduce((acc, formName) => {
          const error = Checkout.computed.errorsByName[formName].get()
          if (Checkout.utils.hasErrors(error)) {
            acc.push({name: formName, errors: error})
          }
          return acc
        }, [])
        if (!errors || errors.length == 0) {
          currentErrorElement.classList.add('elHide')
        } else {
          const error = errors[0]
          if (error.errors?.globalErrors?.[0]?.message) {
            const errorMessage = error.errors.globalErrors[0].message
            currentErrorElement.querySelector('span').innerHTML = errorMessage
            currentErrorElement.classList.remove('elHide')
          } else {
            currentErrorElement.classList.add('elHide')
          }
        }
      })
    })

    Checkout.store.checkout.step.listen((newStep) => {
      this.changeStep(newStep)
    })
  }

  changeStep(newStep) {
    const activeUiStep = this.element.querySelector(".elMultiStepBody[data-step-state='active']").dataset.stepNumber
    if (this.convertUiStepToData(activeUiStep) != newStep) {
      this.setStepActive(newStep)
    }
  }
  
  validateStep(step) {
    const currentStep = Checkout.store.checkout.step.get()
    const currentFormStructure = this.FORM_STRUCTURE_BY_TOTAL_STEPS[this.totalSteps][currentStep]
    
    const errors = currentFormStructure.reduce((acc, formName) => {
      Checkout.store.showAllErrors[formName].set(true)

      const error = Checkout.computed.errorsByName[formName].get()
      if (Checkout.utils.hasErrors(error)) {
        acc.push(error)
      }
      return acc
    }, [])

    return errors.length == 0
  }

  setStepActive(step) {
    this.totalStepsArray.forEach((stepNumber) => {
      const isCurrentStep = stepNumber == step
      const activeText =  isCurrentStep ? "active" : "inactive"

      this.steps[stepNumber].body.setAttribute("data-step-state", activeText);

      const header = this.steps[stepNumber].header
      if (header) {
        header.setAttribute("data-step-state", activeText);
        if (header.children.length > 0) {
          header.children[0].setAttribute("data-step-state", activeText)
        }
      }
    });

    $([document.documentElement, document.body]).animate({
      scrollTop: $(this.element).offset().top - 50
    }, 200);
  }

  setStep(step) {
    Checkout.store.checkout.step.set(step)
  }

  nextStep() {
    const currentStep = Checkout.store.checkout.step.get()
    if (this.validateStep(currentStep)) {
      this.collectLeads()
      this.addPAIDeps(currentStep + 1)
      this.setStep(currentStep + 1)
    } else {
      Checkout.store.incrScrollToFirstVisibleError.set(
        Checkout.store.incrScrollToFirstVisibleError.get() + 1
      )
    }
  }
  prevStep() {
    const step = Checkout.store.checkout.step.get() - 1
    this.setStep(step)
  }
  convertUiStepToData(step) {
    return parseInt(step) - 1
  }
  collectLeads() {
    const currentStep = Checkout.store.checkout.step.get()
    const currentFormStructure = this.FORM_STRUCTURE_BY_TOTAL_STEPS[this.totalSteps]?.[currentStep]
    if (currentFormStructure?.includes('contact')) {
      const contactDetails = Checkout.store.contact.get()
      CFDispatchEvent(CFEvents.FORM_SUBMITTED, {contact: contactDetails})
      fetch('/user_pages/api/contacts', {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        method: 'POST',
        body: JSON.stringify(contactDetails),
      }).then((response) => {
        if (response.ok) {
          CFDispatchEvent(CFEvents.FORM_SUBMITTED_OK, {contact: contactDetails})
        }
      })
    }
  }
  addPAIDeps(nextStep) {
    if (this.FORM_STRUCTURE_BY_TOTAL_STEPS[this.totalSteps][nextStep].includes('payment')) {
      CheckoutHelpersMachine.loadPAIDeps()
    }
  }



}

window["CheckoutMultiStepV2"] = CheckoutMultiStepV2

