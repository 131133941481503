
import CheckoutHelpersSubmit from "./checkout-helpers-submit-v1"
import "./checkout-submit-notification-v1"
import { CF2Component } from 'javascript/lander/runtime'

export default class CheckoutSubmitButtonV1 extends CF2Component {

constructor(el, runtimeSel) {
super(el, runtimeSel)
}

mount() {
    this.applePayMounted = nanostores.atom(false)
    this.storeButtonInitialHTML()

    this.getSubmitButton().addEventListener('click', (evt) => {
      evt.preventDefault()
      CheckoutHelpersSubmit.submitFromButtonClick(this.element)
    })

    const checkoutElement = this.element.closest('.elCheckout')
    this.getApplePayPreview().addEventListener('click', (evt) => {
      evt.preventDefault()
      CheckoutHelpersSubmit.checkSubmitShowErrors({ skipFields: new Set(['payment']) })
      CheckoutHelpersSubmit.checkSubmit(checkoutElement)
    })

    new MutationObserver((mutations) => {
      for (const mutation of mutations) {
        for(const addedNode of mutation.addedNodes) {
          if(addedNode.id == "rebilly-apple-pay-button") {
            this.applePayMounted.set(true)
          }
        }
        for(const removedNode of mutation.removedNodes) {
          if(removedNode.id == "rebilly-apple-pay-button") {
            this.applePayMounted.set(false)
          }
        }
      }
    }).observe(this.element.querySelector(".apple-pay-button"), {
      subtree: true,
      childList: true,
    });

    const computedState = nanostores.computed([
      Checkout.computed.isDigitalWalletReadyToStart,
      this.applePayMounted,
      Checkout.store.isUpdatingRebilly
    ], (isDigitalWalletReadyToStart, isMounted, isUpdatingRebilly) => {
      if (isUpdatingRebilly) {
        return 'loading'
      } else if (!isMounted || !isDigitalWalletReadyToStart) {
        return 'preview'
      } else {
        return 'ready'
      }
    })
    computedState.subscribe((state) => {
      this.getApplePayWrapper().dataset.state = state
    })
    const computedShowApplePay = nanostores.computed([
      Checkout.store.payment.id,
      Checkout.store.payment.type,
    ], (paymentId, paymentType)=> {
      return !paymentId && paymentType == 'apple-pay' && this.mode != 'upgradeDowngrade'
    })
    computedShowApplePay.subscribe((showApplePay) => {
      this.element.querySelector('.submit-button-wrapper').setAttribute('data-show-apple-pay', showApplePay)
    })

    Checkout.store.submitting.listen((submitting) => {
      const state = submitting.state
      switch (state) {
        case Checkout.SubmittingStates.ERROR: {
          this.setButtonInitialHTML()
          break;
        }
        case Checkout.SubmittingStates.IDLE: {
          this.setButtonInitialHTML()
          break;
        }
        case Checkout.SubmittingStates.START: {
          this.setSubmittingText()
          break;
        }
      }
    })
  }

  getSubmitButton() {
    return this.element.querySelector('[href="#submit-checkout-form"]')
  }

  getApplePayWrapper() {
    return this.element.querySelector('.apple-pay-button-wrapper')
  }

  getApplePayPreview() {
    return this.element.querySelector('.apple-pay-button-preview')
  }

  storeButtonInitialHTML() {
    const buttonMainText = this.element.querySelector('.elButtonMain .elButtonMainText')
    const buttonSubText = this.element.querySelector('.elButtonSub')

    this.buttonMainTextInitialHTML = buttonMainText.innerHTML
    this.buttonSubTextInitialHTML = buttonSubText.innerHTML
  }

  setButtonInitialHTML() {
    const buttonMain = this.element.querySelector('.elButtonMain')
    const buttonSub = this.element.querySelector('.elButtonSub')
    buttonMain.querySelector('.elButtonSpinner').style.removeProperty('display');

    const buttonMainText = buttonMain.querySelector('.elButtonMainText')
    buttonMainText.innerHTML = this.buttonMainTextInitialHTML
    buttonSub.innerHTML = this.buttonSubTextInitialHTML
  }

  setSubmittingText() {
    const buttonMain = this.element.querySelector('.elButtonMain')
    const buttonSub = this.element.querySelector('.elButtonSub')

    buttonMain.querySelector('.elButtonSpinner').style.display = 'inline-block'

    const buttonMainText = buttonMain.querySelector('.elButtonMainText')
    buttonMainText.innerHTML = this.submittingText
    buttonSub.innerHTML = ''
  }



}

window["CheckoutSubmitButtonV1"] = CheckoutSubmitButtonV1

