
          
          import Cart from "./checkout-helpers-cart-v1"
import ExistingOrders from "./checkout-helpers-existing-orders-v1"
import pathUtils from "./checkout-path-utils-v1"
import CheckoutAddressUtils from "./checkout-address-utils-v1"
          import { CF2ComponentSingleton } from 'javascript/lander/runtime'

  class PreviewError extends Error {
    constructor(message, details) {
      super(message);
      this.name = this.constructor.name;
      this.details = details;
      if (Error.captureStackTrace) Error.captureStackTrace(this, this.constructor);
    }
  }
  class CheckoutHelpersSummaryV1 extends CF2ComponentSingleton {
    #sendOrderPreviewTimer;
    #lastOrderPreviewData;

    #controllerOrderPreview;
    #orderPreviewSignal;

    #couponStateBeforeApplying;

    #pendingFetch = undefined

    #skipOrderSummaryUpdate;

    #createOrderPreviewSignal() {
      this.#controllerOrderPreview = new AbortController()
      this.#orderPreviewSignal = this.#controllerOrderPreview.signal
    }

    #setLoadingShippingOptions(dataToPreview) {
      // NOTE: if the  only data changed was shippingOption, we dont want to shipping loading state
      const { 
        selected_shipping_option: _tmpShippingOption1,
        ...dataToPreviewWithoutShippingOption 
      } = dataToPreview?.order ?? {}
      const {
        selected_shipping_option: _tmpShippingOption2,
        ...lastOrderPreviewDataWithoutShippingOption
      } = this.#lastOrderPreviewData?.order ?? {}

      if (!this.#deepEqual(dataToPreviewWithoutShippingOption, lastOrderPreviewDataWithoutShippingOption)) {
        Checkout.store.loadingShipping.set(true)
      }
    }

    skipOrderSummaryUpdateWithCb(cb) {
      this.#skipOrderSummaryUpdate = true
      cb()
      this.#skipOrderSummaryUpdate = false
    }

    sendOrderPreview(options) {
      const isCouponValidation = !!options?.isCouponValidation
      if (this.#skipOrderSummaryUpdate) return
      const billingFields = Checkout.store.billingFields.get()
      const shippingFields = Checkout.store.shippingFields.get()
      if (!Checkout.utils.hasValidDataForOrderPreview({billingFields, shippingFields})) {
        if (Checkout.store.summary.get().state != Checkout.SummaryStates.WAITING) {
          Checkout.store.summary.set({ state: Checkout.SummaryStates.WAITING })
        }
        this.#lastOrderPreviewData = null
        return
      }

      const dataToPreview = this.#buildOrderPreviewPayload()
      if (!isCouponValidation && this.#deepEqual(dataToPreview, this.#lastOrderPreviewData)) {
        return
      }

      this.#sendOrderPreviewTimer && clearTimeout(this.#sendOrderPreviewTimer);
      if (this.#controllerOrderPreview && this.#pendingFetch && !this.#pendingFetch.done && !this.#pendingFetch.aborted) {
        this.#controllerOrderPreview.abort();
      }

      if (Checkout.store.summary.get().state != Checkout.SummaryStates.CALCULATING) {
        if (Checkout.store.payment.type.get() == 'apple-pay') {
          Checkout.store.isUpdatingRebilly.set(true)
        }

        Checkout.store.summary.set({ state: Checkout.SummaryStates.CALCULATING })
      }

      const couponStateBeforeApplying = Checkout.store.coupons.state.get()
      if (isCouponValidation && couponStateBeforeApplying != Checkout.CouponStates.APPLYING) {
        this.#couponStateBeforeApplying = couponStateBeforeApplying
        Checkout.store.coupons.state.set(Checkout.CouponStates.APPLYING)
      }

      const thisFetch = this.#pendingFetch = {}
      
      this.#setLoadingShippingOptions(dataToPreview)

      clearTimeout(this.#sendOrderPreviewTimer);
      this.#sendOrderPreviewTimer = setTimeout(() => {
        return this.fetchOrderPreview({ thisFetch })
      }, 500)
    }

    fetchOrderPreview(options) {
      options = options ?? {}
      const thisFetch = options.thisFetch ?? {}
      const callRebillyUpdate = options.callRebillyUpdate ?? true
      const dataToPreview = this.#buildOrderPreviewPayload()
      this.#lastOrderPreviewData = dataToPreview
      let summaryData
      let orderPreviewError
      this.#createOrderPreviewSignal()
      return fetch(
        window.location.origin + pathUtils.renderedPath() + '/order_preview',
        {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
          method: 'POST',
          body: JSON.stringify(dataToPreview),
          signal: this.#orderPreviewSignal,
        }
      )
      .then((response) => response?.json())
      .then((data) => {
        if (!data) return
        if (data.error) throw new PreviewError(data.error, data.details)
        summaryData = data
      })
      .catch((error) => {
        if (error.name == "AbortError") {
          thisFetch.aborted = true            
        } else {
          console.error(error)
          orderPreviewError = error
        }
      })
      .finally(() => {
        if (thisFetch.aborted) {
          return
        }

        if (summaryData) {
          const { shipping_quotes_response, selected_shipping_option, billing_address} = summaryData

          // NOTE: avoids infinite loop
          this.skipOrderSummaryUpdateWithCb(() => {
            Checkout.store.shippingOptions.set(shipping_quotes_response?.options ?? [])            
            Checkout.store.shippingOption.set(selected_shipping_option)
            Checkout.store.loadingShipping.set(false)
            this.#backfillBillingAddress(summaryData)
          })

          const currentCode = Checkout.store.coupons.currentCode.get()
          const couponCodeApplied = summaryData.discounts?.find((discount) => discount.code?.toUpperCase() == currentCode?.toUpperCase())
          if (couponCodeApplied) {
            Checkout.store.coupons.appliedCode.set(currentCode)
            Checkout.store.coupons.state.set(Checkout.CouponStates.APPLIED)
          } else {
            Checkout.store.coupons.state.set(Checkout.CouponStates.READY)
          }

          const newSummaryStoreData = {
            state: Checkout.SummaryStates.OK,
            data: this.#processOrderPreviewResponseForSummary(summaryData)
          }
          Checkout.store.summary.set(newSummaryStoreData)
          this.updateRebillyTransactionData(callRebillyUpdate)
        } else if (orderPreviewError) {
          Checkout.store.loadingShipping.set(false)
          const error = orderPreviewError.details?.[0]
          if (error?.type == 'InvalidPostalCodeError') {
            Checkout.store.summary.set({
              state: Checkout.SummaryStates.WAITING,
            })
            Checkout.store.showAllErrors.billing.set(true)
            Checkout.store.billingApiErrorsByField.set({zip: { message: error.message }})
            this.updateRebillyTransactionData(false)
          } else if (error?.type == 'InvalidCouponError') {
            Checkout.store.coupons.appliedCode.set("")
            Checkout.store.coupons.errorMessage.set(error.message)
            Checkout.store.coupons.state.set(Checkout.CouponStates.ERROR)
            Checkout.store.summary.set({
              state: Checkout.SummaryStates.OK,
              data: this.#processOrderPreviewResponseForSummary(error.preview)
            })
            this.updateRebillyTransactionData(callRebillyUpdate)
          } else {
            Checkout.store.coupons.state.set(this.#couponStateBeforeApplying)
            const newSummaryStoreData = { state: Checkout.SummaryStates.ERROR }
            Checkout.store.summary.set(newSummaryStoreData)
            this.updateRebillyTransactionData(false)
          }
        }
        thisFetch.done = true
      })
    }
    
    #backfillBillingAddress(summaryData) {
      const checkoutBilling = Checkout.store.billing.get()
      if (summaryData.billing_address) {
        const {city: summaryCity, region: summaryRegion} = summaryData.billing_address
        const extraBillingAddress = {}
        if (!checkoutBilling.city) extraBillingAddress.city = summaryCity
        if (!checkoutBilling.state) extraBillingAddress.state = summaryRegion

        if (Object.keys(extraBillingAddress).length) {
          Checkout.store.billing.set({
            ...checkoutBilling,
            ...extraBillingAddress,
          })
        }
      }
    }

    #processOrderPreviewResponseForSummary(summaryData) {
      return ({
        currency: summaryData.currency,
        line_items: summaryData.line_items,
        total: {
          amount: parseFloat(summaryData.total_amount.amount),
          formatted: summaryData.total_amount_formatted,
        },
        discount: {
          amount: parseFloat(summaryData.discount_amount.amount),
          formatted: summaryData.discount_amount_formatted,
        },
        discounts: (summaryData.discounts ?? []).map((discount) => {
          return {
            name: discount.name,
            code: discount.code,
            amount_formatted: '-' + this.#formatNumberToCurrency(summaryData.currency_symbol, discount.amount)
          }
        }),
        subtotal: {
          amount: parseFloat(summaryData.subtotal_amount.amount),
          formatted: summaryData.subtotal_amount_formatted,
        },
        shipping: {
          amount: parseFloat(summaryData.shipping_amount.amount),
          formatted: summaryData.shipping_amount_formatted,
        },
        tax: {
          amount: parseFloat(summaryData.tax_amount.amount),
          formatted: summaryData.tax_amount_formatted,
        },
        ...(summaryData.upcoming_invoice?.interim_amount ? {
          interim: {
            amount: parseFloat(summaryData.upcoming_invoice.interim_amount.amount),
            formatted: summaryData.upcoming_invoice.interim_amount_formatted,
          },
        } : {}),
        ...(summaryData.upcoming_invoice ? {
          upcoming_invoice: {
            line_items: summaryData.upcoming_invoice.line_items.
              filter((i) => i.interim).
              map((item) => {
                return {
                  variant_name: item.variant_name,
                  quantity: item.quantity,
                  amount: parseFloat(item.amount.amount),
                  formatted: item.amount_formatted,
                }
              }),
          },
        } : {}),
      })
    }

    #buildPurchaseDetails() {
      const cart = Checkout.store.cart.get()
      let orderId
      const lineItems = cart.map(({ id, quantity }) => {
        let data
        if (Cart.isCartItemIdUpdatable(id)) {
          const { orderId: tmpOrderId, variantId, priceId, lineItemId, type } = Cart.getCartIdDetails(id)
          if (type != Cart.ITEM_TYPES.REACTIVATE) {
            orderId = tmpOrderId
          }
          data = {
            line_item_id: lineItemId,
            variant_id: variantId,
            price_id: priceId,
          }
        } else {
          const { variantId, priceId } = Cart.getCartIdDetails(id)
          data = {
            variant_id: variantId,
            price_id: priceId,
          }
        }

        return {
          quantity,
          ...data
        }
      })
      return { order_id: orderId, line_items: lineItems, }
    }

    #buildOrderPreviewPayload() {
      // NOTE: currentCode is manily applied in case of code change and clicking Apply button
      // appliedCode is used in all subsequent requests - eg. when product is changed after coupon is applied
      const couponState = Checkout.store.coupons.state.get()
      let couponCode
      if (couponState == Checkout.CouponStates.APPLYING) {
        couponCode = Checkout.store.coupons.currentCode.get()
      } else {
        couponCode = Checkout.store.coupons.appliedCode.get()
      }
      const payload = {
        order: {
          full_preview: true,
          should_backfill_billing_address: true,
          coupon_codes: Checkout.store.featureFlags.isCouponEnabled.get() ? couponCode : [],
          ...(this.#buildAddressParams() ?? {}),
          ...this.#buildPurchaseDetails(),
        },
      }
      const selected_shipping_option = Checkout.store.shippingOption.get()
      if (selected_shipping_option) {
        payload.order.selected_shipping_option = selected_shipping_option
      }
      return payload
    }

    #formatNumberToCurrency(currency, number) {
      if (currency && currency === '$' && number > 0) {
        return new Intl.NumberFormat('en-US', {style:"currency", currency:"USD", maximumFractionDigits: 2}).format(number);
      }
      return currency + number?.toFixed(2)
    }

    #buildAddressParams() {
      const billing = Checkout.store.billing.get()
      const shipping = Checkout.store.shipping.get()
      const mode = Checkout.store.checkout.mode.get()

      if (mode == Checkout.CheckoutStates.UPGRADE_DOWNGRADE) {
        return
      }

      let billingData, shippingData
      if (CheckoutAddressUtils.isSavedAddress(billing)) {
        billingData = {
          id: billing.id,
        }
      } else {
        billingData = {
          address_one: billing.address,
          address_two: billing.address_2,
          city: billing.city,
          state: billing.state,
          country: billing.country,
          postal_code: billing.zip,
        }
      }
      
      if (CheckoutAddressUtils.isSavedAddress(shipping)) {
        shippingData = {
          id: shipping.id,
          address_one: shipping.address,
          address_two: shipping.address_2,
          city: shipping.city,
          state: shipping.state,
          country: shipping.country,
          postal_code: shipping.zip,
        }
      } else {
        shippingData = {
          address_one: shipping.address,
          address_two: shipping.address_2,
          city: shipping.city,
          state: shipping.state,
          country: shipping.country,
          postal_code: shipping.zip,
        }
      }

      if (Checkout.utils.hasPhysicalProducts()) {
        const billingSameAsShippingData = Checkout.store.billingSameAsShipping.get()
        if (Checkout.utils.skipBillingAddress(Checkout.store)) {
          return { delivery_address: Object.assign({}, shippingData) }
        }
        if (mode == Checkout.CheckoutStates.OTO) {
          return {
            delivery_address: Object.assign({}, shippingData),
            billing_address: Object.assign({}, billingData)
          }
        }
        if (billingSameAsShippingData) {
          return {
            delivery_address: Object.assign({}, shippingData),
            billing_address: Object.assign({}, shippingData)
          }
        } else {
          return {
            delivery_address: Object.assign({}, shippingData),
            billing_address: Object.assign({}, billingData)
          }
        }
      } else {
        if (Checkout.utils.skipBillingAddress(Checkout.store)) return
        return { billing_address: Object.assign({}, billingData) }
      }
    }

    updateRebillyTransactionData(shouldCallRebilly) {
      const mode = Checkout.store.checkout.mode.get()
      const summary = Checkout.store.summary.get()
      const cart = Checkout.store.cart.get()
      const shippingEnabled = Checkout.utils.hasPhysicalProducts()
      const shippingOptions = Checkout.store.shippingOptions.get()
      
      const summaryData = summary.data
      const lineItems = summaryData?.line_items ?? []
      const RebillyFullyInitialized = Checkout.store.payment.state.get() == Checkout.PaymentStates.INITIALIZED
      if (!shouldCallRebilly || mode == Checkout.CheckoutStates.UPGRADE_DOWNGRADE || !lineItems.length || !RebillyFullyInitialized) {
        Checkout.store.isUpdatingRebilly.set(false)
        return
      }

      const transactionData = this.buildTransactionData(summaryData, cart, shippingEnabled, shippingOptions)
      window.Rebilly.update({ transactionData }).then(() => {
        Checkout.store.isUpdatingRebilly.set(false)
      })
    }

    buildTransactionData(summaryData, cart, shippingEnabled, shippingOptions) {
      const { currency, line_items } = summaryData
      const lineItems = line_items.map(({ price, description }, index) => {
        let label
        if (line_items.length == cart.length) {
          const { variantId } = Cart.getCartIdDetails(cart[index].id)
          const variant = Checkout.variantsById[variantId]
          label = variant.name
        } else {
          label = description
        }
        return {
          label,
          amount: price
        }
      })

      if (summaryData.tax?.amount > 0) {
        lineItems.push({
          label: 'Taxes',
          amount: summaryData.tax.amount
        })
      }

      if (summaryData.shipping?.amount > 0) {
        lineItems.push({
          label: 'Shipping',
          amount: summaryData.shipping.amount
        })
      }

      return {
        currency,
        amount: summaryData.total.amount,
        label: 'Total Purchase',
        lineItems,
        requestShipping: shippingEnabled,
      }
    }


    #deepEqual(x, y) {
      const ok = Object.keys, tx = typeof x, ty = typeof y;
      return x && y && tx === 'object' && tx === ty ? (
        ok(x).length === ok(y).length &&
          ok(x).every(key => this.#deepEqual(x[key], y[key]))
      ) : (x === y);
    }
  }
  const checkoutManagerOrderPreviewV1 = CheckoutHelpersSummaryV1.getInstance()
  export default checkoutManagerOrderPreviewV1
        