
          
          import CheckoutHelpersSummary from "./checkout-helpers-summary-v1"
import CheckoutHelpersSubmit from "./checkout-helpers-submit-v1"
import CheckoutHelpersAuth from "./checkout-helpers-auth-v1"
import CriticalErrors from "./checkout-helpers-critical-errors-v1"
import Cart from "./checkout-helpers-cart-v1"
import ExistingOrders from "./checkout-helpers-existing-orders-v1"
import CheckoutAddressUtils from "./checkout-address-utils-v1"
import CheckoutHelpersDigitalWallet from "./checkout-helpers-digital-wallet-v1"
          import { CF2ComponentSingleton } from 'javascript/lander/runtime'
  // TODO(henrique): <ONE_STORE_PER_CHECKOUT> we wanna have one global store 
  // which holds global state for checkouts initialization. So we can export
  // rebilly initilization, feature gates fetching, country initialization
  // into this global store but still make leave whatever belongs especifically to 
  // checkout in its own store
  class CheckoutHelpersMachine extends CF2ComponentSingleton {
    INITIALIAZE_PAI_TIMEOUT = 60000

    loadPAIDeps (addLoader = true, shouldDisplayAndLogOnCriticalError = true) {
      if (Checkout.store.payment.state.get() != Checkout.PaymentStates.START) return
      Checkout.store.payment.state.set(Checkout.PaymentStates.INITIALIZING)
      if (addLoader) Checkout.store.payment.state.set(Checkout.PaymentStates.LOADING)
      const js = document.createElement('script')
      js.src = "https://framepay.payments.ai/rebilly.js"
      js.crossorigin = "anonymous"

      const errorHandler = (errorType, cause) => {
        const error = new CriticalErrors.CheckoutCriticalError(errorType, {cause})
        if (shouldDisplayAndLogOnCriticalError) {
          CriticalErrors.displayAndLogCriticalError(error)
        }
      }

      const promise = new Promise((resolve, reject) => {
        js.onload = () => {
          this.intializePAI().then(() => {
            CheckoutHelpersSummary.updateRebillyTransactionData(true)
            resolve()
          }).catch((e) => {
            errorHandler(CriticalErrors.ERROR_CODES.PAI_INITIALIZATION_READY_TIMEOUT_ERROR, e)
            reject()
          })
        }
        js.onerror = (e) => {
          errorHandler(CriticalErrors.ERROR_CODES.PAI_INITIALIZATION_TIMEOUT_ERROR, e)
          reject()
        }
      })
      document.head.appendChild(js)

      const css = document.createElement('link')
      css.href = "https://framepay.payments.ai/rebilly.css"
      css.rel = "stylesheet"
      css.crossorigin = "anonymous"
      document.head.appendChild(css)

      return promise
    }

    intializePAI () {
      Checkout.PaypalCallbacks = {
        onInit: (data, actions) => {
          console.log('[PayPal] - onInit called')
          Checkout.store.payment.paypal.state.set({ state: Checkout.PaypalStates.INITIALIZED })
        },
        onApprove: (data, actions) => {
          console.log('[PayPal] - onApprove called', data, actions)
          Checkout.store.payment.paypal.state.set({state: Checkout.PaypalStates.PAYMENT_METHOD_APPROVED })
        },
        onError: (data, actions) => {
          console.log('[PayPal] - onError called', data, actions)

          Checkout.store.payment.paypal.state.set({
            state: Checkout.PaypalStates.ERROR,
            code: Checkout.ErrorTypes.PAYPAL_CUSTOM_ERROR,
            message: 'Something unexpected happened'
          })
        },
        onCancel: (data, actions) => {
          console.log('[PayPal] - onCancel called', data, actions)

          Checkout.store.payment.paypal.state.set({
            state: Checkout.PaypalStates.ERROR,
            code: Checkout.ErrorTypes.PAYPAL_DECLINED_ERROR
          })
          
        },
        onClick: (data, actions) => {
          console.log('[PayPal] - onClick called', data, actions)
          Checkout.store.payment.paypal.state.set({ state: Checkout.PaypalStates.ADDING_PAYMENT_METHOD })
        },
      }
      const checkoutElement = document.querySelector('[data-page-element="Checkout/V2"]')
      const inputColor = getComputedStyle(checkoutElement).getPropertyValue('--input-color')
      const fontFamily = getComputedStyle(checkoutElement).getPropertyValue('--multiple-payments-font-family')
      const fontSize = getComputedStyle(checkoutElement).getPropertyValue('--multiple-payments-font-size')
      const rebillyKeys = document.getElementById('rebilly-keys')
      const publishableKey = rebillyKeys.getAttribute('data-rebilly-publishable-key')
      const organizationId = rebillyKeys.getAttribute('data-rebilly-organization-id')
      const websiteId = rebillyKeys.getAttribute('data-rebilly-website-id')
      const currency = rebillyKeys.getAttribute('data-rebilly-currency')
      const rebillyConfig = {
        publishableKey: publishableKey,
        organizationId: organizationId,
        kountAccountId: '700000', // This is for capturing kount fraud sessions
        websiteId: websiteId,
        // NOTE: need to add below otherwise this error happens: transactionData must contain an amount to fetch methods
        transactionData: {
          currency: currency || 'USD',
          amount: 1,
          label: 'Product Purchase',
        },
        icon: {
          color: inputColor,
        },
        style: {
          base: {
            color: inputColor,
            fontFamily: fontFamily,
            fontSize: fontSize,
            '::placeholder': {
              color: inputColor,
              fontFamily: fontFamily,
              fontSize: fontSize,
            },
          },
        },
      }

      CheckoutHelpersDigitalWallet.initialize()
      const promise = new Promise((resolve, reject) => {
        const timeout = setTimeout(() => {
          reject(new Error(`Timeout error: Rebilly took more than ${this.INITIALIAZE_PAI_TIMEOUT} to load`))
        }, this.INITIALIAZE_PAI_TIMEOUT)
        window.Rebilly.on('ready', () => {
          if (timeout) clearTimeout(timeout)
          Checkout.store.payment.state.set(Checkout.PaymentStates.INITIALIZED)
          resolve()
        })
      })

      window.Rebilly.initialize(rebillyConfig)
      return promise
    }

    loadPhoneNumberLib() {
    return window.intlTelInputGlobals.loadUtils("https://cdn.jsdelivr.net/npm/intl-tel-input@17.0.16/build/js/utils.js").catch((e) => {
        throw new CriticalErrors.CheckoutCriticalError(CriticalErrors.ERROR_CODES.FETCH_PHONE_UTILS_ERROR, { cause: e })
      })
    }

    initializeCountries(options) {
      return Promise.all([
        window.CFFetch('/cf_countries_states.json', {
          headers: { "Content-Type": "application/json"},
        }, { retries: 3, shouldCaptureServerError: true })
        .then(function(res) { return res.json(); })
        .catch((e) => {
          throw new CriticalErrors.CheckoutCriticalError(CriticalErrors.ERROR_CODES.FETCH_COUNTRIES_STATES_ERROR, { cause: e })
        }),
        options.hasPhoneNumber && this.loadPhoneNumberLib(options),
      ].filter(Boolean)).
      then(([countryStateReqResponse]) => {
        Checkout.allCountries = countryStateReqResponse.result

        const defaultCountryCode = 'US'
        
        const countryData = Checkout.allCountries.find((c) => c.iso2 == cfVisitorData.country) ??
                              Checkout.allCountries.find((c) => c.iso2 == defaultCountryCode)

        const stateData = countryData.regions?.find((r) => r.iso2 == cfVisitorData.regionCode) ??
                            countryData.regions?.[0]

        // TODO: check if this works for default country code case
        const country = countryData.iso2
        const state = stateData?.state_code


        Checkout.contactLocale = {
          country: country,
          state: state,
        }

        const initialShipping = Checkout.store.shipping.get()
        Checkout.store.shipping.set({
          ...(initialShipping ?? {}),
          country: initialShipping?.country ?? country,
          state: initialShipping?.state ?? state,
        })

        let initialBilling = Checkout.store.billing.get()
        if (Checkout.store.checkout.mode.get() == 'guest') {
          initialBilling = CheckoutAddressUtils.parseAddressByFields(initialBilling, options.billingFields)
        }
        Checkout.store.billing.set(initialBilling)

        Checkout.store.phoneNumberInitialized.set(true)
      })
    }

    checkFeatureFlags() {
      return window.CFFetch("/user_pages/api/v1/checkouts/feature_flags.json", {} , { retries: 3, shouldCaptureServerError: true }).
          then((res) => { return res.json(); }).
          then((res) => {
            Checkout.store.featureFlags.isShippingEnabled.set(res.feature_flags.is_shipping_enabled)
            Checkout.store.featureFlags.isCouponEnabled.set(res.feature_flags.is_coupon_enabled)
          }).
          catch((e) => {
            throw new CriticalErrors.CheckoutCriticalError(CriticalErrors.ERROR_CODES.FETCH_FEATURE_FLAGS_ERROR, { cause: e })
          })
    }

    initializeMachine(options) {
      Checkout.store.state.listen((newState) => {
        switch (newState) {
          case Checkout.StoreStates.INITIALIZING: {
            const isGuest = Checkout.store.checkout.mode.get() == 'guest'
            const isSaved = Checkout.store.checkout.mode.get() == 'saved'
            const isOTO = Checkout.store.checkout.mode.get() == 'oto'
            const paymentMethods = Checkout.store.paymentMethods.get()
            const oneStepCheckout = document.querySelector('[data-page-element="Checkout/V2"][data-total-steps="1"]')
            const shouldIntializePAI = (oneStepCheckout && isGuest) || (!paymentMethods.length && (isSaved || isOTO))
            Promise.all([
              this.checkFeatureFlags(),
              shouldIntializePAI && this.loadPAIDeps(false, false),
              this.initializeCountries(options),
              !isGuest && Object.keys(Checkout.productsById).length > 0 && ExistingOrders.fetch()
            ].filter(v => !!v)).then(() => {
              Checkout.store.state.set(Checkout.StoreStates.INITIALIZED)
            }).catch((e) => {
              if (e instanceof CriticalErrors.CheckoutCriticalError) {
                CriticalErrors.displayAndLogCriticalError(e)
              } else {
                const initializationError = new CriticalErrors.CheckoutCriticalError(CriticalErrors.ERROR_CODES.UNEXPECTED_INITIALIZATION_ERROR, { cause: e })
                CriticalErrors.displayAndLogCriticalError(initializationError)
              }
            })
            break;
          }
          case Checkout.StoreStates.INITIALIZED: {
            CheckoutHelpersSummary.sendOrderPreview()
            // NOTE: Change mode to filling form after all callbacks
            // for Initialized have been executed.
            setTimeout(() => {
              Checkout.store.state.set(Checkout.StoreStates.FILLING_FORM)
              CheckoutHelpersDigitalWallet.listen()
            })
            break;
          }
          case Checkout.StoreStates.FILLING_FORM: {
            break;
          }
          case Checkout.StoreStates.SUBMITTED: {
            break;
          }
        }
      })
      // # endregion state machine listeners

      // # region global listeners
      Checkout.store.submitting.listen((submitting) => {
        const state = submitting.state
        switch (state) {
          case Checkout.SubmittingStates.ERROR: {
            Checkout.store.state.set(Checkout.StoreStates.FILLING_FORM)
            break;
          }
          case Checkout.SubmittingStates.DONE: {
            Checkout.store.state.set(Checkout.StoreStates.SUBMITTED)
            break;
          }
        }
      })

      Checkout.store.billing.listen((billing) => {
        const fields = Checkout.store.billingFields.get()
        const addressFields = CheckoutAddressUtils.fieldsForCountry(fields, billing.country)
        Checkout.store.billingFields.set(addressFields)
      })

      Checkout.store.payment.paypal.state.listen((submitting) => {
        const state = submitting.state
        switch (state) {
          case Checkout.PaypalStates.ERROR: {
            setTimeout(() => {
              Checkout.store.payment.paypal.state.set({ state: Checkout.PaypalStates.INITIALIZED })
            }, 3000)
            break;
          }
        }
      })

      Checkout.store.checkout.mode.listen((mode) => {
        if (mode === 'guest') {
          // #region reset cart if upgrade downgrade
          const cart = Checkout.store.cart.get()

          const selectedProductId = Cart.getCartIdDetails(cart[0]?.id)?.productId
          const productCardByProductId = Checkout.store.productCardByProductId.get()
          if (Checkout.computed.anyUpdatableOrders.get()) {
            Object.keys(productCardByProductId).forEach((productId) => {
              const productCard = productCardByProductId[productId]
              const isSelected = selectedProductId == productId

              Checkout.store.productCardByProductId.setKey(productId, {
                variantId: productCard.variantId,
                priceId: productCard.priceId,
                quantity: isSelected ? 1 : 0,
              })

              if (isSelected) {
                const cartItemId = Cart.buildCartItemId(productId, productCard.variantId, productCard.priceId)
                Checkout.store.cart.set([{id: cartItemId, quantity: 1}])
              }
            })
            ExistingOrders.cleanUp()
          }
          // #endregion reset cart if upgrade downgrade
          
          // #region reset contact data
          Checkout.store.contact.set({})
          Checkout.store.shipping.set({
            country: Checkout.contactLocale.country,
            state: Checkout.contactLocale.state,
          })

          const newBillingData = CheckoutAddressUtils.parseAddressByFields(Checkout.contactLocale, options.billingFields)
          Checkout.store.billing.set(newBillingData)
          Checkout.store.payment.id.set(null)
          // #endregion reset contact data
        }
      })

      // Adds PAI when the user clicks to add a new payment
      Checkout.store.payment.id.listen((newPaymentId) => {
        if (!newPaymentId) this.loadPAIDeps()
      })

      const checkoutStatesChangedByCartItems = {
        [Checkout.CheckoutStates.UPGRADE_DOWNGRADE]: true,
        [Checkout.CheckoutStates.REACTIVATE]: true,
      }
      const cartItemsByCheckoutStates = {
        [Cart.ITEM_TYPES.UPGRADE_DOWNGRADE]: Checkout.CheckoutStates.UPGRADE_DOWNGRADE,
        [Cart.ITEM_TYPES.REACTIVATE]: Checkout.CheckoutStates.REACTIVATE,
      }
      nanostores.computed([Checkout.computed.modeLeaveEnterEvent, Checkout.store.state], (ev, state) => {
        if([Checkout.StoreStates.START, Checkout.StoreStates.INITIALIZING].includes(state)) return
        const { enter } = ev
        if (enter && !checkoutStatesChangedByCartItems[enter]) {
          Checkout.store.checkout.lastModeIndependentOfCartItems.set(enter)
        }

        // Add PAI when entering on guest mode and page has a one step checkout
        // Or when entering on saved mode and the contact does not have any saved payments
        const paymentState = Checkout.store.payment.state.get()
        const oneStepCheckout = document.querySelector('[data-page-element="Checkout/V2"][data-total-steps="1"]')
        if (enter === 'guest' && oneStepCheckout) {
          this.loadPAIDeps()
        }
        if (enter === 'saved') {
          const contactPaymentMethod = Checkout.store.paymentMethods.get()
          if (!contactPaymentMethod?.length) {
            this.loadPAIDeps()
          }
        }
      }).subscribe(() => {})

      // NOTE: Update checkout.mode depending on the existing cart items
      Checkout.store.cart.listen((cart) => {
        const currentMode = Checkout.store.checkout.mode.get()

        let cartState
        cart.forEach(({ id }) => {
          const state = cartItemsByCheckoutStates[Cart.getCartItemType(id)]
          if (state) {
            if (cartState) {
              throw new Error(`Cart has more than one item of type ${state}`)
            }
            cartState = state
          }
        })

        if (cartState && cartState != currentMode) {
          Checkout.store.checkout.mode.set(cartState)
        } else if (!cartState && [Checkout.CheckoutStates.UPGRADE_DOWNGRADE, Checkout.CheckoutStates.REACTIVATE].includes(currentMode)) {
          const lastModeIndependentOfCartItems = Checkout.store.checkout.lastModeIndependentOfCartItems.get()
          if (lastModeIndependentOfCartItems && lastModeIndependentOfCartItems != currentMode) {
            Checkout.store.checkout.mode.set(lastModeIndependentOfCartItems)
          }
        }
      })

      let lastBilling
      Checkout.store.billing.listen((billing) => {
        if (Checkout.store.billingApiErrorsByField.get()) Checkout.store.billingApiErrorsByField.set()
        const backfilledBillingFromApi = options.billingFields.length == 2 && Object.keys(billing).length > 2
        const updatedZipForSameAddress = !billing.id && lastBilling?.zip != billing.zip
          // NOTE: If zip code changes for a new address, cleans up other fields calculated by API
          // in order to recalculate them by back-end again, otherwise, we would be storing wrong data here
        if (backfilledBillingFromApi && updatedZipForSameAddress) {
          const newBillingAddress = {
            country: billing.country,
            zip: billing.zip,
          }
          Checkout.store.billing.set(newBillingAddress)
        }
        lastBilling = billing
      })
      // # endregion global listeners
    }
  }
  const checkoutHelpersMachine = CheckoutHelpersMachine.getInstance()
  export default checkoutHelpersMachine
        