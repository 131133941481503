
import "./checkout-globals-v1"
import CheckoutHelpersSummary from "./checkout-helpers-summary-v1"
import CheckoutHelpersStore from "./checkout-helpers-store-v1"
import CheckoutHelpersMachine from "./checkout-helpers-machine-v1"
import CheckoutAddressUtils from "./checkout-address-utils-v1"
import "./checkout-tos-v1"
import "./checkout-order-summary-v1"
import "./checkout-login-v2"
import "./checkout-product-select-v2"
import "./checkout-product-select-bump-v2"
import "./checkout-multiple-payments-v2"
import "./checkout-multi-step-v2"
import "./checkout-contact-form-v1"
import "./checkout-billing-address-v1"
import "./checkout-shipping-address-v1"
import "./checkout-saved-contact-details-v1"
import "./checkout-saved-billing-address-v1"
import "./checkout-shipping-profile-select-v1"
import "./checkout-saved-multiple-payments-v1"
import "./checkout-submit-button-v1"
import "./checkout-threeds-frame-v1"
import "./checkout-configuration-error-v1"
import "./checkout-loading-v1"
import "./checkout-order-details-v1"
import { CF2Component } from 'javascript/lander/runtime'

export default class CheckoutV2 extends CF2Component {

constructor(el, runtimeSel) {
super(el, runtimeSel)
}

initialize() {
    // TODO(henrique): <ONE_STORE_PER_CHECKOUT> right now we are saving store as a global object
    // but the goal is having one store per checkout.
    const billingFields = this.billingEnabled ? this.billingFields : []
    const enabledPayments = !window.ApplePaySession || this.disabledApplePay ? globalResourceData.enabledPayments.filter((v) => v != 'apple-pay') : globalResourceData.enabledPayments
    Checkout.store = CheckoutHelpersStore.create(billingFields, this.shippingFields, enabledPayments)
    Checkout.computed = CheckoutHelpersStore.buildComputed(Checkout.store, this.contactFields, this.shippingFields, this.totalSteps)
    CheckoutHelpersStore.initializeUtilities(Checkout.store, Checkout.computed)
    CheckoutHelpersMachine.initializeMachine({
      hasPhoneNumber: this.contactFields.includes('phone_number'),
      billingFields: billingFields,
      shippingFields: this.shippingFields,
    })
  }

  backfillContactDataFromGarlic_Mutable(contactData) {
    Object.keys(contactData).reduce((acc, field) => {
      if (!contactData[field]) {
        const val = window.cfGarlicUtils.retrieve(field)
        if (field?.length) acc[field] = val
      }
      return acc
    }, contactData)
  }

  initializeStoreDependentElements(store) {
    const checkoutElements = this.getAllComponents()
    const linkedElements = [...document.querySelectorAll(`[data-linked-checkout-id=${this.id}]`)].map((el) => el.cf2_instance)
    const allElements = checkoutElements.concat(linkedElements)
    allElements.forEach((el) => {
      el.checkoutStoreReady && el.checkoutStoreReady(store)
    })
  }

  mount() {
    // NOTE: We need a setTimeout here because we need to wait for all CF2 Components to be intialized
    // before triggering their checkout store related functions.
    setTimeout(() => {
      this.initializeStoreDependentElements(Checkout.store)
    }, 0);
    const contactData = this.contactFields.reduce((acc, field) => {
      acc[field] = Checkout.initialContactData[field]
      return acc
    }, {})
    this.backfillContactDataFromGarlic_Mutable(contactData)

    const initialMode = Checkout.store.checkout.mode.get()
    if ((initialMode == 'guest' && this.totalSteps == 1) || initialMode == 'saved') {
      this.listenAndUpdateCheckoutErrors(initialMode)
    }

    if (!this.disableOTP) {
      Checkout.auth.computed.requireLogin.listen(requireLogin => {
        if (requireLogin) {
          // hide checkout form
          this.element.querySelector(`[data-wrapper-checkout-state="${Checkout.store.checkout.mode.get()}"]`)
            .classList.add('elHide')
  
        } else {
          // restore checkout form
          this.updateCheckoutMode(Checkout.store.checkout.mode.get())
        }
      })
    }
    Checkout.store.checkout.mode.listen((mode) => {
      this.updateCheckoutMode(mode)
    })
    
    this.listenAndUpdateCheckoutErrors()
    
    // TODO: checkout component could have its local store so that if/when we support multiple checkouts,
    // the value we store here doesnt conflict with other checkout elements.
    Checkout.store.tos.present.set(this.showTos);

    Checkout.store.state.listen((state) => {
      if ([Checkout.StoreStates.START, Checkout.StoreStates.INITIALIZING, Checkout.StoreStates.INITIALIZED].includes(state)) {
        this.element.querySelector('.elSpinnerWrapper').dataset.loader = true
        if (state === Checkout.StoreStates.INITIALIZED) {
          Checkout.store.contact.set(contactData)
        }
      } else {
        if (this.element.querySelector('[data-page-element="CheckoutConfigurationError/V1"]').querySelector('.elHide')) {
          delete this.element.querySelector('.elSpinnerWrapper').dataset.loader 
        }
      }
    })

    this.element.querySelectorAll('[href="#!checkout-as-guest"]').forEach((el) => {
      el.addEventListener('click', (evt) => {
        evt.preventDefault()
        Checkout.store.checkout.mode.set('guest')
      })
    })

    ;[Checkout.store.cart, Checkout.store.billing, Checkout.store.shipping, Checkout.store.shippingOption].forEach((store) => {
      store.listen(() => {
        CheckoutHelpersSummary.sendOrderPreview()
      })
    })

    Checkout.store.incrScrollToFirstVisibleError.listen(() => {
      const $errorWrapper = $('[data-error-container="active"]:visible:first').closest('[data-error-wrapper]')
      const $checkoutFormError = $('.elCheckoutFormErrors:visible:first')
      if ($errorWrapper.length > 0) {
        $('html, body').stop(true,true).animate({
            scrollTop: $errorWrapper.offset().top - 100
        }, 500)
      } else if ($checkoutFormError.length > 0) {
         $('html, body').stop(true,true).animate({
            scrollTop: $checkoutFormError.offset().top - 100
        }, 500)
      }
    })
    Checkout.computed.hasShippingEnabled.subscribe(hasShippingEnabled => {   
      if (!hasShippingEnabled && Checkout.store.shippingOption.value) {
        // clear any selected shipping option if shipping is no longer enabled
        Checkout.store.shippingOption.set(null)
      }
    })
    Checkout.computed.hideShipping.subscribe(hideShipping => {
      this.element.querySelector('.elCheckoutWrapper').setAttribute('data-shipping-enabled', hideShipping ? 'false' : 'true')      
    })

    // Initialize checkout after all children components are mounted
    Checkout.store.state.set(Checkout.StoreStates.INITIALIZING)
    const checkoutOrderSummaries = this.element.querySelectorAll('[data-page-element="CheckoutOrderSummary/V1"]')
    const pageOrderSummaries = document.querySelector('[data-page-element="CheckoutOrderSummary/V1"].elCheckout')
    if (pageOrderSummaries) {
      checkoutOrderSummaries.forEach((el) => {
        el.style.display = 'none'
      })
    }

    this.element.querySelectorAll('input, select').forEach((el) => {
      el.addEventListener('focus', () => {
        Checkout.store.submitting.set({state: Checkout.SubmittingStates.IDLE})
      })
    })

    const trackableStores = [
      Checkout.store.contact,
      Checkout.store.shipping,
      Checkout.store.billing,
      Checkout.store.billingSameAsShipping,
      Checkout.store.cart,
      Checkout.store.tos.accepted,
      Checkout.store.shippingOption,
      Checkout.store.payment.state,
      Checkout.store.payment.id,
      Checkout.store.payment.type,
      Checkout.store.payment['payment-card'].events,
      Checkout.store.payment['payment-card'].token,
      Checkout.store.payment.paypal.state,
      Checkout.store.payment.paypal.token,
    ]
    trackableStores.forEach((store) => {
      store.listen((v) => {
        Checkout.store.submitting.set({state: Checkout.SubmittingStates.IDLE})
      })
    })
  }

  updateCheckoutMode(mode) {
    const containerMapping = Object.values(Checkout.CheckoutStates).reduce((acc, item) => {
      acc[item] = this.element.querySelector(`[data-wrapper-checkout-state="${item}"]`)
      return acc
    }, {})

    const currentContainer = containerMapping[mode]

    for (let container of Object.values(containerMapping)) {
      if (container == currentContainer) {
        currentContainer.classList.remove('elHide')
      } else {
        container.classList.add('elHide') 
      }
    }
  }

  listenAndUpdateCheckoutErrors() {
    Object.entries(Checkout.computed.errorsByName).forEach(([name, computed]) => {
      computed.subscribe((error) => {
        Object.values(Checkout.CheckoutStates).forEach((mode) => {
          if (mode == 'guest' && this.totalSteps != 1) return
          const currentErrorElement = this.element.querySelector(`[data-wrapper-checkout-state="${mode}"]`).querySelector('.checkout-general-errors-wrapper')
          const generalErrorsStructure = ['shippingOption', 'products', 'tos', 'payment', 'billing']
          const errors = generalErrorsStructure.reduce((acc, formName) => {
            const error = Checkout.computed.errorsByName[formName].get()
            if (Checkout.utils.hasErrors(error)) {
              acc.push({name: formName, errors: error})
            }
            return acc
          }, [])
          if (!errors || errors?.length == 0) {
            currentErrorElement.classList.add('elHide')
          } else {
            const error = errors[0]
            if (error.errors?.globalErrors?.[0]?.message) {
              const errorMessage = error.errors.globalErrors[0].message
              currentErrorElement.querySelector('span').innerHTML = errorMessage
              currentErrorElement.classList.remove('elHide')
            } else {
              currentErrorElement.classList.add('elHide')
            }
          }
        })
      })
    })
  }



}

window["CheckoutV2"] = CheckoutV2

