
import "./checkbox-v1"
import { CF2Component } from 'javascript/lander/runtime'

export default class CheckoutProductCardBumpV2 extends CF2Component {

constructor(el, runtimeSel) {
super(el, runtimeSel)
}

mount() {
  }


    remove() {
      this.element.innerHTML = '';
    }
    render(initializeChildrenInstances = false) {
      const is_server = true;
      const selectType = this.selectType ?? null ;
const isChecked = this.isChecked ?? false ;
const showImage = this.showImage ?? null ;
const layout = this.layout ?? null ;
const product = this.product ?? null ;
const variant = this.variant ?? null ;
const selected_price = this.selected_price ?? null ;
const quantity = this.quantity ?? 0 ;
const arrow = this.arrow ?? null ;

    const showDescription = this.element.getAttribute('data-param-showDescription');
const showCompareAtPrice = this.element.getAttribute('data-param-showCompareAtPrice');
  
      let html = '';
      {
        html += `<div class="elProductLayoutBorder"></div><div class="elProductLayoutBackground"></div>`
        const customVariants = product.variants.filter((value) => value["default_variant"] == false);
        const product_name = "product_bump";
        if (layout == "classic") {
          if (showImage) {
            const imageUrl = variant.image ?? product.image;
            if (imageUrl && imageUrl != "") {
              html += `<div class="elProductCardImage"><div data-page-element="Image/V2" class="elImageWrapper de-image-block id-Image/V2`
              if (true && !imageUrl && !null && false == false) {
                html += ` forceHide`
              }
              html += `" data-liquid-replace="item">`
              if (imageUrl || !!null) {
                html += `<img class="elImage" src="${imageUrl ?? null}"/>`
              } else if (false) {
                html += `<div class="image-placeholder" title="This element will render with content once configured."><span class="image-placeholder-header">[Replaced by Content]</span></div>`
              }
              html += `</div></div>`
            }
          }
          html += `<div class="elProductCardInfoContainer"><div class="elProductCardInputAndTitle">`
          const isClassic = layout == "classic";
          if (arrow.icon != "none" && isChecked != true) {
            if (arrow.animation) {
              html += `<span data-animation-type="${arrow.animation}" data-animation-delay="600" data-animation-loop="true" class="elProductBumpHeadlineArrow"><i class="${arrow.icon}"></i></span>`
            } else {
              html += `<span class="elProductBumpHeadlineArrow"><i class="${arrow.icon}"></i></span>`
            }
          }
          html += `<div class="elProductCardSelectInput`
          if (isClassic == false || selectType == "quantity") {
            html += ` elHide`
          }
          html += `">`
          if (isChecked) {
            html += `<div data-page-element="Checkbox/V1" type="" class="elCheckboxWrapper elFormItemWrapper de-input-block id-Checkbox/V1" data-liquid-replace="item" data-prevent-click-on-label="false" data-trigger-click-on-wrapper="false" data-checked="true"><label class="elCheckboxLabel"><input type="checkbox" value="${product.id}" name="product" checked data-prevent-garlic="false" class="elCheckboxInput inputHolder"/><div class="elCheckbox"><div class="elCheckboxIcon"><div class="elCheckboxIconSquare hide"></div><i class="elCheckboxIconCheck fas fa-check"></i></div></div><span class="elCheckboxText"></span></label><div data-input-status-type="true" data-error-container="true"></div></div>`
          } else {
            html += `<div data-page-element="Checkbox/V1" type="" class="elCheckboxWrapper elFormItemWrapper de-input-block id-Checkbox/V1" data-liquid-replace="item" data-prevent-click-on-label="false" data-trigger-click-on-wrapper="false" data-checked=""><label class="elCheckboxLabel"><input type="checkbox" value="${product.id}" name="product"  data-prevent-garlic="false" class="elCheckboxInput inputHolder"/><div class="elCheckbox"><div class="elCheckboxIcon"><div class="elCheckboxIconSquare hide"></div><i class="elCheckboxIconCheck fas fa-check"></i></div></div><span class="elCheckboxText"></span></label><div data-input-status-type="true" data-error-container="true"></div></div>`
          }
          html += `</div><span class="elProductBumpHeadline">`
          if (product.headline) {
          html += `${product.headline}`
          } else if (customVariants.length > 1) {
          html += `${product.default_variant.name ?? "One time offer:"}`
          } else {
          html += `${variant.name ?? "One time offer:"}`
          }
          html += `</span></div><div class="elProductCardPriceContainer">`
          if (variant.prices.length > 1) {
            html += `<div class="elSelectWrapper"><select name="variant_price">`
            const c0 = variant.prices
            const fl1 = new CF2ForloopDrop(c0.length)
            for (const price of c0) {
              const forloop = fl1
              html += `<option `
              if (selected_price.id == price.id) {
                html += `selected`
              }
              html += ` value="${price.id}">${price.name}</option>`
              forloop.next();
            }
            html += `</select><div class="elSelectArrow"><i class="fas fa-chevron-down"></i></div></div>`
          }
          if (variant.prices.length == 1) {
            html += `<div class="elProductCardInfoPrice">`
            if (showCompareAtPrice) {
              const compare_at_amount = selected_price.compare_at_amount;
              if (compare_at_amount) {
                html += `<span class="elProductCardAnchorPrice">${compare_at_amount}</span>`
              }
            }
            html += `<span class="elProductCardFinalPrice">${selected_price.name}</span></div>`
          }
          html += `</div>`
          const desc = (product.description ?? product.description_override ?? variant.description ?? product.default_variant.description ?? "").replaceAll(`
`,"<br>");
          if (desc != "") {
            html += `<div class="elProductCardDescription"><span class="elProductBumpText">${desc}</span></div>`
          }
          if (customVariants.length > 1) {
            html += `<div class="elProductSelectSelectorsWrapper">`
            if (customVariants.length > 1) {
              html += `<div class="elSelectWrapper"><select class="elBumpVariantSelector">`
              const c2 = product.variants
              const fl3 = new CF2ForloopDrop(c2.length)
              for (const product_variant of c2) {
                const forloop = fl3
                const variant_index = forloop.index0;
                let combined_variant_name = "";
                const c4 = product.sorted_property_values
                const fl5 = new CF2ForloopDrop(c4.length)
                for (const property_values of c4) {
                  const forloop = fl5
                  const index0 = forloop.index0;
                  const property_id = property_values.property_id;
                  const property_value_id = product_variant.property_value_ids[index0];
                  if (combined_variant_name != "") {
                    combined_variant_name = combined_variant_name.toString().concat(", ");
                  }
                  combined_variant_name = combined_variant_name.toString().concat(product.all_properties[property_id]);
                  combined_variant_name = combined_variant_name.toString().concat(": ");
                  combined_variant_name = combined_variant_name.toString().concat(product.all_properties_values[property_value_id]);
                  forloop.next();
                }
                html += `<option value="${product_variant.property_value_ids}" `
                if (product_variant.id == variant.id) {
                  html += `selected`
                }
                html += `>${combined_variant_name}</option>`
                forloop.next();
              }
              html += `</select><div class="elSelectArrow"><i class="fas fa-chevron-down"></i></div></div>`
            }
            html += `</div>`
          }
          if (selectType == "quantity") {
            html += `<div class="elProductInputWrapper elProductCardQuantityContainer"><span>Quantity</span><div class="elProductInputControls"><button class="elProductCardInput">-</button><input class="elProductCardInput elProductCardShortInput" name="product" value="${quantity ?? 0}" min="0" type="number"/><button class="elProductCardInput">+</button></div></div>`
          } else if (selectType == "quantity-forced") {
            html += `<div class="elProductInputWrapper elSelectWrapper elForcedQuantityWrapper"><span>Quantity</span><select name="product" class="elProductCardInput elProductInputControls">`
            const step = product.step ?? 2;
            const min = product.min ?? 0;
            const max = product.max ?? 5;
            const item_count_step_1 = max- min;
            const item_count_step_2 = item_count_step_1/ step;
            const item_count_step_3 = item_count_step_2| 0;
            const range = Array.apply(0, Array(item_count_step_3)).map((element, index) => index + 0);;
            const c6 = range
            const fl7 = new CF2ForloopDrop(c6.length)
            for (const i of c6) {
              const forloop = fl7
              const value = i* step+ min;
              html += `<option value="${value}">${value}</option>`
              forloop.next();
            }
            html += `</select><div class="elSelectArrow elForcedQuantitySelectArrow"><i class="fas fa-chevron-down"></i></div></div>`
          }
          html += `</div>`
        } else {
          if (showImage) {
            const imageUrl = variant.image ?? product.image;
            if (imageUrl && imageUrl != "") {
              html += `<div class="elProductCardImage"><div data-page-element="Image/V2" class="elImageWrapper de-image-block id-Image/V2`
              if (true && !imageUrl && !null && false == false) {
                html += ` forceHide`
              }
              html += `" data-liquid-replace="item">`
              if (imageUrl || !!null) {
                html += `<img class="elImage" src="${imageUrl ?? null}"/>`
              } else if (false) {
                html += `<div class="image-placeholder" title="This element will render with content once configured."><span class="image-placeholder-header">[Replaced by Content]</span></div>`
              }
              html += `</div></div>`
            }
          }
          html += `<div class="elProductCardInfoContainer"><div class="elProductCardInputAndTitle">`
          const isClassic = layout == "classic";
          if (arrow.icon != "none" && isChecked != true) {
            if (arrow.animation) {
              html += `<span data-animation-type="${arrow.animation}" data-animation-delay="600" data-animation-loop="true" class="elProductBumpHeadlineArrow"><i class="${arrow.icon}"></i></span>`
            } else {
              html += `<span class="elProductBumpHeadlineArrow"><i class="${arrow.icon}"></i></span>`
            }
          }
          html += `<div class="elProductCardSelectInput`
          if (isClassic == false || selectType == "quantity") {
            html += ` elHide`
          }
          html += `">`
          if (isChecked) {
            html += `<div data-page-element="Checkbox/V1" type="" class="elCheckboxWrapper elFormItemWrapper de-input-block id-Checkbox/V1" data-liquid-replace="item" data-prevent-click-on-label="false" data-trigger-click-on-wrapper="false" data-checked="true"><label class="elCheckboxLabel"><input type="checkbox" value="${product.id}" name="product" checked data-prevent-garlic="false" class="elCheckboxInput inputHolder"/><div class="elCheckbox"><div class="elCheckboxIcon"><div class="elCheckboxIconSquare hide"></div><i class="elCheckboxIconCheck fas fa-check"></i></div></div><span class="elCheckboxText"></span></label><div data-input-status-type="true" data-error-container="true"></div></div>`
          } else {
            html += `<div data-page-element="Checkbox/V1" type="" class="elCheckboxWrapper elFormItemWrapper de-input-block id-Checkbox/V1" data-liquid-replace="item" data-prevent-click-on-label="false" data-trigger-click-on-wrapper="false" data-checked=""><label class="elCheckboxLabel"><input type="checkbox" value="${product.id}" name="product"  data-prevent-garlic="false" class="elCheckboxInput inputHolder"/><div class="elCheckbox"><div class="elCheckboxIcon"><div class="elCheckboxIconSquare hide"></div><i class="elCheckboxIconCheck fas fa-check"></i></div></div><span class="elCheckboxText"></span></label><div data-input-status-type="true" data-error-container="true"></div></div>`
          }
          html += `</div><span class="elProductBumpHeadline">`
          if (product.headline) {
          html += `${product.headline}`
          } else if (customVariants.length > 1) {
          html += `${product.default_variant.name ?? "One time offer:"}`
          } else {
          html += `${variant.name ?? "One time offer:"}`
          }
          html += `</span></div><div class="elProductCardPriceContainer">`
          if (variant.prices.length > 1) {
            html += `<div class="elSelectWrapper"><select name="variant_price">`
            const c8 = variant.prices
            const fl9 = new CF2ForloopDrop(c8.length)
            for (const price of c8) {
              const forloop = fl9
              html += `<option `
              if (selected_price.id == price.id) {
                html += `selected`
              }
              html += ` value="${price.id}">${price.name}</option>`
              forloop.next();
            }
            html += `</select><div class="elSelectArrow"><i class="fas fa-chevron-down"></i></div></div>`
          }
          if (variant.prices.length == 1) {
            html += `<div class="elProductCardInfoPrice">`
            if (showCompareAtPrice) {
              const compare_at_amount = selected_price.compare_at_amount;
              if (compare_at_amount) {
                html += `<span class="elProductCardAnchorPrice">${compare_at_amount}</span>`
              }
            }
            html += `<span class="elProductCardFinalPrice">${selected_price.name}</span></div>`
          }
          html += `</div>`
          const desc = (product.description ?? product.description_override ?? variant.description ?? product.default_variant.description ?? "").replaceAll(`
`,"<br>");
          if (desc != "") {
            html += `<div class="elProductCardDescription"><span class="elProductBumpText">${desc}</span></div>`
          }
          if (customVariants.length > 1) {
            html += `<div class="elProductSelectSelectorsWrapper">`
            if (customVariants.length > 1) {
              html += `<div class="elSelectWrapper"><select class="elBumpVariantSelector">`
              const c10 = product.variants
              const fl11 = new CF2ForloopDrop(c10.length)
              for (const product_variant of c10) {
                const forloop = fl11
                const variant_index = forloop.index0;
                let combined_variant_name = "";
                const c12 = product.sorted_property_values
                const fl13 = new CF2ForloopDrop(c12.length)
                for (const property_values of c12) {
                  const forloop = fl13
                  const index0 = forloop.index0;
                  const property_id = property_values.property_id;
                  const property_value_id = product_variant.property_value_ids[index0];
                  if (combined_variant_name != "") {
                    combined_variant_name = combined_variant_name.toString().concat(", ");
                  }
                  combined_variant_name = combined_variant_name.toString().concat(product.all_properties[property_id]);
                  combined_variant_name = combined_variant_name.toString().concat(": ");
                  combined_variant_name = combined_variant_name.toString().concat(product.all_properties_values[property_value_id]);
                  forloop.next();
                }
                html += `<option value="${product_variant.property_value_ids}" `
                if (product_variant.id == variant.id) {
                  html += `selected`
                }
                html += `>${combined_variant_name}</option>`
                forloop.next();
              }
              html += `</select><div class="elSelectArrow"><i class="fas fa-chevron-down"></i></div></div>`
            }
            html += `</div>`
          }
          if (selectType == "quantity") {
            html += `<div class="elProductInputWrapper elProductCardQuantityContainer"><span>Quantity</span><div class="elProductInputControls"><button class="elProductCardInput">-</button><input class="elProductCardInput elProductCardShortInput" name="product" value="${quantity ?? 0}" min="0" type="number"/><button class="elProductCardInput">+</button></div></div>`
          } else if (selectType == "quantity-forced") {
            html += `<div class="elProductInputWrapper elSelectWrapper elForcedQuantityWrapper"><span>Quantity</span><select name="product" class="elProductCardInput elProductInputControls">`
            const step = product.step ?? 2;
            const min = product.min ?? 0;
            const max = product.max ?? 5;
            const item_count_step_1 = max- min;
            const item_count_step_2 = item_count_step_1/ step;
            const item_count_step_3 = item_count_step_2| 0;
            const range = Array.apply(0, Array(item_count_step_3)).map((element, index) => index + 0);;
            const c14 = range
            const fl15 = new CF2ForloopDrop(c14.length)
            for (const i of c14) {
              const forloop = fl15
              const value = i* step+ min;
              html += `<option value="${value}">${value}</option>`
              forloop.next();
            }
            html += `</select><div class="elSelectArrow elForcedQuantitySelectArrow"><i class="fas fa-chevron-down"></i></div></div>`
          }
          html += `</div>`
          if (selectType != "quantity") {
            html += `<div class="elProductCardModernButton">`
            if (isChecked) {
              html += `<span>- Unselect</span>`
            } else {
              html += `<span>+ Add</span>`
            }
            html += `</div>`
          }
        }

      }

      this.element.innerHTML = html;

      if (initializeChildrenInstances) {
        CF2Component.hydrateTree(this.element);
      }
    }


}

window["CheckoutProductCardBumpV2"] = CheckoutProductCardBumpV2

