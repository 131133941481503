
          
          import CheckoutInputValidator from "./checkout-input-validator-v1"
import CheckoutHelpersCriticalErrors from "./checkout-helpers-critical-errors-v1"
          import { CF2ComponentSingleton } from 'javascript/lander/runtime'

  class CheckoutHelpersStore extends CF2ComponentSingleton {
    create(billingFields, shippingFields, enabledPayments) {
      const paymentMethods = typeof Checkout.savedPaymentMethods === 'string' ? JSON.parse(Checkout.savedPaymentMethods) : Checkout.savedPaymentMethods
      const paymentDataId = paymentMethods?.[0]?.id ?? null
      const { mode, contact, shipping, billing, cart, itemByCartItemId, productCardByProductId, billingSameAsShipping, billing_addresses, shipping_addresses } = Checkout.ssrDynamicData

      const paymentType = enabledPayments[0]
      const initialCriticalErrors = []
      if ((enabledPayments ?? []).length == 0) {
        initialCriticalErrors.push({ code: CheckoutHelpersCriticalErrors.ERROR_CODES.EMPTY_PAYMENT_METHODS_ERROR })
      }
      if (!globalResourceData.productVariantWithNoBump?.id) {
        initialCriticalErrors.push({ code: CheckoutHelpersCriticalErrors.ERROR_CODES.EMPTY_PRODUCTS_ERROR })
      }


      return {
        state: nanostores.atom(Checkout.StoreStates.START),
        checkout: {
          mode: nanostores.atom(mode),
          lastModeIndependentOfCartItems: nanostores.atom(mode),
          step: nanostores.atom(0)
        },
        contact: nanostores.map(contact),
        contact_pending_auth: nanostores.map({authenticated: false}),
        shipping: nanostores.map(shipping),
        shipping_addresses: nanostores.atom(shipping_addresses ?? []),
        loadingShipping: nanostores.atom(false),
        shippingOptions: nanostores.atom([]),
        isUpdatingRebilly: nanostores.atom(true),
        shippingOption: nanostores.atom(),
        billingFields: nanostores.atom(billingFields),
        shippingFields: nanostores.atom(shippingFields),
        payment: {
          state: nanostores.atom(Checkout.PaymentStates.START),
          id: nanostores.atom(paymentDataId),
          type: nanostores.atom(paymentType),
          'payment-card': {
            events: nanostores.map({}),
            token: nanostores.atom(null),
          },
          paypal: {
            state: nanostores.atom({ state: Checkout.PaypalStates.IDLE }),
            token: nanostores.atom(null)
          },
          'apple-pay': {
            token: nanostores.atom(null)
          }
        },
        paymentMethods: nanostores.atom(paymentMethods ?? []),
        billing: nanostores.map(billing),
        billingApiErrorsByField: nanostores.atom(null),
        billing_addresses: nanostores.atom(billing_addresses ?? []),
        billingSameAsShipping: nanostores.atom(billingSameAsShipping),
        cart: nanostores.atom(cart),
        clickedCartItemIds: nanostores.atom(cart.map((c) => c.id)),
        coupons: {
          appliedCode: nanostores.atom(''),
          currentCode: nanostores.atom(''),
          errorMessage: nanostores.atom(''),
          state: nanostores.atom(Checkout.CouponStates.READY),
        },
        summary: nanostores.atom({
          state: Checkout.SummaryStates.WAITING,
          data: {},
        }),
        tos: {
          accepted: nanostores.atom(false),
          present: nanostores.atom(false),
        },
        criticalErrors: nanostores.atom(initialCriticalErrors),
        showAllErrors: {
          contact: nanostores.atom(false),
          shipping: nanostores.atom(false),
          billing: nanostores.atom(false),
          shippingOption: nanostores.atom(false),
          payment: nanostores.atom(false),
          products: nanostores.atom(false),
          tos: nanostores.atom(false),
        },
        submitting: nanostores.atom({
          state: Checkout.SubmittingStates.IDLE,
        }),
        incrScrollToFirstVisibleError: nanostores.atom(0),
        productCardByProductId: nanostores.map(productCardByProductId),
        threeds: nanostores.map({
          show: false,
          approvalUrl: null,
        }),
        featureFlags: {
          isShippingEnabled: nanostores.atom(false),
          isCouponEnabled: nanostores.atom(false),
        },
        selectedOrderDetailId: nanostores.atom(null),
        orderDetailsByProductId: nanostores.map({}),
        itemByCartItemId: nanostores.map(itemByCartItemId),
        phoneNumberInitialized: nanostores.atom(false),
      }
    }

    buildComputed(store, contactFields, shippingFields, totalSteps) {
      const computedModeLeaveEnterEvent = nanostores.atom({})
      nanostores.onMount(computedModeLeaveEnterEvent, () => {
        let previousMode
        return store.checkout.mode.subscribe(mode => {
          if (previousMode) {
            computedModeLeaveEnterEvent.set({
              leave: previousMode,
              next: mode 
            })
          }
          computedModeLeaveEnterEvent.set({
            enter: mode,
            previous: previousMode
          })
          previousMode = mode
        })
      })

      const computedContactErrors = nanostores.computed([
        store.checkout.mode,
        store.contact,
        store.showAllErrors.contact,
        ], (mode, contact, showAllErrors) => {
          const errors = {
            globalErrors: [],
            fields: {},
          }
          // NOTE: contact form should only be validated in guest mode.
          // In all other modes its currently disabled.
          if (mode != Checkout.CheckoutStates.GUEST) return null

          // NOTE: need to do something here
          contactFields.forEach((field) => {
            const value = contact[field]
            if (value == undefined && !showAllErrors) return

            const { valid, message } = CheckoutInputValidator.validateValue(field, value)
            if (!valid) {
              errors.fields[field] = { message }
            }
          })
          return Checkout.utils.cleanupEmptyErrors(errors)
      })

      const computedBillingErrors = nanostores.computed([
        store.billing,
        store.billingSameAsShipping,
        store.showAllErrors.billing,
        store.cart,
        store.checkout.mode,
        store.payment.type,
        store.billingApiErrorsByField,
        store.billingFields,
      ], (billing, billingSameAsShipping, showAllErrors, cart, mode, paymentType, billingApiErrorsByField, billingFields) => {
        return Checkout.utils.billingErrors(billing, billingSameAsShipping, showAllErrors, cart, mode, billingFields, billingApiErrorsByField, paymentType)
      })

      const computedShippingErrors = nanostores.computed([
        store.shipping,
        store.showAllErrors.shipping,
        store.cart,
        store.checkout.mode,
        store.payment.type,
      ], (shipping, showAllErrors, cart, mode, paymentType) => {
        return Checkout.utils.shippingErrors(shipping, showAllErrors, cart, mode, shippingFields, paymentType)
      })

      const computedHasValidShippingAddress = nanostores.computed([
        store.cart, store.shipping, store.checkout.mode
      ], (cart, shipping, mode) => {
        return !Checkout.utils.shippingErrors(shipping, true, cart, mode, shippingFields)
      })

      const computedProductErrors = nanostores.computed([
        store.cart,
        store.showAllErrors.products,
      ], (cart, showAllErrors) => {
        return Checkout.utils.productErrors(cart, showAllErrors)
      })

      const computedShippingOptionErrors = nanostores.computed([
        store.loadingShipping,
        store.cart,
        store.shippingOptions,
        store.shippingOption,
        store.showAllErrors.shippingOption,
        store.payment.type,
      ], (loadingShipping, cart, shippingOptions, shippingOption, showAllErrors, paymentType) => {
        const errors = {
          globalErrors: [],
          fields: {},
        }
        if (
          !showAllErrors ||
          loadingShipping || 
          !Checkout.utils.hasPhysicalProductsWithParams(cart) ||
          paymentType == 'apple-pay'
        ) {
          return null
        }
        const shippingOptionIsValid = shippingOption && shippingOptions?.length > 0
        if (!shippingOptionIsValid) {          
          const error = { message: 'Shipping is not available for this location' }
          errors.fields.shippingOption = error
          errors.globalErrors.push(error)
        }
        return Checkout.utils.cleanupEmptyErrors(errors)
      })

      const computedPaymentErrors = nanostores.computed([
        store.payment.id,
        store.payment.type,
        store.payment['payment-card'].events,
        store.payment.paypal.state,
        store.payment.paypal.token,
        store.showAllErrors.payment,
        store.checkout.mode,
      ], (id, type, cardEvents, paypalState, paypalToken, showAllErrors, mode) => {
        const errors = {
          globalErrors: [],
          fields: {},
        }
        if (!id && mode != Checkout.CheckoutStates.UPGRADE_DOWNGRADE) {
          if (type == 'payment-card') {
            let hasEvent = Object.keys(cardEvents).length
            if (hasEvent) {
              Object.entries(cardEvents).forEach(([field, event]) => {
                if (!event) {
                  event = { source: field }
                } else {
                  hasEvent = true
                }
                const { valid, message } = CheckoutInputValidator.validateValue('card', event)
                if (!valid) {
                  errors.fields[field] = { message }
                }
              })
            } else if (showAllErrors) {
              errors.globalErrors.push({ message: 'Missing payment information' })
            }
          } else if (type == 'paypal') {
            if (showAllErrors && !(paypalState.state == Checkout.PaypalStates.PAYMENT_METHOD_APPROVED && paypalToken)) { 
              errors.globalErrors.push({ message: 'Missing payment information' })
            }
          } else {
            if (showAllErrors) errors.globalErrors.push({ message: 'Missing payment information' })
          }
        }
        return Checkout.utils.cleanupEmptyErrors(errors)
      })

      const computedTosErrors = nanostores.computed([
          store.tos.accepted,
          store.tos.present,
          store.showAllErrors.tos,
        ], (accepted, present, showTosErrors) => {
          if(present && showTosErrors && !accepted) {
            return { globalErrors: [{ message: 'You must accept the terms of service' }] }
          } else {
            return []
          }
        }
      )

      const computedErrorsByName = {
        contact: computedContactErrors,
        billing: computedBillingErrors,
        shipping: computedShippingErrors,
        shippingOption: computedShippingOptionErrors,
        products: computedProductErrors,
        payment: computedPaymentErrors,
        tos: computedTosErrors,
      }

      const computedHasPhysicalProducts = nanostores.computed(store.cart, (cart) => {
        return Checkout.utils.hasPhysicalProductsWithParams(cart)
      })

      const computedPossibleOtoWithFreeShipping = nanostores.computed([store.cart, store.checkout.mode, store.shippingOptions, store.loadingShipping], (cart, mode, shippingOptions, loadingShipping) => {
        return (
          mode === 'oto' &&         
          Checkout.utils.hasPhysicalProductsWithParams(cart) 
        ) 
        && (
          // shipping options unknown / not loaded
          (!shippingOptions || 
          // pending with no current shipping options
          (loadingShipping && shippingOptions.length === 0)) ||
          // Exactly one shipping option with amount zero
          (        
            shippingOptions?.length === 1 && 
            parseInt(shippingOptions[0].amount.amount) === 0 
          )
        )
      })

      const computedHasShippingEnabled = nanostores.computed([store.cart, store.featureFlags.isShippingEnabled], (cart, isShippingEnabled) => {
        return  Checkout.utils.hasPhysicalProductsWithParams(cart) && isShippingEnabled
      })

      const computedHideShipping = nanostores.computed([computedHasShippingEnabled, computedPossibleOtoWithFreeShipping], (isShippingEnabled, possibleOtoWithFreeShipping) => {
        return !isShippingEnabled || possibleOtoWithFreeShipping
      })

      const computedAnyUpdatableOrders = nanostores.computed([store.orderDetailsByProductId], (orderDetailsByProductId) => {
        return !!Object.keys(orderDetailsByProductId).length
      })

      const computedCurrentPaymentMethod = nanostores.computed([store.payment.id], (currentPaymentId) => {
        return store.paymentMethods.get().find((pm) => pm.id == currentPaymentId)        
      })

      const computedIsCouponReady = nanostores.computed([
        store.checkout.mode,
        store.state,
        store.summary,
        store.cart,
        store.billing,
        store.shipping,
        store.billingSameAsShipping,
        store.payment.type,
        store.billingFields,
        store.billingApiErrorsByField,
      ], (mode, state, summary, cart, billing, shipping, billingSameAsShipping, paymentType, billingFields, billingApiErrorsByField)=> {
        const showAllBillingErrors = true
        const showAllShippingErrors = true
        const showAllProductErrors = true

        const pErrors = Checkout.utils.productErrors(cart, showAllProductErrors)
        const bErrors = Checkout.utils.billingErrors(billing, billingSameAsShipping, showAllBillingErrors, cart, mode, billingFields, billingApiErrorsByField, paymentType)
        const sErrors = Checkout.utils.shippingErrors(shipping, showAllShippingErrors, cart, mode, shippingFields, paymentType)
        return state == Checkout.StoreStates.FILLING_FORM && 
          summary.state == Checkout.SummaryStates.OK &&
          !Checkout.utils.hasErrors(pErrors) && !Checkout.utils.hasErrors(bErrors) && !Checkout.utils.hasErrors(sErrors)
      })

      const computedIsDigitalWalletReadyToStart = nanostores.computed([
        store.checkout.mode,
        store.state,
        store.summary,
        store.cart,
      ], (mode, state, summary, cart)=> {
        const showAllProductErrors = true
        const showAllErrorsContact = true
        const pErrors = Checkout.utils.productErrors(cart, showAllProductErrors)
        return state == Checkout.StoreStates.FILLING_FORM && 
          summary.state == Checkout.SummaryStates.OK &&
          !Checkout.utils.hasErrors(pErrors)
      })

      const computedHideContactInformationForm = nanostores.computed([
        store.checkout.mode,
        store.payment.type,
      ], (mode, paymentType) => {
        return paymentType == 'apple-pay' && (mode == 'guest' && totalSteps == 1)
      })


      const computedUseDigitalWalletForUpdatingContactStore = nanostores.computed([
        store.checkout.mode,
      ], (mode) => mode == 'guest')

      return {
        errorsByName: computedErrorsByName,
        modeLeaveEnterEvent: computedModeLeaveEnterEvent,
        hasPhysicalProducts: computedHasPhysicalProducts,
        hasShippingEnabled: computedHasShippingEnabled,
        hideShipping: computedHideShipping,
        hasValidShippingAddress: computedHasValidShippingAddress,
        isDigitalWalletReadyToStart: computedIsDigitalWalletReadyToStart,
        isCouponReady: computedIsCouponReady,
        contactErrors: computedContactErrors,
        productErrors: computedProductErrors,
        billingErrors: computedBillingErrors,
        shippingErrors: computedShippingErrors,
        shippingOptionErrors: computedShippingOptionErrors,
        paymentMethod: computedCurrentPaymentMethod,
        paymentErrors: computedPaymentErrors,
        tosErrors: computedTosErrors,
        anyUpdatableOrders: computedAnyUpdatableOrders,
        hideContactInformationForm: computedHideContactInformationForm,
        useDigitalWalletForUpdatingContactStore: computedUseDigitalWalletForUpdatingContactStore,
        isNewDigitalWalletPayment: nanostores.computed([Checkout.store.payment.id, Checkout.store.payment.type], (id, paymentType) => !id && paymentType == 'apple-pay')
      }
    }
  
    initializeUtilities(store, computed) {
      this.initializeStoreUtilities(store, computed)
      this.setupCFEnableDevTools()
    }

    initializeStoreUtilities(store, computed) {
      let proxyCache = new WeakMap();
      let storeValueCache = new WeakMap();
      const DEBOUNCE_INTERVAL = 50
      
      const stores = { 
        store,
        computed,
      }

      function createDeepNanostoreProxy (target, onChange) {
        return new Proxy(target, {
          get(target, property) {
            const item = target[property];
            if (property === "__store") {
              return item;
            }
            if (item && typeof item === "object") {
              if (proxyCache.has(item)) return proxyCache.get(item);
              if (isStore(item)) {
                // instead of proxy, return value holder
                const __store = item;
                const storeValue = {
                  __store,
                  __value: peek(__store),
                };
                storeValueCache.set(item, storeValue);
                __store.listen((value) => {
                  storeValue.__value = value;
                  onChange();
                });
                return storeValue;
              } else {
                const proxy = createDeepNanostoreProxy(item, onChange);
                proxyCache.set(item, proxy);
                return proxy;
              }
            }
            return item;
          },
        });
      }

      function peek(store) {
        return store.value;
      }
      function isStore(value) {
        return value && value.subscribe;
      }

      let debounceTimeout
      const proxy = createDeepNanostoreProxy(stores, function onChange() {
        clearTimeout(debounceTimeout)
        debounceTimeout = setTimeout(() => {
          window.dispatchEvent(new CustomEvent('checkout:store:change'))
        }, DEBOUNCE_INTERVAL)
      })

      /**
      * Stringify the whole proxy 
      * Replace stores with their current values
      */
      window.Checkout.utils.getStoreJson = (tab=0) => {
        return JSON.stringify(
          proxy,
          function replacer(key, value) {
            // prevent circular references breaking json
            if (["__store"].includes(key)) return;
            if (value && value.hasOwnProperty("__value")) {
              return value.__value;
            }
            return value;
          },
          tab
        );
      }
    }

    setupCFEnableDevTools () {
      if (localStorage.getItem('cf2:devtools:enabled')) {
        trackUpdatesCheckoutUpdates()
      }
      window.CFEnableDevTools = () => {
        localStorage.setItem('cf2:devtools:enabled', true)
        trackUpdatesCheckoutUpdates()
      }
      function trackUpdatesCheckoutUpdates() {
        document.querySelectorAll('[data-page-element="Checkout/V2"]').forEach((el) => {
          el.insertAdjacentHTML('afterend', '<div class="checkout-store-log"><pre></pre></div>')
          document.querySelector('.checkout-store-log').style.cssText = `
            z-index: 10;
            top: 0;
            background: rgba(100,100,1005,0.1);
            height: 100;
            display: flex;
            flex-direction: column;
            justify-content: center;`
          document.querySelector('.checkout-store-log pre').style.cssText = `
            font-size: 12px;
            font-weight: bold;
            width: fit-content;`
        })  
        const checkoutStoreLog = document.querySelector('.checkout-store-log')

        const renderDevToolsState = initReduxDevTools().render
        render()

        window.addEventListener('checkout:store:change', () => {
          render()
          if (renderDevToolsState) {
            renderDevToolsState()
          }
        })

        /** 
        * Lazily load Redux and create a store
        * When proxy changes, dispatch state updates to store 
        * Send clone by stringifying and then parsing JSON
        */

        function initReduxDevTools () {
          let store

          // inject redux
          const reduxUrl = 'https://cdnjs.cloudflare.com/ajax/libs/redux/4.2.1/redux.min.js'
          let scriptEle = document.createElement("script");
          scriptEle.setAttribute("src", reduxUrl);
          document.body.appendChild(scriptEle);

          // on load, create debug store
          scriptEle.addEventListener("load", () => {
              store = Redux.createStore(
                (state, action) => action.state || state,
                JSON.parse(window.Checkout.utils.getStoreJson()),
                window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
              );
          });
          
          return {          
            render () { // render callback for proxy change handler to call
              if (store) {
                store.dispatch({
                  type: "state",
                  state: JSON.parse(window.Checkout.utils.getStoreJson())
                });
              }
            }
          }
        }

        /**
        * Render JSON to div on change
        */
        function render() {
          let json
          let error
          try {
            const data = JSON.parse(window.Checkout.utils.getStoreJson(4))
            delete data.store.itemByCartItemId;
            delete data.store.productCardByProductId          
            json = JSON.stringify(data, null, 2)
          } catch (err) {
            error = err
            console.log(error)
          }
          checkoutStoreLog.querySelector('pre').innerHTML = 
            error ? error.message : 
            [
              '--- State ---',
              json
            ].join('\n')    
        }
      }
    }
  }
  const checkoutStore = CheckoutHelpersStore.getInstance()
  export default checkoutStore
        