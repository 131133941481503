
import "./input-v1"
import CheckoutHelpersSummary from "./checkout-helpers-summary-v1"
import { CF2Component } from 'javascript/lander/runtime'

export default class CheckoutCouponFormV1 extends CF2Component {

constructor(el, runtimeSel) {
super(el, runtimeSel)
}

hydrate(store) {
    this.checkoutStore = store
    this.isCouponFgEnabled = this.checkoutStore.featureFlags.isCouponEnabled.get()

    if (!this.isCouponFgEnabled) {
      this.element.remove()
      return
    }
    // NOTE: This is to avoid a flickering screen due to the isCouponFgEnabled being retrived
    // by an api, we can remove this when isCouponFgEnabled is true for all users.
    this.element.classList.remove('hide')

    this.isCouponReadySubs = Checkout.computed.isCouponReady.subscribe((isCouponReady) => {
      this.updateElementWithCanApply(isCouponReady)
    })

    this.currentCodeListen = this.checkoutStore.coupons.currentCode.listen((value) => {
      this.setInputValue(value)
    })

    this.couponsStateListen = this.checkoutStore.coupons.state.listen((state) => {
      this.updateElementWithCouponState(state)
    })

    this.renderElement()
    this.setupInputListeners()
  }

  cleanup() {
    this.isCouponReadySubs?.()
    this.currentCodeListen?.()
    this.couponsStateListen?.()
  }

  updateElementWithCanApply(isCouponReady) {
    if (isCouponReady) {
      const appliedCode = Checkout.store.coupons.appliedCode.get()
      if (!appliedCode) {
        this.enableCoupon()
      }
    } else {
      this.disableCoupon()
    }
  }

  updateElementWithCouponState(state) {
    switch (state) {
      case Checkout.CouponStates.READY: {
        this.clearError()
        this.enableCoupon()
        this.updateLoader(false)
        break;
      }
      case Checkout.CouponStates.ERROR: {
        this.setError(this.checkoutStore.coupons.errorMessage.get())
        this.enableCoupon()
        this.updateLoader(false)
        break;
      }
      case Checkout.CouponStates.APPLIED: {
        this.clearError()
        this.disableCoupon()
        this.updateLoader(false)
        break;
      }
      case Checkout.CouponStates.APPLYING: {
        this.disableCoupon()
        this.clearError()
        this.updateLoader(true)
        break;
      }
    }
  }

  renderElement() {
    const currentCode = this.checkoutStore.coupons.currentCode.get()
    const appliedCode = this.checkoutStore.coupons.appliedCode.get()
    const coupon = currentCode || appliedCode
    if (coupon) {
      this.getCouponInput().value = coupon
      this.getCouponInputContainer().classList.add('hasValue')
    }
    const state = this.checkoutStore.coupons.state.get()
    this.updateElementWithCouponState(state)
    const isCouponReady = Checkout.computed.isCouponReady.get()
    this.updateElementWithCanApply(isCouponReady)
  }

  setupInputListeners() {
    const input = this.getCouponInput()
    if (input !== null) { 
      input.addEventListener('blur', () => {
        this.checkoutStore.coupons.currentCode.set(input.value?.trim())
      })
      input.addEventListener('focus', () => {
        if (!input.value) {
          this.clearError()
        }
      })
      input.addEventListener('change', () => {
        this.clearError()
      })
    }

    this.getCouponApplyButton().addEventListener('click', (ev) => {
      ev.preventDefault()
      ev.stopPropagation()
      const code = this.checkoutStore.coupons.currentCode.get()
      if (!code) {
        this.checkoutStore.coupons.errorMessage.set("Discount code is blank")
        this.checkoutStore.coupons.state.set(Checkout.CouponStates.ERROR)
        return
      }
      // NOTE: <ONE_STORE_PER_CHECKOUT> this should be stored per checkout object store
      CheckoutHelpersSummary.sendOrderPreview({isCouponValidation: true})
    })
  }
  enableCoupon() {
    this.getCouponApplyButton().setAttribute("data-disabled", false)
    this.getCouponInput().removeAttribute("disabled")
  }
  disableCoupon() {
    this.getCouponApplyButton().setAttribute("data-disabled", true)
    this.getCouponInput().setAttribute("disabled", true)
  }
  updateLoader(isLoading) {
    if (isLoading == undefined) {
      isLoading = this.lastLoading
    } else {
      this.lastLoading = isLoading
    }

    this.getCouponButtonContainer().dataset.loading = isLoading
  }
  setInputValue(value) {
    const input = this.getCouponInput()
    value = value?.trim()
    if (value) {
      input.value = value
      this.getCouponInputContainer().classList.add('hasValue')
    } else {
      input.value = ''
      this.getCouponInputContainer().classList.remove('hasValue')
    }
  }
  setError(message) {
    this.getCouponErrorContainer().innerHTML = message
    this.getCouponInputContainer().classList.add("elInputError")
    this.getCouponIconContainer().classList.add("elInputError")
  }
  clearError() {
    this.getCouponErrorContainer().innerHTML = ""
    this.getCouponInputContainer().classList.remove("elInputError")
    this.getCouponIconContainer().classList.remove("elInputError")
  }
  getCouponInput() {
    return this.element.querySelector('input')
  }
  getCouponInputContainer() {
    return this.element.querySelector('.elCheckoutCouponCodeInput')
  }
  getCouponApplyButton() {
    return this.element.querySelector('.elCheckoutCouponCodeButton a[href="#check-coupon-code"]')
  }
  getCouponButtonContainer() {
    return this.element.querySelector('.elCheckoutCouponCodeButton')
  }
  getCouponErrorContainer() {
    return this.element.querySelector('.elCouponCodeError')
  }
  getCouponIconContainer() {
    return this.element.querySelector('.elCheckoutCouponFormWrapper')
  }



}

window["CheckoutCouponFormV1"] = CheckoutCouponFormV1

