
          
          import CheckoutHelpersSummary from "./checkout-helpers-summary-v1"
import CheckoutHelpersSubmit from "./checkout-helpers-submit-v1"
          import { CF2ComponentSingleton } from 'javascript/lander/runtime'
  
  class CheckoutHelpersDigitalWallet extends CF2ComponentSingleton {
    initialize() {
      window.Rebilly.on('token-ready', (data, extraData) => {
        const paymentType = Checkout.store.payment.type.get()
        Checkout.store.payment[paymentType].token.set(data.id)
        CheckoutHelpersSummary.skipOrderSummaryUpdateWithCb(() => {
          if (paymentType == 'apple-pay') {
            const shippingAddress = extraData?.shippingDetails?.address
            const billingAddress = data.billingAddress
            const {emailAddress: shippingEmail, familyName: shippingLN, givenName: shippingFN } = shippingAddress ?? {}
            const {firstName: billingFN, lastName: billingLN, address: billingAddr, address2: billingAddr2} = billingAddress

            if (Checkout.computed.hasPhysicalProducts.get()) {
              Checkout.store.shipping.set({
                address: shippingAddress.addressLines?.[0],
                address_2: shippingAddress.addressLines?.[1],
                country: shippingAddress.countryCode.toUpperCase(),
                city: shippingAddress.locality,
                zip: shippingAddress.postalCode,
                state: shippingAddress.administrativeArea,
              })
            }
            Checkout.store.billing.set({
              address: billingAddress.address,
              address_2: billingAddress.address2,
              country: billingAddress.country.toUpperCase(),
              city: billingAddress.city,
              zip: billingAddress.postalCode,
              state: billingAddress.region,
            })
            let contact
            if (Checkout.computed.useDigitalWalletForUpdatingContactStore.get()) {
              contact = {
                email: shippingEmail,
                first_name: [shippingFN, billingFN].find(Boolean),
                last_name: [shippingLN, billingLN].find(Boolean),
                phone_number: Checkout.store.contact.get().phone_number,
              }
            }
            const payload = CheckoutHelpersSubmit.buildPayloadFromStore({contact})
            CheckoutHelpersSubmit.submit(payload)
          }
        })
      })
      window.Rebilly.on('error', (err) => {
        if (err.code == 'apple-pay-error') {
          // NOTE: this is captured when apple pay modal is closed
          // This clears all data appel pay modal started with
          this.#reset()
       }
      })
      window.Rebilly.on('shipping-address-changed', (shippingAddress, updateShippingOptions) => {
        CheckoutHelpersSummary.skipOrderSummaryUpdateWithCb(() => {
          Checkout.store.shipping.set({
            country: shippingAddress.countryCode.toUpperCase(),
            city: shippingAddress.locality,
            zip: shippingAddress.postalCode,
            state: shippingAddress.administrativeArea,
          })
        })
        this.#updateRebillyWithCallback(updateShippingOptions, 'shipping-address-changed')
      });
      window.Rebilly.on('shipping-option-changed', (selectedShippingOption, updateTransaction) => {
        CheckoutHelpersSummary.skipOrderSummaryUpdateWithCb(() => {
          const shippingOptions = Checkout.store.shippingOptions.get()
          const shippingOption = shippingOptions.find((s) => CheckoutHelpersSubmit.generateShippingOptionId(s) == selectedShippingOption.id)
          Checkout.store.shippingOption.set(shippingOption)
        })
        this.#updateRebillyWithCallback(updateTransaction, 'shipping-option-changed')
      })
      window.Rebilly.on('billing-address-changed', (billingAddress, updateTransaction) => {
        if (billingAddress) {
          CheckoutHelpersSummary.skipOrderSummaryUpdateWithCb(() => {
            Checkout.store.billing.set({
              country: billingAddress.countryCode.toUpperCase(),
              city: billingAddress.locality,
              zip: billingAddress.postalCode,
              state: billingAddress.administrativeArea,
            })
          })
        }
        this.#updateRebillyWithCallback(updateTransaction, 'billing-address-changed')
      });
    }

    #reset() {
      Checkout.store.shippingOptions.set([])
      Checkout.store.shipping.set({})
      Checkout.store.billing.set({})
      Checkout.store.shippingOption.set(null)
    }

    listen() {
      let previousIsNewDigitalWalletPayment = Checkout.computed.isNewDigitalWalletPayment.get()
      Checkout.computed.isNewDigitalWalletPayment.subscribe((isNewDigitalWalletPayment) => {
        if (isNewDigitalWalletPayment) {
          this.clearAddresses()
        } else if (previousIsNewDigitalWalletPayment && !isNewDigitalWalletPayment) {
          this.restoreAddresses()
        }
        previousIsNewDigitalWalletPayment = isNewDigitalWalletPayment
      })            
    }

    #updateRebillyWithCallback(updateCallback, fnCallbackName) {
      CheckoutHelpersSummary.fetchOrderPreview({callRebillyUpdate: false}).then(() => {
        const summary = Checkout.store.summary.get()
        const cart = Checkout.store.cart.get()
        const shippingEnabled = Checkout.utils.hasPhysicalProducts()
        const shippingOptions = Checkout.store.shippingOptions.get()
        const transactionData = CheckoutHelpersSubmit.buildDigitalWalletTransactionData(summary.data, cart, shippingEnabled, shippingOptions)
        updateCallback(transactionData)
      })
    }

    restoreAddresses() {
      Checkout.store.shipping.set(this.oldShipping)
      Checkout.store.billing.set(this.oldBilling)
      Checkout.store.billingSameAsShipping.set(this.oldBillingSameAsShipping)
    }

    clearAddresses() {
      this.oldShipping = Checkout.store.shipping.get()
      this.oldBilling = Checkout.store.billing.get()
      this.oldBillingSameAsShipping = Checkout.store.billingSameAsShipping.get()

      Checkout.store.shipping.set({})
      Checkout.store.billing.set({})
      Checkout.store.billingSameAsShipping.set(false)
    }
  }
  export default CheckoutHelpersDigitalWallet.getInstance()
        