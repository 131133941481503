
          
          
          import { CF2ComponentSingleton } from 'javascript/lander/runtime'

  class CheckoutAddressUtilsV1 extends CF2ComponentSingleton {
    STATES_WITH_POSTAL_CODE = new Set(['US', 'GB', 'CA', 'FI', 'FR', 'IT', 'ES'])
    backfillAddressId(address) {
      if (address.id) return address
      let addressId = 'no-id#'
      addressId += [address.country, address.state, address.city, address.zip, address.address, address.address_2].filter(Boolean).join(':')
      
      return { ...address, id: addressId }
    }

    backfillAddressesId(addresses) {
      return addresses.map((address) => this.backfillAddressId(address))
    }

    isSavedAddress(address) {
      return address.id && !String(address.id).includes('no-id#')
    }

    fieldsForCountry(fields, country) {
      const fieldsLength = Object.keys(fields).length
      if (fieldsLength > 0 && fieldsLength <= 2) {
        const hasPostalCode = this.STATES_WITH_POSTAL_CODE.has(country)
        if (hasPostalCode) {
          return ['country', 'zip']
        } else {
          return ['country']
        }
      }
      return fields
    }

    addressToString(address) {
      let str = ''
      if (address.address) {
        str = address.address
        if (address.address2) {
          str += `, ${address.address2}`
        }
        if (address.city) {
          str += `. ${address.city}`
        }
        if (address.state) {
          str += `, ${address.state}`
        }
        if (address.zip) {
          str += ` ${address.zip}`
        }
        if (address.country) {
          str += `, ${address.country}`
        }
      } else {
        if (address.zip) {
          str += `${address.zip}, `
        }
        if (address.country) {
          str += `${address.country}`
        }
      }
      return str
    }

    parseAddressByFields(address, fields, shouldBackfill = true) {
      const addressByFields = fields.reduce((acc, field) => {
        acc[field] = address[field] ?? (shouldBackfill ? Checkout.contactLocale[field] : null)
        return acc
      }, {})

      return this.cleanEmptyObjectKeys({...addressByFields, id: address.id})
    }

    cleanEmptyObjectKeys(obj) {
      for (var propName in obj) {
        if ([null, undefined, ""].includes(obj[propName])) {
          delete obj[propName];
        }
      }
      return obj
    }
  }

  const checkoutAddressUtils = CheckoutAddressUtilsV1.getInstance();
  export default checkoutAddressUtils;
        