
import Cart from "./checkout-helpers-cart-v1"
import "./checkout-coupon-form-v1"
import CheckoutHelpersSummary from "./checkout-helpers-summary-v1"
import { CF2Component } from 'javascript/lander/runtime'

export default class CheckoutOrderSummaryV1 extends CF2Component {

constructor(el, runtimeSel) {
super(el, runtimeSel)
}

checkoutStoreReady(store) {
    this.checkoutStore = store
    ;[
      this.checkoutStore.cart, 
      this.checkoutStore.summary
    ].forEach((store) => {
      store.listen(() => {
        this.renderSummaryDetails()
      })
    })

    nanostores.computed([Checkout.store.payment.type], (paymentType) => paymentType == 'apple-pay')
      .subscribe(isDigitalWalletPaymentType => {
        if (isDigitalWalletPaymentType && !this.couponFieldEnabled) {
          this.hide()
        } else {
          this.show()
        }
      })

      this.renderSummaryDetails()

    Checkout.store.state.listen((state) => {
      if (state === Checkout.StoreStates.FILLING_FORM) {
        this.renderSummaryDetails()
      }
    })
  }

  show() {
    this.element.classList.remove('elHide');
  }

  hide() {
    this.element.classList.add('elHide');
  }

  renderSummaryDetails() {
    const summary = this.checkoutStore.summary.get() 
    this.state = summary.state

    this.summary = !summary.data ? {} : summary.data

    this.hasPhysicalProducts = Checkout.utils.hasPhysicalProducts()
    this.cart = this.checkoutStore.cart.get().map(({ id, quantity }) => {
      const { variantId, priceId } = Cart.getCartIdDetails(id)
      const variant = Checkout.variantsById[variantId]
      const price = Checkout.pricesById[priceId]
      return {
        variantName: variant.name,
        priceName: price.name,
        quantity
      }
    })

    let couponForm = this.getComponent('CheckoutCouponForm/V1')
    couponForm?.cleanup(this.checkoutStore)
    this.render(true)
    this.hydrate()
    couponForm = this.getComponent('CheckoutCouponForm/V1')
    couponForm?.hydrate(this.checkoutStore)
  }

  hydrate() {
    this.element.querySelector('.elOrderSummaryHead')?.addEventListener('click', (evt) => {
      evt.preventDefault();
      this.open = !this.open
      this.renderSummaryDetails()
    })
    this.element.querySelector('.elOrderSummaryCouponClear')?.addEventListener('click', (evt) => {
      evt.preventDefault();
      this.checkoutStore.coupons.currentCode.set("")
      this.checkoutStore.coupons.appliedCode.set("")
      CheckoutHelpersSummary.sendOrderPreview()
    })
  }


    remove() {
      this.element.innerHTML = '';
    }
    render(initializeChildrenInstances = false) {
      const is_server = true;
      const couponFieldEnabled = this.couponFieldEnabled ?? null ;
const open = this.open ?? null ;
const state = this.state ?? "ok" ;
const cart = this.cart ?? [] ;
const summary = this.summary ?? {} ;
const hasPhysicalProducts = this.hasPhysicalProducts ?? false ;
const billingFields = this.billingFields ?? null ;
const shippingFields = this.shippingFields ?? null ;

    const linkWithCheckout = this.element.getAttribute('data-param-linkWithCheckout');
const linkedCheckoutId = this.element.getAttribute('data-param-linkedCheckoutId');
  
      let html = '';
      {
        html += `<div class="elCheckoutWrapper">`
        if (linkWithCheckout == "true" || linkWithCheckout == true) {
          html += `<div class="elCheckoutOuterFrame"></div>`
        }
        html += `<div class="elOrderSummaryHead"><div class="elOrderSummaryRow"><div class="elOrderSummaryRowLeft"><i class="fa fa-shopping-basket"></i><span class="elOrderSummaryHeadText">Summary</span></div><div class="elOrderSummaryRowRight">`
        if (state == "waiting") {
          html += `<span> For more details, fill the form <i style="margin:0px 10px;" class="fas fa-spinner fa-spin"></i></span>`
        } else if (state == "calculating") {
          html += `<span> Calculating <i style="margin:0px 10px;" class="fas fa-spinner fa-spin"></i></span>`
        } else if (state == "error") {
          html += `<span> Something Unexpected Happened! <i style="margin:0px 10px;" class="fas fa-spinner fa-spin"></i></span>`
        }
        if (open) {
          html += `<i class="fas fa-chevron-up elOrderSummaryToggleable"></i>`
        } else {
          if (state == "ok") {
            html += `<span class="elOrderSummaryTotalPrice">`
            if (summary.interim) {
            html += `${summary.interim.formatted}`
            } else {
            html += `${summary.total.formatted}`
            }
            html += `</span>`
          }
          html += ` `
          html += `<i class="fas fa-chevron-down elOrderSummaryToggleable"></i>`
        }
        html += `</div></div></div>`
        if (open) {
          if (couponFieldEnabled && couponFieldEnabled != "false") {
            html += `<div data-page-element="CheckoutCouponForm/V1" class="elCheckoutCouponFormContainer id-CheckoutCouponForm/V1 hide" data-liquid-replace="item"><div class="elCheckoutCouponFormWrapper"><i data-page-element="IconNode" class="fa-fw fa_icon fa_prepended elCheckoutCouponTagIcon fa fa-tag id-IconNode"></i><div data-page-element="Input/V1" class="elFormItemWrapper elInputWrapper de-input-block elCheckoutCouponCodeInput id-Input/V1 labelInside" type="coupon_codes" data-liquid-replace="item"><input class="elFormItem elInput inputHolder " value="" type="text" name="coupon_codes" pattern="" autocomplete="" inputtype="" data-prevent-submit="" data-prevent-garlic="true" placeholder="Enter Coupon" maxlength=""/><div class="elLabel"><div class="borderHolder"></div><div class="borderHolder labelText"><label>Enter Coupon</label></div><div class="borderHolder"></div></div><div class="inputHolder" data-input-status-type="true" data-error-container="true"></div><div class="input-icon"><i class="fas fa-exclamation"></i></div></div><div class="elCheckoutCouponCodeButtonWrapper"><div data-page-element="Button/V1" class="elBTN elCheckoutCouponCodeButton id-Button/V1" data-liquid-replace="item"><a target="_self" href="#check-coupon-code" class="elButton" aria-label="" data-disabled="false" data-show-button-ids="" data-hide-button-ids="" data-on-submit-go-to="" data-param-submittingText="Submitting..." data-style-guide-button="" rel="noopener"><span class="elButtonMain"><i class="fas fa-spinner fa-spin elButtonSpinner elButtonText"></i><span class="elButtonMainText elButtonText">Apply</span></span><span class="elButtonSub"></span></a></div><span class="elCheckoutCouponCodeButtonLoading"><i style="margin:0px 10px;" class="fas fa-spinner fa-spin"></i></span></div></div><paragraph-v1 class="elCouponCodeError"></paragraph-v1></div>`
          }
          html += `<div class="elOrderSummaryBody">`
          const c0 = cart
          const fl1 = new CF2ForloopDrop(c0.length)
          for (const item of c0) {
            const forloop = fl1
            html += `<div class="elOrderSummaryRow"><div class="elOrderSummaryRowLeft">${item.variantName}</div><div class="elOrderSummaryRowRight">`
            if (item.quantity && item.quantity > 1) {
              html += `<span class="elOrderSummaryQuantity">Qty ${item.quantity}</span>`
            }
            html += `<span>${item.priceName}</span></div></div>`
            forloop.next();
          }
          if (state == "ok") {
            if (summary.upcoming_invoice && summary.upcoming_invoice.line_items.length) {
              html += `<hr class="elOrderSummaryDivider"/>`
              const c2 = summary.upcoming_invoice.line_items
              const fl3 = new CF2ForloopDrop(c2.length)
              for (const item of c2) {
                const forloop = fl3
                html += `<div class="elOrderSummaryRow"><div class="elOrderSummaryRowLeft">${item.variant_name}</div><div class="elOrderSummaryRowRight">`
                if (item.quantity && item.quantity > 1) {
                  html += `<span class="elOrderSummaryQuantity">Qty ${item.quantity}</span>`
                }
                html += `<span>${item.formatted}</span></div></div>`
                forloop.next();
              }
            }
            html += `<hr class="elOrderSummaryDivider"/>`
            html += `<div class="elOrderSummaryRow"><div class="elOrderSummaryRowLeft"> Taxes </div><div class="elOrderSummaryRowRight">${summary.tax.formatted}</div></div>`
            if (summary.discount.amount > 0 && summary.discounts.length > 0) {
              const c4 = summary.discounts
              const fl5 = new CF2ForloopDrop(c4.length)
              for (const discount of c4) {
                const forloop = fl5
                html += `<div class="elOrderSummaryRow elOrderSummaryDiscountRow"><div class="elOrderSummaryRowLeft"> Discount `
                if (discount.code) {
                  html += `(`
                  html += `${discount.code})`
                }
                html += `<span class="elOrderSummaryCouponClear">X</span></div><div class="elOrderSummaryRowRight">${summary.discount.formatted}</div></div>`
                forloop.next();
              }
            }
            if (hasPhysicalProducts) {
              html += `<div class="elOrderSummaryRow"><div class="elOrderSummaryRowLeft"> Shipping </div><div class="elOrderSummaryRowRight">`
              if (summary.shipping.amount > 0) {
              html += `${summary.shipping.formatted}`
              } else {
                html += ` FREE `
              }
              html += `</div></div>`
            }
            html += `<hr class="elOrderSummaryDivider"/>`
            html += `<div class="elOrderSummaryRow"><div class="elOrderSummaryRowLeft"> Total </div><div class="elOrderSummaryRowRight"><span class="elOrderSummaryTotalPrice">`
            if (summary.interim) {
            html += `${summary.interim.formatted}`
            } else {
            html += `${summary.total.formatted}`
            }
            html += `</span></div></div>`
          }
          html += `</div>`
        }
        html += `</div>`

      }

      this.element.innerHTML = html;

      if (initializeChildrenInstances) {
        CF2Component.hydrateTree(this.element);
      }
    }


}

window["CheckoutOrderSummaryV1"] = CheckoutOrderSummaryV1

