
          
          
          import { CF2ComponentSingleton } from 'javascript/lander/runtime'
  
  class CheckoutPathUtilsV1 extends CF2ComponentSingleton{
    renderedPath() {
      const renderedPage = document.cookie.split("; ").find( x => x.match(/^cfhoy_rendered_page=/))?.split("=")[1]
      return renderedPage ?? window.location.pathname
    }

    renderedHref(){
      const url = new URL(window.location.href)
      url.pathname = this.renderedPath()
      return url.toString();
    }
  }

  const pathUtils = CheckoutPathUtilsV1.getInstance();
  export default pathUtils;
        