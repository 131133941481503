
          
          import Cart from "./checkout-helpers-cart-v1"
import CriticalErrors from "./checkout-helpers-critical-errors-v1"
import pathUtils from "./checkout-path-utils-v1"
          import { CF2ComponentSingleton } from 'javascript/lander/runtime'

  class CheckoutHelpersExistingOrdersV1 extends CF2ComponentSingleton {
    REACTAVATABLE_SERVICE_STATUS = ['canceled', 'churned']

    #hasReactivatableItems = false
    #hasUpgradeDowngradeItems = false
    isSelectedCartItemUpdatable = false
    #orderDetailsByProductId = {}
    #productCardDetails = {}
    orderDetailsByOrderId = {}

    TYPES = {
      upgradeDowngrade: 'upgradeDowngrade',
      reactivate: 'reactivate',
    }

    fetch(updateStore = true) {
      const orderId = new URLSearchParams(window.location.search).get('manage_order_id')
      const params = orderId ? `?manage_order_id=${orderId}` : ''
      return window.CFFetch(
        `${window.location.origin}${pathUtils.renderedPath()}/existing_orders${params}`,
        {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
          method: 'GET',
        },
        { retries: 3, shouldCaptureServerError: true }
      )
      .then((response) => response.json())
      .then((data) => {
        this.cleanUp()
        this.processExistingOrdersResponse(data, updateStore)
      })
      .catch((e) => {
        throw new CriticalErrors.CheckoutCriticalError(CriticalErrors.ERROR_CODES.FETCH_EXISTING_ORDERS_ERROR, { cause: e })
      })
    }

    cleanUp() {
      this.#hasReactivatableItems = false
      this.#hasUpgradeDowngradeItems = false
      this.orderDetailsByOrderId = {}
      this.#productCardDetails = {}
      this.#orderDetailsByProductId = {}
      Checkout.store.orderDetailsByProductId.set({})
    }

    processExistingOrdersResponse(existingOrdersResponse, updateStore = true) {
      existingOrdersResponse.orders.forEach((order, orderIndex) => {
        const orderId = order.id
        this.orderDetailsByOrderId[orderId] = order
        const orderNumber = order.order_number

        order.line_items.forEach((lineItem) => {
          const productId = lineItem.product_id

          this.#orderDetailsByProductId[productId] = this.#orderDetailsByProductId[productId] ?? {orders: {}, sortedOrderIds: []}
          this.#orderDetailsByProductId[productId].orders[orderId] = this.#orderDetailsByProductId[productId].orders[orderId] ?? {id: orderId, number: orderNumber, updatableCartItemIds: []}

          // NOTE: we are using sortedOrderIds instead of having that within the object in a sortOrder facet because
          // it is helpful for consuming this data in the liquidjs syntax as it would require to grab object values 
          // to sort orders it properly
          const sortedOrderIds = this.#orderDetailsByProductId[productId].sortedOrderIds
          if (!sortedOrderIds.some((id) => id == orderId)) {
            sortedOrderIds.push(orderId)
          }

          const lineItemSubscriptionDetails = lineItem.subscription_details
          if (this.REACTAVATABLE_SERVICE_STATUS.includes(lineItemSubscriptionDetails?.status) && lineItemSubscriptionDetails?.reactivatable) {
            this.processReactivation(lineItem, orderId, orderNumber, productId)
          } else {
            this.processUpgradeDowngrades(lineItem, orderId, orderNumber, productId)
          }
        })
      })
      if (updateStore) {
        this.updateStore()
      }
    }

    updateStore() {
      if (this.#hasReactivatableItems || this.#hasUpgradeDowngradeItems) {
        Checkout.store.orderDetailsByProductId.set(this.#orderDetailsByProductId)
        this.updateSelectedCardItem()
      }
    }

    processReactivation(lineItem, orderId, orderNumber, productId) {
      const lineItemId = lineItem.id
      const variantId = lineItem.variant_id
      const priceId = lineItem.price_id

      if (!Checkout.variantsById[variantId] || !Checkout.pricesById[priceId]) {
        return
      }
  
      this.#hasReactivatableItems = true
      this.#orderDetailsByProductId[productId].orders[orderId].reactivation = true
      this.#orderDetailsByProductId[productId].orders[orderId].lineItemId = lineItemId
      this.#orderDetailsByProductId[productId].orders[orderId].variantId = variantId
      this.#orderDetailsByProductId[productId].orders[orderId].priceId = priceId
      this.storeCartItem(Cart.ITEM_TYPES.REACTIVATE, orderId, orderNumber, productId, variantId, priceId, lineItemId)

      const updatableItemId = Cart.buildCartUpdatableItemId(Cart.ITEM_TYPES.REACTIVATE, orderId, lineItemId, productId, variantId, priceId)
      if (!this.#productCardDetails[productId]) {
        this.storeProductCardDetails(Checkout.CheckoutStates.REACTIVATE, orderId, productId, variantId, priceId, updatableItemId)
      }
    }

    processUpgradeDowngrades(lineItem, orderId, orderNumber, productId) {
      const lineItemId = lineItem.id

      lineItem.upgrade_downgrade_options.forEach((upgradeDowngrade) => {
        const variantId = upgradeDowngrade.variant_to_id
        const priceId = upgradeDowngrade.price_to_id
        if (!Checkout.variantsById[variantId] || !Checkout.pricesById[priceId]) {
          return
        }
        this.#hasUpgradeDowngradeItems = true

        const direction = upgradeDowngrade.direction.charAt(0).toUpperCase() + upgradeDowngrade.direction.slice(1)
        this.storeCartItem(Cart.ITEM_TYPES.UPGRADE_DOWNGRADE, orderId, orderNumber, productId, variantId, priceId, lineItemId, { direction })
        this.storeUpdatableCartItemIds(Cart.ITEM_TYPES.UPGRADE_DOWNGRADE, orderId, lineItemId, productId, variantId, priceId)

        const selectedUpdatableCartItemId = this.#orderDetailsByProductId[productId].orders[orderId].updatableCartItemIds[0]
        if (!this.#productCardDetails[productId]) {
          this.storeProductCardDetails(Checkout.CheckoutStates.UPGRADE_DOWNGRADE, orderId, productId, variantId, priceId, selectedUpdatableCartItemId)
        }
      })
    }


    storeCartItem(type, orderId, orderNumber, productId, variantId, priceId, lineItemId, extraData) {
      const id = Cart.buildCartUpdatableItemId(type, orderId, lineItemId, productId, variantId, priceId)
      const product = Checkout.productsById[productId]
      const variant = Checkout.variantsById[variantId]
      const price = Checkout.pricesById[priceId]
      const orderDetails = {
        type,
        orderId,
        orderNumber,
        lineItemId,
        extraData,
      }
      Cart.storeCartItem(id, product, variant, price, orderDetails)
    }

    storeUpdatableCartItemIds(itemType, orderId, lineItemId, productId, variantId, priceId) {
      const updatableCartItemIds = this.#orderDetailsByProductId[productId].orders[orderId].updatableCartItemIds
      const updatableCartItemId = Cart.buildCartUpdatableItemId(itemType, orderId, lineItemId, productId, variantId, priceId)
      if (updatableCartItemIds.indexOf(updatableCartItemId) == -1) {
        updatableCartItemIds.push(updatableCartItemId)
      }
    }

    storeProductCardDetails(renderType, orderId, productId, variantId, priceId, selectedUpdatableCartItemId) {
      this.#productCardDetails[productId] = {
        variantId,
        priceId,
        selectedUpdatableCartItemId,
        selectedOrderId: orderId,
        renderType,
      }
    }

    updateSelectedCardItem() {
      let selectedCartItem 
      Checkout.store.cart.get().find((cartItem) => {
        const { productId } = Cart.getCartIdDetails(cartItem.id)
        const id = this.#productCardDetails[productId] ? this.#productCardDetails[productId].selectedUpdatableCartItemId : cartItem.id
        if (!Checkout.productsById[productId].bump) {
          selectedCartItem = {
            productId,
            id,
          }
          return true
        }
      })

      if (!selectedCartItem) {
        const product = Checkout.products.filter((p) => !p.bump)[0]
        const productId = product.id
        const variantId = product.variants[0].id
        const priceId = product.variants[0].prices[0].id
        let id
        if (this.#productCardDetails[productId]) {
          id = this.#productCardDetails[productId].selectedUpdatableCartItemId
        } else {
          id = Cart.buildCartItemId(productId, variantId, priceId)
        }
        selectedCartItem = {
          productId,
          id,
        }
      }

      const productCardsById = Checkout.store.productCardByProductId.get()
      Object.keys(productCardsById).forEach((productId) => {
        const res = { 
          ...(this.#productCardDetails[productId] ?? {}),
          quantity: selectedCartItem.productId == productId ? 1 : 0,
        }
        const pccState = productCardsById[productId]
        Checkout.store.productCardByProductId.setKey(productId, {
          ...pccState,
          ...res,
        })
      })

      Checkout.store.cart.set([{id: selectedCartItem.id, quantity: 1}])
      if (Cart.isCartItemIdUpdatable(selectedCartItem.id)) {
        this.isSelectedCartItemUpdatable = true
      }
    }
  }
  export default CheckoutHelpersExistingOrdersV1.getInstance()
        