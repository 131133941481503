
          
          import Cart from "./checkout-helpers-cart-v1"
import CriticalErrors from "./checkout-helpers-critical-errors-v1"
          import { CF2ComponentSingleton } from 'javascript/lander/runtime'
  class CheckoutHelpersDataLoader extends CF2ComponentSingleton {

    productSelectType = globalThis.globalResourceData?.show_page_step?.product_quantity_type ?? "single"
    productVariantWithNoBump = globalThis.globalResourceData?.productVariantWithNoBump

    getSSRGlobalResourceData() {
      const { checkout_v2_state, contact } = globalThis?.sessionData ?? {}
      const initialContactData = checkout_v2_state === 'guest' 
        ? {}
        : cleanEmptyObjectKeys({
          first_name: contact?.first_name ?? "",
          last_name: contact?.last_name ?? "",
          email: contact?.email ?? "",
          phone_number: contact?.phone_number ?? "",
        })

      const productsById = {}
      const variantsById = {}
      const pricesById = {}
      const savedPaymentMethods = contact?.payment_methods ?? []

      const itemByCartItemId = {}
      const products = globalThis.globalResourceData.products;
      products?.forEach((product) => {
        const { variants } = product
        productsById[product.id] = product
        return variants.forEach((variant) => {
          const { id: variantId, prices } = variant
          variantsById[variantId] = variant
          return prices.forEach((price) => {
            const { id: priceId } = price
            pricesById[priceId] = price
            const cartItemId = Cart.buildCartItemId(product.id, variantId, priceId)
            itemByCartItemId[cartItemId] = Cart.buildCartItemData(product, variant, price)
          })
        })
      })

      return {
        productSelectType: this.productSelectType,
        products,
        productsById,
        itemByCartItemId,
        variantsById,
        pricesById,
        savedPaymentMethods,
        initialContactData,
      }
    }
  }
  const checkoutHelpersDataLoader = CheckoutHelpersDataLoader.getInstance()
  export default checkoutHelpersDataLoader
        