
import "./checkout-product-card-bump-v2"
import CheckoutProductSelectV2 from "./checkout-product-select-v2"
import Cart from "./checkout-helpers-cart-v1"
import { CF2Component } from 'javascript/lander/runtime'

export default class CheckoutProductSelectBumpV2 extends CF2Component {

constructor(el, runtimeSel) {
super(el, runtimeSel)
}

mount() {
    const productCardComponents = this.getComponents("CheckoutProductCardBump/V2");
    const productSelectElement = this.element

    CheckoutProductSelectV2.registerOneTimeCardElementListeners(productCardComponents, productSelectElement, (pcc) => {this.registerBumpVariantEventListeners(pcc)})
    productCardComponents.forEach((pcc) => {
      CheckoutProductSelectV2.registerEventListeners(pcc, (pcc) => {this.registerBumpVariantEventListeners(pcc)})
    })
  }

  registerBumpVariantEventListeners(pcc){
    const element = pcc.element
    const variantSelects = element.querySelectorAll('.elBumpVariantSelector')

    variantSelects.forEach((select, index) => {
      select.addEventListener('click', (evt) => {
        evt.stopImmediatePropagation()
        evt.stopPropagation()
        evt.preventDefault()
      })

      select.addEventListener('change', (evt) => {
        evt.stopImmediatePropagation()
        evt.stopPropagation()
        evt.preventDefault()

        const newValues = [...variantSelects].map((e) => e.value)

        const selectedVariantId = pcc.product.property_values_variant_mapping[newValues]
        const oldCartItemId = Cart.buildCartItemId(pcc.product.id, pcc.variant.id, pcc.selected_price.id)
        const newVariant = pcc.product.variants.find((v) => v.id == String(selectedVariantId))
        const newPrice = newVariant.prices[0]
        const newCartItemId = Cart.buildCartItemId(pcc.product.id, newVariant.id, newPrice.id)

        Cart.dispatchEvents(Cart.replace(oldCartItemId, newCartItemId))
        CheckoutProductSelectV2.updateCardByProductIdState(pcc.product.id, {variantId: newVariant.id, priceId: newPrice.id})
        CheckoutProductSelectV2.renderAndMount(pcc, (pcc) => {this.registerBumpVariantEventListeners(pcc)})
      })
    })
  }



}

window["CheckoutProductSelectBumpV2"] = CheckoutProductSelectBumpV2

