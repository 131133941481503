
          
          import Cart from "./checkout-helpers-cart-v1"
import ExistingOrders from "./checkout-helpers-existing-orders-v1"
import InputValidator from "./input-validator-v1"
import pathUtils from "./checkout-path-utils-v1"
import CheckoutAddressUtils from "./checkout-address-utils-v1"
          import { CF2ComponentSingleton } from 'javascript/lander/runtime'

  class CheckoutHelpersSubmitV1 extends CF2ComponentSingleton {
    #redirecTo = document.querySelector('[href="#submit-checkout-form"]').getAttribute('data-on-submit-go-to');
    #threedsListenerEnabled = false
    #formSubmitPayload = null
    #pageElementContexts = [
      { selector: '[data-page-element="ModalContainer/V1"]', context: 'Modal' },
      { selector: '[data-page-element="ContentNode"]', context: 'ContentNode' }
    ];

    submitFromButtonClick(submitButton) {
      this.#add3dsListener()
      const checkoutElement = submitButton.closest('.elCheckout') 
      if (
        this.checkSubmitShowErrors({ skipFields: new Set(['payment']) }) &&
        this.checkSubmitShowErrors({ onlyFields: new Set(['payment']) }) &&
        this.checkSubmit(checkoutElement)
      ) {
        Checkout.store.state.set(Checkout.StoreStates.SUBMITTING)
        Checkout.store.submitting.set({
          state: Checkout.SubmittingStates.START
        })
        const isUpgradeDowngrade = Checkout.store.checkout.mode.get() == Checkout.CheckoutStates.UPGRADE_DOWNGRADE
        const capturedPaymentMethodId = Checkout.store.payment.id.get()
        const canSkipSubmitRebilly = isUpgradeDowngrade || capturedPaymentMethodId
        if (canSkipSubmitRebilly) {
          const payload = this.buildPayloadFromStore()
          return this.submit(payload)
        } else {
          const paymentType = Checkout.store.payment.type.get()
          if (paymentType == 'payment-card') {
            // NOTE: payment-card always obtain a token calling Rebilly.createToken method
            return this.#submitRebilly().then((tokenData) => {
              Checkout.store.payment[paymentType].token.set(tokenData.id)
              const payload = this.buildPayloadFromStore()
              return this.submit(payload)
            })
          } else {
            const payload = this.buildPayloadFromStore()
            return this.submit(payload)
          }
        }
      }
    }

    checkSubmitShowErrors(options) {
      const { onlyFields, skipFields } = options ?? {}
      return Object.entries(Checkout.computed.errorsByName).map(([name, computed]) => {
        if (onlyFields && !onlyFields.has(name)) return true
        if (skipFields && skipFields.has(name)) return true

        Checkout.store.showAllErrors[name].set(true)
        return !Checkout.utils.hasErrors(computed.get())
      }).every((v) => !!v)
    }

    checkSubmit(checkoutElement) {
      if (Checkout.utils.canSubmit() && this.checkValidCustomFields(checkoutElement)) {
        return true
      } else {
        Checkout.store.incrScrollToFirstVisibleError.set(
          Checkout.store.incrScrollToFirstVisibleError.get() + 1
        )
        return false
      }
    }

    getCheckoutContext(checkoutElement) {
      let found
      this.#pageElementContexts.find(({ selector }) => { 
        found = checkoutElement.closest(selector)
        return found
       })
      return found
    }

    checkValidCustomFields(checkoutElement) {
      const contextElement = this.getCheckoutContext(checkoutElement);
      if (!contextElement) return true

      contextElement.querySelectorAll('.elFormItemWrapper:not(.elCheckout .elFormItemWrapper)').forEach(el => {
        el.classList.remove('elInputError', 'elInputWarning', 'elInputValid')
      })

      const formItems = Array.from(contextElement.querySelectorAll('.elFormItem.required1:not(.elCheckout .elFormItem.required1)'))
        .filter((input) => !!input.getAttribute('data-custom-type') || input.getAttribute('type') == 'checkbox')

      const results = []
      formItems.forEach((input) => {
        const result = InputValidator.validateInput(input)
        results.push(result)

        let thisInput = $(input)
        const parent = thisInput.parents('.elFormItemWrapper')
        thisInput =
          parent.length && parent.find('.inputHolder, .borderHolder, .elCheckbox').length
            ? parent.find('.inputHolder, .borderHolder, .elCheckbox')
            : thisInput

        if(result) {
          thisInput.css('border-color', '#4a8920')
          thisInput.css('border-width', '3px')
        } else {
          thisInput.css('border-color', '#B91517')
          thisInput.css('border-width', '3px')
        }
      })
      
      return results.every((r) => !!r)
    }

    buildPayloadFromStore(overrides) {
      overrides = overrides ?? {}
      const addressParams = this.#buildAddressParams() ?? {}
      const paymentDetails = this.#buildPaymentMethodDetails() ?? {}
      const purchaseCartDetails = this.#buildPurchaseCartDetails()
      // NOTE: Temp fix for FHL 2023
      const customFields = this.#buildCustomFields()
      const contact = overrides.contact ?? Checkout.store.contact.get()
      const couponCode = Checkout.store.featureFlags.isCouponEnabled.get() && Checkout.store.coupons.appliedCode.get()
      const couponData = couponCode ? {coupon_codes: [couponCode]} : {}

      const paymentType = Checkout.store.payment.type.get()
      const payload = {
        // NOTE: Temp fix for FHL 2023
        ...(customFields ? customFields : {}),
        contact: {
          ...contact,
          ...(addressParams.shipping ?? {}),
        },
        ...(addressParams.billing ?? {}),
        purchase: {
          ...couponData,
          ...purchaseCartDetails,
          ...paymentDetails,
        },
      }
      if (this.#shouldIncludeRegistration()) payload["registration"] = this.#buildRegistrationDetails()
      const selected_shipping_option = Checkout.store.shippingOption.get()
      if (selected_shipping_option) {
        payload.purchase.selected_shipping_option = selected_shipping_option
      }
      if (this.#redirecTo) {
        payload.redirect_to = this.#redirecTo
      }

      payload.purchase.process_new_order = true

      if (window.straightforward_onboarding_flow_redirect_url) {
        payload.straightforward_onboarding_flow_redirect_url = window.straightforward_onboarding_flow_redirect_url
      }
      return payload
    }

    #leadSourceGenerator() {
      const DEFAULT_MAX_CHARS_LENGTH = 512
      const leadQueryParamMapping = {
        utm_source: {
          name: 'source',
        },
        utm_medium: {
          name: 'medium',
        },
        utm_campaign: {
          name: 'campaign',
        },
        utm_term: {
          name: 'term',
        },
        utm_content: {
          name: 'content',
        },
        affiliate: {},
        subAffiliate: {},
        clickId: {},
        salesAgent: {},
      }
      const params = new URLSearchParams(window.location.search)
      return Array.from(params.keys()).reduce((acc, key) => {
        const mappedValue = leadQueryParamMapping[key]
        if (mappedValue) {
          const paramValue = params.get(key)
          const leadSourceName = mappedValue.name ?? key
          acc[leadSourceName] = paramValue.substring(0, DEFAULT_MAX_CHARS_LENGTH)
        }
        return acc
      }, {})
    }

    #submitRebilly() {
      const selectedPaymentMethod = Checkout.store.payment.type.get()
      const Rebilly = globalThis.Rebilly
      let extraData = {
        method: selectedPaymentMethod,
      }

      const leadSource = this.#leadSourceGenerator()
      if (Object.keys(leadSource).length) {
        extraData = { ...extraData, leadSource }
      }

      const form = document.querySelector('#cfAR')
      
      const rebillyDataMapping = {
        firstName: 'first_name',
        lastName: 'last_name',
        emails: 'email',
      }

      const rebillyFieldKeyMapping = {
        'paymentInstrument.cvv': 'cvv' 
      }

      Object.entries(rebillyDataMapping).forEach(([rebillyKey, dataKey]) => {
        const input = form.querySelector(`[data-rebilly="${rebillyKey}"]`)
        if (input) {
          input.value = Checkout.store.contact.get()[dataKey]
        }
      })
      return Rebilly.createToken(form, extraData).catch((e) => {
        console.error(e)
        if (e.invalidFields && e.invalidFields.length) {
          e.invalidFields.forEach((error) => {
            const key = rebillyFieldKeyMapping[error.field]
            if (key) {
              const event = {valid: false, error: {message: error.message}}
              Checkout.store.payment['payment-card'].events.setKey(key, event)
            }
          })
          Checkout.store.submitting.set({
            state: Checkout.SubmittingStates.ERROR,
            code: Checkout.ErrorTypes.REBILLY_ERROR,
          })
        } else {
          let message = e.message
          if (e.details.length) {
            const details = e.details.join(' - ')
            message =`${message} - ${details}`
          }
          Checkout.store.submitting.set({
            state: Checkout.SubmittingStates.ERROR,
            code: Checkout.ErrorTypes.REBILLY_ERROR,
            message: message,
          })
        }
        Checkout.store.incrScrollToFirstVisibleError.set(
          Checkout.store.incrScrollToFirstVisibleError.get() + 1
        )
      })
    }

    generateShippingOptionId(shippingOption) {
      return [
        shippingOption.description,
        shippingOption.amount_formatted
      ].join('$')
    }

    buildDigitalWalletTransactionData(summaryData, cart, shippingEnabled, shippingOptions) {
      const { line_items } = summaryData
      const lineItems = line_items.map(({ price, description }, index) => {
        let label
        if (line_items.length == cart.length) {
          const { variantId } = Cart.getCartIdDetails(cart[index].id)
          const variant = Checkout.variantsById[variantId]
          label = variant.name
        } else {
          label = description
        }
        return {
          label,
          amount: price
        }
      })

      if (summaryData.tax?.amount > 0) {
        lineItems.push({
          label: 'Taxes',
          amount: summaryData.tax.amount
        })
      }

      if (summaryData.shipping?.amount > 0) {
        lineItems.push({
          label: 'Shipping',
          amount: summaryData.shipping.amount
        })
      }

      return {
        amount: summaryData.total.amount,
        lineItems,
        ...(shippingEnabled ? { 
          status: shippingOptions.length ? 'success' : 'fail',
          shippingOptions: shippingOptions.map((shippingOption) => ({
            id: this.generateShippingOptionId(shippingOption),
            label: shippingOption.description,
            description: '',
            amount: Number(shippingOption.amount.amount),
          })),
        } : { }),
      }
    }

    #buildPurchaseCartDetails() {
      let orderId
      const lineItems = Checkout.store.cart.get().map(({ id, quantity }) => {
        let data
        if (Cart.isCartItemIdUpdatable(id)) {
          const { orderId: tmpOrderId, variantId, priceId, lineItemId } = Cart.getCartIdDetails(id)
          orderId = tmpOrderId
          data = {
            line_item_id: lineItemId,
            id: variantId,
            price_id: priceId,
          }
        } else {
          const { variantId, priceId } = Cart.getCartIdDetails(id)
          data = {
            id: variantId,
            price_id: priceId
          }
        }
        return {
          quantity,
          ...data
        }
      })
      return { order_id: orderId, product_variants: lineItems }
    }

    #buildPaymentMethodDetails() {
      const mode = Checkout.store.checkout.mode.get()
      if (mode == Checkout.CheckoutStates.UPGRADE_DOWNGRADE) return

      const paymentType = Checkout.store.payment.type.get()
      const rebilly_token = Checkout.store.payment[paymentType].token.get()
      const payment_method_id = Checkout.store.payment.id.get()
      const payment_method_type = Checkout.store.payment.type.get()

      if (payment_method_id) return { payment_method_id, rebilly_token: null }

      return {
        payment_method_id: null,
        payment_method_type,
        rebilly_token,
      }
    }

    #buildCustomFields() {
      const fields = {}
      document.querySelectorAll("[type='custom_type'], select[data-custom-type], [type='custom_type'] [type='checkbox']").forEach((input) => {
        fields[input.name] = 
          input.type === 'checkbox'
            ? input.checked
            : input.value
      })
      return fields
    }

    #buildRegistrationDetails() {
      return {
        calendar_event_id: document.getElementsByName("registration[calendar_event_id]")[0].value,
        occurs_at: document.getElementsByName("registration[occurs_at]")[0].value
      }
    }

    #shouldIncludeRegistration() {
      return document.getElementsByName("registration[calendar_event_id]")?.length > 0
    }

    submit(payload) {
      if (localStorage.getItem('cf2:devtools:enabled')) {
        console.log('submitted data', payload)
      }

      CFDispatchEvent(CFEvents.FORM_SUBMITTED, payload)
      let timer = null
      this.#submitOrderAsync(
        payload,
        3,
        () => {
          clearInterval(timer)
          Checkout.store.submitting.set({
            state: Checkout.SubmittingStates.START
          })
        },
        (sleepTime) => {
          let remainingSeconds = sleepTime / 1000
          clearInterval(timer)
          timer = setInterval(() => {
            remainingSeconds -= 1
            Checkout.store.submitting.set({
              state: Checkout.SubmittingStates.WAITING_ON_QUEUE,
              remainingSeconds
            })
          }, 1000)
        }
      )
      .then((response) => {
        if (response.ok) {
          this.#formSubmitPayload = payload
          const rawFlashes = response.headers.get('X-CF2-FLASHES')
          const flashes = JSON.parse(rawFlashes ?? '{}')
          if (flashes.error) {
            Checkout.store.submitting.set({
              state: Checkout.SubmittingStates.ERROR,
              code: Checkout.ErrorTypes.SERVER_ERROR_WITH_MESSAGE,
              message: flashes.error
            })
          } else {
            CFDispatchEvent(CFEvents.FORM_SUBMITTED_OK, payload)
            if (response.headers.get('X-CF2-APPROVAL-URL')) {
              Checkout.store.threeds.set({
                show: true,
                approvalUrl: response.headers.get('X-CF2-APPROVAL-URL'),
              })
            } else {
              Checkout.store.submitting.set({
                state: Checkout.SubmittingStates.DONE,
              })
              Checkout.store.state.set(Checkout.StoreStates.SUBMITTED)
              CFDispatchEvent(CFEvents.FORM_SUBMITTED_FINALIZED, payload)
              this.#handleFormSubmitRedirect(response, response.headers.get('Location'))
            }
          }
        } else if (response.status >= 300 && response.status < 400) {
          // NOTE: This code is never executed...
          Checkout.store.submitting.set({
            state: Checkout.SubmittingStates.DONE,
          })
          Checkout.store.state.set(Checkout.StoreStates.SUBMITTED)
          CFDispatchEvent(CFEvents.FORM_SUBMITTED_FINALIZED, payload)
          this.#handleFormSubmitRedirect(response, response.headers.get('Location') ?? window.location.href)
        } else if (response.status === 429) {
          clearInterval(timer)
          Checkout.store.submitting.set({
            state: Checkout.SubmittingStates.ERROR,
            code: Checkout.ErrorTypes.EXCEEDED_MAX_RETRIES
          })
        } else {
          Checkout.store.submitting.set({
            state: Checkout.SubmittingStates.ERROR,
            code: Checkout.ErrorTypes.UNHANDLED_SERVER_RESPONSE
          })
        }
      })
      .catch((err) => {
        console.error(err)
        clearInterval(timer)
        Checkout.store.submitting.set({
          state: Checkout.SubmittingStates.ERROR,
          code: Checkout.ErrorTypes.SERVER_ERROR
        })
      })
    }

    #buildAddressParams() {
      const mode = Checkout.store.checkout.mode.get()
      const billing = Checkout.store.billing.get()
      const shipping = Checkout.store.shipping.get()

      const cart = Checkout.store.cart.get()

      if (mode == Checkout.CheckoutStates.UPGRADE_DOWNGRADE) {
        return
      }

      let billing_address_attributes, shipping_addresses_attributes
      if (CheckoutAddressUtils.isSavedAddress(billing)) {
        billing_address_attributes = {
          id: billing.id,
        }
      } else {
        billing_address_attributes = {
          address_one: billing.address,
          address_two: billing.address_2,
          city: billing.city,
          region_name: billing.state,
          country_id: billing.country,
          postal_code: billing.zip,
        }
      }
      
      if (CheckoutAddressUtils.isSavedAddress(shipping)) {
        shipping_addresses_attributes = [
          {
            physical_address_attributes: {
              id: shipping.id,
            }
          }
        ]
      } else {
        shipping_addresses_attributes = [
          {
            physical_address_attributes: {
              address_one: shipping.address,
              address_two: shipping.address_2,
              city: shipping.city,
              region_name: shipping.state,
              country_id: shipping.country,
              postal_code: shipping.zip,
            }
          }
        ]
      }

      if (Checkout.utils.hasPhysicalProducts()) {
        const billingSameAsShipping = Checkout.store.billingSameAsShipping.get()
        if (Checkout.utils.skipBillingAddress(Checkout.store)) {
          return { shipping: { shipping_addresses_attributes } }
        }
        if (mode == Checkout.CheckoutStates.OTO) {
          return {
            shipping: { shipping_addresses_attributes },
            billing: { billing_address_attributes, billing_same_as_shipping: false } 
          }
        }
        if (billingSameAsShipping)  {
          return {
            shipping: { shipping_addresses_attributes },
            billing: { billing_same_as_shipping: true, }
          }
        } else {
          return {
            shipping: { shipping_addresses_attributes },
            billing: { billing_address_attributes, billing_same_as_shipping: false } 
          }
        }
      } else {
        if (Checkout.utils.skipBillingAddress(Checkout.store)) return
        return { billing: { billing_address_attributes } }
      }
    }

    async #submitOrderAsync(
      data,
      maxRetries = 3,
      onBeforeSubmit,
      onRetryAfter
    ) {
      let response
      for (let i = 0; i < maxRetries; i++) {
        onBeforeSubmit()
        response = await fetch(pathUtils.renderedHref(), {
          credentials: 'same-origin',
          method: 'post',
          body: JSON.stringify(data),
          headers: {
            'Content-Type': 'application/json',
            'X-CF2-POST-TYPE': 'submit',
          },
        })
        .then((res) => {
          if (res.status >= 500) {
            console.error(res)
            throw Error('500 error')
          }
          return res
        })

        if (response.status === 429) {
          const sleepTime = parseInt(response.headers.get('Retry-After'))
          onRetryAfter(sleepTime)
          console.log(`Waiting on queue, retrying after ${sleepTime}`)
          await sleepMs(sleepTime)
        } else {
          break
        }
      }
      return response
    }

    async #handleFormSubmitRedirect(response, urlToRedirect) {
      const userRedirectSignInUrl = response.headers.get('X-CF2-USER-REDIRECT-URL')
      const signInToken = response.headers.get('X-CF2-USER-SIGN-IN-TOKEN')
      if (userRedirectSignInUrl && signInToken && window.straightforward_onboarding_flow_enabled) {
        const shouldRedirectImmediately = window.straightforward_onboarding_flow_redirect_url
        if (shouldRedirectImmediately) {
          window.location.href = userRedirectSignInUrl
        } else {
          const MAX_WAIT_TIME = 120
          const fetchedActiveWorkspace = await new Promise(async (resolve) => {
            const startTime = new Date();
            while(true) {
              const currentTime = new Date()
              const elapsedTime = (currentTime - startTime) / 1000
              if (elapsedTime > MAX_WAIT_TIME) {
                resolve(false)
                break
              }
              const meRequest = `https://accounts.${window.cfRootDomain}/me.json?token=${encodeURIComponent(signInToken)}`
              const response = await fetch(meRequest)
              if (response.ok) {
                const responseJson = await response.json()
                const team = responseJson.teams[0]
                const workspaceCreationStatus = team?.workspaces[0]?.creation_status
                if (team?.subscription_status == 'active' && workspaceCreationStatus == 'install_finalized') {
                  window.location.href = userRedirectSignInUrl
                  resolve(true)
                  break
                }
              }
              await new Promise(resolve => setTimeout(resolve, 4000));
            }
          })
          if (!fetchedActiveWorkspace) window.location.href = urlToRedirect
        }
      } else {
        window.location.href = urlToRedirect
      }
    }

    #add3dsListener() {
      if (this.#threedsListenerEnabled) return
      this.#threedsListenerEnabled = true

      window.addEventListener("message", (event) => {
        if (event.data.sender == "CfOrderStatus") {
          const orderDetails = event.data.details          
          
          if (this.#redirecTo) {
            orderDetails.redirect_to = this.#redirecTo
          }

          if (window.straightforward_onboarding_flow_redirect_url) {
            orderDetails.straightforward_onboarding_flow_redirect_url = window.straightforward_onboarding_flow_redirect_url
          }

          fetch(pathUtils.renderedHref(), {
            credentials: 'same-origin',
            method: 'post',
            body: JSON.stringify(orderDetails),
            headers: {
              'Content-Type': 'application/json',
              'X-CF2-POST-TYPE': 'submit',
            },
          }).then((response) => {
            if (orderDetails['orderResult'] == 'declined') {
              Checkout.store.submitting.set({
                state: Checkout.SubmittingStates.ERROR,
                code: Checkout.ErrorTypes.THREEDS_DECLINED_ERROR
              })
            } else {
              if (response.ok) {
                Checkout.store.state.set(Checkout.StoreStates.SUBMITTED)
                CFDispatchEvent(CFEvents.FORM_SUBMITTED_FINALIZED, this.#formSubmitPayload)
                this.#handleFormSubmitRedirect(response, response.headers.get('Location'))
              } else {
                response.json().then((r) => {
                  Checkout.store.submitting.set({
                    state: Checkout.SubmittingStates.ERROR,
                    code: Checkout.ErrorTypes.THREEDS_DECLINED_CUSTOM_ERROR,
                    message: r.error
                  })
                })
              }
            }
            Checkout.store.threeds.set({
              show: false,
              approvalUrl: null,
            })
          })
        }
      })
    }
  }
  const checkoutControllerSubmitV1 = CheckoutHelpersSubmitV1.getInstance()
  export default checkoutControllerSubmitV1
        