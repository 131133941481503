
import "./radio-v1"
import "./checkbox-v1"
import { CF2Component } from 'javascript/lander/runtime'

export default class CheckoutProductCardV2 extends CF2Component {

constructor(el, runtimeSel) {
super(el, runtimeSel)
}

mount() {
    this.can_purchase_again = !(new URLSearchParams(window.location.search).get('manage_order_id'))
    this.variantValues = Object.entries(this.product.property_values_variant_mapping ?? {}).reduce((acc, entries) => {
      const values = entries[0].split(',').map((value) => parseInt(value))
      acc[entries[1]] = values
      return acc
    }, {}) ?? {}

    this.valuesPositions = this.product.sorted_property_values?.reduce((acc, property) => {
      property.value_ids.forEach((value, index) => {
        acc[value] = index
      })
      return acc
    }, {}) ?? {}

  }


    remove() {
      this.element.innerHTML = '';
    }
    render(initializeChildrenInstances = false) {
      const is_server = true;
      const layout = this.layout ?? null ;
const featured = this.featured ?? null ;
const highlight_text = this.highlight_text ?? null ;
const selectType = this.selectType ?? null ;
const showImage = this.showImage ?? null ;
const showDescription = this.showDescription ?? null ;
const showCompareAtPrice = this.showCompareAtPrice ?? null ;
const isChecked = this.isChecked ?? null ;
const product = this.product ?? null ;
const variant = this.variant ?? null ;
const selected_price = this.selected_price ?? null ;
const quantity = this.quantity ?? null ;
const can_purchase_again = this.can_purchase_again ?? true ;
const renderType = this.renderType ?? null ;
const selectedOrderId = this.selectedOrderId ?? null ;
const selectedUpdatableCartItemId = this.selectedUpdatableCartItemId ?? null ;

      
      let html = '';
      {
        const is_not_server = false;
        let priceDisplayType = "multiple";
        let newOrder = selectedOrderId == null || selectedOrderId == "new";
        let showProductVariantSelection = true;
        let currentRenderType = renderType;
        const showUpgradeDowngrade = currentRenderType == "upgradeDowngrade";
        const showReactivate = currentRenderType == "reactivate";
        const showManageOrders = showUpgradeDowngrade || showReactivate;
        if (showManageOrders) {
          showProductVariantSelection = false;
          if (showUpgradeDowngrade) {
            priceDisplayType = "hide";
            if (newOrder && variant.prices.length == 1) {
              priceDisplayType = "single";
            }
          }
          if (showReactivate && variant.prices.length > 1) {
            priceDisplayType = "single";
          }
        }
        const customVariants = product.variants.filter((value) => value["default_variant"] == false);
        if (layout == "modern") {
          html += `<div class="elProductLayoutBackground"></div>`
          html += `<div class="elProductModernItem"> `
          if (selectType == "quantity") {
            html += `<div class="elProductInputWrapper elProductCardQuantityContainer"><div class="elProductInputControls"><button class="elProductCardInput">-</button><input class="elProductCardInput elProductCardShortInput" name="product" value="${quantity ?? 0}" min="0" type="number"/><button class="elProductCardInput">+</button></div></div>`
          } else if (selectType == "quantity-forced") {
            html += `<div class="elProductInputWrapper elSelectWrapper elForcedQuantityWrapper"><select name="product" class="elProductCardInput elProductInputControls">`
            const step = product.step ?? 2;
            const min = product.min ?? 0;
            const max = product.max ?? 5;
            const item_count_step_1 = max- min;
            const item_count_step_2 = item_count_step_1/ step;
            const item_count_step_3 = item_count_step_2| 0;
            const range = Array.apply(0, Array(item_count_step_3)).map((element, index) => index + 0);;
            const c0 = range
            const fl1 = new CF2ForloopDrop(c0.length)
            for (const i of c0) {
              const forloop = fl1
              const value = i* step+ min;
              html += `<option value="${value}">${value}</option>`
              forloop.next();
            }
            html += `</select><div class="elSelectArrow elForcedQuantitySelectArrow"><i class="fas fa-chevron-down"></i></div></div>`
          }
          const isClassic = layout == "classic";
          const isQuantityType = selectType == "quantity";
          html += `<div class="elProductCardSelectInput`
          if (isClassic == false || isQuantityType == true) {
            html += ` hide`
          }
          html += `">`
          if (selectType == "single") {
            if (isChecked) {
              html += `<div data-page-element="Radio/V1" class="elRadioWrapper id-Radio/V1" data-liquid-replace="item" data-checked="true" data-trigger-click-on-wrapper="false"><label class="elRadioLabel"><input type="radio" value="" name="product" checked class="elRadioInput"/><div class="elRadio"><div class="elRadioIcon"></div></div><span class="elRadioText"></span></label></div>`
            } else {
              html += `<div data-page-element="Radio/V1" class="elRadioWrapper id-Radio/V1" data-liquid-replace="item" data-checked="" data-trigger-click-on-wrapper="false"><label class="elRadioLabel"><input type="radio" value="" name="product"  class="elRadioInput"/><div class="elRadio"><div class="elRadioIcon"></div></div><span class="elRadioText"></span></label></div>`
            }
          } else if (selectType == "multiple" || selectType == "quantity") {
            if (isChecked) {
              html += `<div data-page-element="Checkbox/V1" type="" class="elCheckboxWrapper elFormItemWrapper de-input-block id-Checkbox/V1" data-liquid-replace="item" data-prevent-click-on-label="false" data-trigger-click-on-wrapper="false" data-checked="true"><label class="elCheckboxLabel"><input type="checkbox" value="${product.id}" name="product" checked data-prevent-garlic="false" class="elCheckboxInput inputHolder"/><div class="elCheckbox"><div class="elCheckboxIcon"><div class="elCheckboxIconSquare hide"></div><i class="elCheckboxIconCheck fas fa-check"></i></div></div><span class="elCheckboxText"></span></label><div data-input-status-type="true" data-error-container="true"></div></div>`
            } else {
              html += `<div data-page-element="Checkbox/V1" type="" class="elCheckboxWrapper elFormItemWrapper de-input-block id-Checkbox/V1" data-liquid-replace="item" data-prevent-click-on-label="false" data-trigger-click-on-wrapper="false" data-checked=""><label class="elCheckboxLabel"><input type="checkbox" value="${product.id}" name="product"  data-prevent-garlic="false" class="elCheckboxInput inputHolder"/><div class="elCheckbox"><div class="elCheckboxIcon"><div class="elCheckboxIconSquare hide"></div><i class="elCheckboxIconCheck fas fa-check"></i></div></div><span class="elCheckboxText"></span></label><div data-input-status-type="true" data-error-container="true"></div></div>`
            }
          }
          html += `</div>`
          if (showImage) {
            const imageUrl = variant.image ?? product.image;
            if (imageUrl && imageUrl != "") {
              html += `<div class="elProductCardImage"><div data-page-element="Image/V2" class="elImageWrapper de-image-block id-Image/V2`
              if (true && !imageUrl && !null && false == false) {
                html += ` forceHide`
              }
              html += `" data-liquid-replace="item">`
              if (imageUrl || !!null) {
                html += `<img class="elImage" src="${imageUrl ?? null}"/>`
              } else if (false) {
                html += `<div class="image-placeholder" title="This element will render with content once configured."><span class="image-placeholder-header">[Replaced by Content]</span></div>`
              }
              html += `</div></div>`
            }
          }
          html += `<div class="elProductCardNameContainer">`
          if (featured && highlight_text) {
            html += `<div class="elProductCardHighlight">${highlight_text}</div>`
          }
          if (customVariants.length > 1) {
            html += `<span class="elProductCardInfoName">${product.default_variant.name}</span>`
          } else {
            html += `<span class="elProductCardInfoName">${variant.name}</span>`
          }
          if (showDescription) {
            html += `<span class="elProductCardInfoDescription">${(product.description_override ?? variant.description ?? product.default_variant.description ?? "").replaceAll(`
`,"<br>")}</span>`
          }
          html += `</div><div class="elProductCardPriceContainer">`
          if (priceDisplayType == "multiple") {
            if (variant.prices.length == 1) {
              html += `<div class="elProductCardInfoPrice">`
              if (showCompareAtPrice) {
                const compare_at_amount = selected_price.compare_at_amount;
                if (compare_at_amount) {
                  html += `<span class="elProductCardAnchorPrice">${compare_at_amount}</span>`
                }
              }
              html += `<span class="elProductCardFinalPrice">${selected_price.name}</span></div>`
            } else {
              html += `<div class="elSelectWrapper"><select name="variant_price">`
              const c2 = variant.prices
              const fl3 = new CF2ForloopDrop(c2.length)
              for (const price of c2) {
                const forloop = fl3
                html += `<option `
                if (selected_price.id == price.id) {
                  html += `selected`
                }
                html += ` value="${price.id}">${price.name}</option>`
                forloop.next();
              }
              html += `</select><div class="elSelectArrow"><i class="fas fa-chevron-down"></i></div></div>`
            }
          } else if (priceDisplayType == "single") {
            html += `<div class="elProductCardInfoPrice">`
            if (showCompareAtPrice) {
              const compare_at_amount = selected_price.compare_at_amount;
              if (compare_at_amount) {
                html += `<span class="elProductCardAnchorPrice">${compare_at_amount}</span>`
              }
            }
            html += `<span class="elProductCardFinalPrice">${selected_price.name}</span></div>`
          }
          html += `</div>`
          if (showProductVariantSelection) {
            if (customVariants.length > 1) {
              html += `<div class="elProductSelectSelectorsWrapper">`
              const c4 = product.sorted_property_values
              const fl5 = new CF2ForloopDrop(c4.length)
              for (const property_values of c4) {
                const forloop = fl5
                const property_id = property_values.property_id;
                const select_index = forloop.index0;
                html += `    `
                html += `<div class="elSelectWrapper"><select class="elVariantSelector" name="property_${property_id}">`
                const c6 = property_values.value_ids
                const fl7 = new CF2ForloopDrop(c6.length)
                for (const value_id of c6) {
                  const forloop = fl7
                  html += `<option `
                  if (variant.property_value_ids[select_index] == value_id) {
                    html += `selected`
                  }
                  html += ` value="${value_id}">${product.all_properties_values[value_id]}</option>`
                  forloop.next();
                }
                html += `</select><div class="elSelectArrow"><i class="fas fa-chevron-down"></i></div></div>`
                forloop.next();
              }
              html += `</div>`
            }
          }
          if (showManageOrders) {
            const sortedOrderIds = Checkout.orderDetailsByProductIdGet[product.id].sortedOrderIds;
            let active_order = false;
            let canceled_order = false;
            const amount_of_orders = sortedOrderIds.length;
            const c8 = sortedOrderIds
            const fl9 = new CF2ForloopDrop(c8.length)
            for (const sortedOrderId of c8) {
              const forloop = fl9
              const order = Checkout.orderDetailsByProductIdGet[product.id].orders[sortedOrderId];
              if (order.reactivation == true) {
                canceled_order = true;
              } else {
                active_order = true;
              }
              forloop.next();
            }
            if (amount_of_orders && amount_of_orders > 0) {
              html += `<div class="elProductCardSubscriptionsBanner"><i class="fas fa-sync-alt"></i>`
              if (active_order && canceled_order) {
                if (amount_of_orders > 1) {
                  html += `<span>${amount_of_orders} Existing Subscriptions</span>`
                } else {
                  html += `<span>${amount_of_orders} Existing Subscription</span>`
                }
              } else if (canceled_order) {
                html += ` `
                if (amount_of_orders > 1) {
                  html += `<span>${amount_of_orders} Canceled Subscriptions</span>`
                } else {
                  html += `<span>${amount_of_orders} Canceled Subscription</span>`
                }
              } else {
                if (amount_of_orders > 1) {
                  html += `<span>${amount_of_orders} Active Subscriptions</span>`
                } else {
                  html += `<span>${amount_of_orders} Active Subscription</span>`
                }
              }
              html += `</div>`
              html += `<div class="elProductCardManageOrders`
              if (!isChecked) {
                html += ` elHide`
              }
              html += `"><div class="elProductCardOrdersWrapper"><div class="elProductCardTitle"><span>Manage Subscriptions</span><div></div></div><div class="elProductCardOrders">`
              const c10 = sortedOrderIds
              const fl11 = new CF2ForloopDrop(c10.length)
              for (const sortedOrderId of c10) {
                const forloop = fl11
                const order = Checkout.orderDetailsByProductIdGet[product.id].orders[sortedOrderId];
                html += `<div class="elProductCardOrder`
                if (selectedOrderId == order.id) {
                  html += ` elProductCardOrderSelected`
                }
                html += `" data-order-id="${sortedOrderId}"><div class="elProductCardOrderDetails"><div class="elProductCardOrderDetailsNumber"><i class="fas fa-receipt"></i><span>Order ${order.number}</span></div>`
                if (order.reactivation) {
                  html += `<span class="elProductCardOrderDetailsReactivate">Reactivate</span>`
                } else {
                  html += `<span class="elProductCardOrderDetailsManage">Manage</span>`
                }
                html += `</div>`
                if (selectedOrderId == order.id) {
                  html += `<div class="elProductCardOrderActions"><a data-page-element="LinkNode" class="elTypographyLink elProductCardShowOrderDetailsLink id-LinkNode" href="#show-selected-order-details" target="_self" data-selected="{% is_current_path '#show-selected-order-details' %}" data-liquid-replace="item"><span>View Order Details</span></a>`
                  const updatableCartItemIds = Checkout.orderDetailsByProductIdGet[product.id].orders[order.id].updatableCartItemIds;
                  const selectedUpdatableCartItem = Checkout.itemByCartItemIdGet[selectedUpdatableCartItemId];
                  if (updatableCartItemIds && updatableCartItemIds.length > 0) {
                    html += `<div class="elCustomSelect"><div class="elCustomSelectPreview"><span class="elCustomSelectPreviewText"><span class="elCustomSelectPreviewDirection" data-direction="${selectedUpdatableCartItem.orderDetails.extraData.direction}">${selectedUpdatableCartItem.orderDetails.extraData.direction} To </span> <span class="elCustomSelectPreviewInfo">${selectedUpdatableCartItem.variant.name} - ${selectedUpdatableCartItem.price.name}</span></span><div class="elSelectArrow"><i class="fas fa-chevron-down"></i></div></div><select name="upgradeDowngrade">`
                    const c12 = updatableCartItemIds
                    const fl13 = new CF2ForloopDrop(c12.length)
                    for (const updatableCartItemId of c12) {
                      const forloop = fl13
                      const updatableItem = Checkout.itemByCartItemIdGet[updatableCartItemId];
                      html += `<option `
                      if (updatableCartItemId == selectedUpdatableCartItemId) {
                        html += `selected`
                      }
                      html += ` value="${updatableCartItemId}">${updatableItem.orderDetails.extraData.direction} To ${updatableItem.variant.name} - ${updatableItem.price.name}</option>`
                      forloop.next();
                    }
                    html += `</select></div>`
                  } else {
                    if (customVariants.length > 1) {
                      html += `<div class="elCustomSelect"><div class="elCustomSelectPreview"><span class="elCustomSelectPreviewText"><span class="elCustomSelectPreviewDirection" data-direction="Reactivate">Reactivate</span> <span class="elCustomSelectPreviewInfo">${variant.name}</span></span><div class="elSelectArrow"><i class="fas fa-chevron-down"></i></div></div><select class="elVariantSelector">`
                      const c14 = product.variants
                      const fl15 = new CF2ForloopDrop(c14.length)
                      for (const product_variant of c14) {
                        const forloop = fl15
                        let combined_variant_name = "";
                        const c16 = product.sorted_property_values
                        const fl17 = new CF2ForloopDrop(c16.length)
                        for (const property_values of c16) {
                          const forloop = fl17
                          const index0 = forloop.index0;
                          const property_id = property_values.property_id;
                          const property_value_id = product_variant.property_value_ids[index0];
                          if (combined_variant_name != "") {
                            combined_variant_name = combined_variant_name.toString().concat(", ");
                          }
                          combined_variant_name = combined_variant_name.toString().concat(product.all_properties[property_id]);
                          combined_variant_name = combined_variant_name.toString().concat(": ");
                          combined_variant_name = combined_variant_name.toString().concat(product.all_properties_values[property_value_id]);
                          forloop.next();
                        }
                        html += `<option value="${product_variant.property_value_ids}" `
                        if (product_variant.id == variant.id) {
                          html += `selected`
                        }
                        html += `>${combined_variant_name}</option>`
                        forloop.next();
                      }
                      html += `</select></div>`
                    }
                    if (priceDisplayType == "single" && variant.prices.length > 1) {
                      html += `<div class="elProductSelectSelectorsWrapper"><div class="elSelectWrapper"><select name="variant_price">`
                      const c18 = variant.prices
                      const fl19 = new CF2ForloopDrop(c18.length)
                      for (const price of c18) {
                        const forloop = fl19
                        html += `<option `
                        if (selected_price.id == price.id) {
                          html += `selected`
                        }
                        html += ` value="${price.id}">${price.name}</option>`
                        forloop.next();
                      }
                      html += `</select><div class="elSelectArrow"><i class="fas fa-chevron-down"></i></div></div></div>`
                    }
                  }
                  html += `</div>`
                }
                html += `</div>`
                forloop.next();
              }
              html += `</div></div>`
              if (can_purchase_again) {
                html += `<div class="elProductCardNewOrderWrapper"><div class="elProductCardTitle"><span>Or Purchase Again</span><div></div></div><div class="elProductCardNewOrder`
                if (newOrder == true) {
                  html += ` elProductCardOrderSelected`
                }
                html += `"><div class="elProductCardNewOrderTitle"><i class="fas fa-plus-circle"></i><span>New Order</span></div>`
                if (newOrder == true) {
                  html += `<div class="elProductCardOrderActions">`
                  if (customVariants.length > 1) {
                    html += `<div class="elProductSelectSelectorsWrapper">`
                    const c20 = product.sorted_property_values
                    const fl21 = new CF2ForloopDrop(c20.length)
                    for (const property_values of c20) {
                      const forloop = fl21
                      const property_id = property_values.property_id;
                      const select_index = forloop.index0;
                      html += `    `
                      html += `<div class="elSelectWrapper"><select class="elVariantSelector" name="property_${property_id}">`
                      const c22 = property_values.value_ids
                      const fl23 = new CF2ForloopDrop(c22.length)
                      for (const value_id of c22) {
                        const forloop = fl23
                        html += `<option `
                        if (variant.property_value_ids[select_index] == value_id) {
                          html += `selected`
                        }
                        html += ` value="${value_id}">${product.all_properties_values[value_id]}</option>`
                        forloop.next();
                      }
                      html += `</select><div class="elSelectArrow"><i class="fas fa-chevron-down"></i></div></div>`
                      forloop.next();
                    }
                    html += `</div>`
                  }
                  if (priceDisplayType == "single" && variant.prices.length > 1) {
                    html += `<div class="elProductSelectSelectorsWrapper"><div class="elSelectWrapper"><select name="variant_price">`
                    const c24 = variant.prices
                    const fl25 = new CF2ForloopDrop(c24.length)
                    for (const price of c24) {
                      const forloop = fl25
                      html += `<option `
                      if (selected_price.id == price.id) {
                        html += `selected`
                      }
                      html += ` value="${price.id}">${price.name}</option>`
                      forloop.next();
                    }
                    html += `</select><div class="elSelectArrow"><i class="fas fa-chevron-down"></i></div></div></div>`
                  }
                  html += `</div>`
                }
                html += `</div></div>`
              }
              html += `</div>`
            }
          }
          html += `</div>`
        } else if (layout == "grid") {
          html += `<div class="elProductLayoutBackground"></div>`
          html += `<div class="elProductGridItem">`
          if (selectType == "quantity") {
            html += `<div class="elProductInputWrapper elProductCardQuantityContainer"><div class="elProductInputControls"><button class="elProductCardInput">-</button><input class="elProductCardInput elProductCardShortInput" name="product" value="${quantity ?? 0}" min="0" type="number"/><button class="elProductCardInput">+</button></div></div>`
          } else if (selectType == "quantity-forced") {
            html += `<div class="elProductInputWrapper elSelectWrapper elForcedQuantityWrapper"><select name="product" class="elProductCardInput elProductInputControls">`
            const step = product.step ?? 2;
            const min = product.min ?? 0;
            const max = product.max ?? 5;
            const item_count_step_1 = max- min;
            const item_count_step_2 = item_count_step_1/ step;
            const item_count_step_3 = item_count_step_2| 0;
            const range = Array.apply(0, Array(item_count_step_3)).map((element, index) => index + 0);;
            const c26 = range
            const fl27 = new CF2ForloopDrop(c26.length)
            for (const i of c26) {
              const forloop = fl27
              const value = i* step+ min;
              html += `<option value="${value}">${value}</option>`
              forloop.next();
            }
            html += `</select><div class="elSelectArrow elForcedQuantitySelectArrow"><i class="fas fa-chevron-down"></i></div></div>`
          }
          const isClassic = layout == "classic";
          const isQuantityType = selectType == "quantity";
          html += `<div class="elProductCardSelectInput`
          if (isClassic == false || isQuantityType == true) {
            html += ` hide`
          }
          html += `">`
          if (selectType == "single") {
            if (isChecked) {
              html += `<div data-page-element="Radio/V1" class="elRadioWrapper id-Radio/V1" data-liquid-replace="item" data-checked="true" data-trigger-click-on-wrapper="false"><label class="elRadioLabel"><input type="radio" value="" name="product" checked class="elRadioInput"/><div class="elRadio"><div class="elRadioIcon"></div></div><span class="elRadioText"></span></label></div>`
            } else {
              html += `<div data-page-element="Radio/V1" class="elRadioWrapper id-Radio/V1" data-liquid-replace="item" data-checked="" data-trigger-click-on-wrapper="false"><label class="elRadioLabel"><input type="radio" value="" name="product"  class="elRadioInput"/><div class="elRadio"><div class="elRadioIcon"></div></div><span class="elRadioText"></span></label></div>`
            }
          } else if (selectType == "multiple" || selectType == "quantity") {
            if (isChecked) {
              html += `<div data-page-element="Checkbox/V1" type="" class="elCheckboxWrapper elFormItemWrapper de-input-block id-Checkbox/V1" data-liquid-replace="item" data-prevent-click-on-label="false" data-trigger-click-on-wrapper="false" data-checked="true"><label class="elCheckboxLabel"><input type="checkbox" value="${product.id}" name="product" checked data-prevent-garlic="false" class="elCheckboxInput inputHolder"/><div class="elCheckbox"><div class="elCheckboxIcon"><div class="elCheckboxIconSquare hide"></div><i class="elCheckboxIconCheck fas fa-check"></i></div></div><span class="elCheckboxText"></span></label><div data-input-status-type="true" data-error-container="true"></div></div>`
            } else {
              html += `<div data-page-element="Checkbox/V1" type="" class="elCheckboxWrapper elFormItemWrapper de-input-block id-Checkbox/V1" data-liquid-replace="item" data-prevent-click-on-label="false" data-trigger-click-on-wrapper="false" data-checked=""><label class="elCheckboxLabel"><input type="checkbox" value="${product.id}" name="product"  data-prevent-garlic="false" class="elCheckboxInput inputHolder"/><div class="elCheckbox"><div class="elCheckboxIcon"><div class="elCheckboxIconSquare hide"></div><i class="elCheckboxIconCheck fas fa-check"></i></div></div><span class="elCheckboxText"></span></label><div data-input-status-type="true" data-error-container="true"></div></div>`
            }
          }
          html += `</div>`
          if (showImage) {
            const imageUrl = variant.image ?? product.image;
            if (imageUrl && imageUrl != "") {
              html += `<div class="elProductCardImage"><div data-page-element="Image/V2" class="elImageWrapper de-image-block id-Image/V2`
              if (true && !imageUrl && !null && false == false) {
                html += ` forceHide`
              }
              html += `" data-liquid-replace="item">`
              if (imageUrl || !!null) {
                html += `<img class="elImage" src="${imageUrl ?? null}"/>`
              } else if (false) {
                html += `<div class="image-placeholder" title="This element will render with content once configured."><span class="image-placeholder-header">[Replaced by Content]</span></div>`
              }
              html += `</div></div>`
            }
          }
          html += `<div class="elProductCardNamePriceWrapper"><div class="elProductCardNameContainer">`
          if (featured && highlight_text) {
            html += `<div class="elProductCardHighlight">${highlight_text}</div>`
          }
          if (customVariants.length > 1) {
            html += `<span class="elProductCardInfoName">${product.default_variant.name}</span>`
          } else {
            html += `<span class="elProductCardInfoName">${variant.name}</span>`
          }
          if (showDescription) {
            html += `<span class="elProductCardInfoDescription">${(product.description_override ?? variant.description ?? product.default_variant.description ?? "").replaceAll(`
`,"<br>")}</span>`
          }
          html += `</div><div class="elProductCardPriceContainer">`
          if (priceDisplayType == "multiple") {
            if (variant.prices.length == 1) {
              html += `<div class="elProductCardInfoPrice">`
              if (showCompareAtPrice) {
                const compare_at_amount = selected_price.compare_at_amount;
                if (compare_at_amount) {
                  html += `<span class="elProductCardAnchorPrice">${compare_at_amount}</span>`
                }
              }
              html += `<span class="elProductCardFinalPrice">${selected_price.name}</span></div>`
            } else {
              html += `<div class="elSelectWrapper"><select name="variant_price">`
              const c28 = variant.prices
              const fl29 = new CF2ForloopDrop(c28.length)
              for (const price of c28) {
                const forloop = fl29
                html += `<option `
                if (selected_price.id == price.id) {
                  html += `selected`
                }
                html += ` value="${price.id}">${price.name}</option>`
                forloop.next();
              }
              html += `</select><div class="elSelectArrow"><i class="fas fa-chevron-down"></i></div></div>`
            }
          } else if (priceDisplayType == "single") {
            html += `<div class="elProductCardInfoPrice">`
            if (showCompareAtPrice) {
              const compare_at_amount = selected_price.compare_at_amount;
              if (compare_at_amount) {
                html += `<span class="elProductCardAnchorPrice">${compare_at_amount}</span>`
              }
            }
            html += `<span class="elProductCardFinalPrice">${selected_price.name}</span></div>`
          }
          html += `</div></div></div>`
          if (showProductVariantSelection) {
            if (customVariants.length > 1) {
              html += `<div class="elProductSelectSelectorsWrapper">`
              const c30 = product.sorted_property_values
              const fl31 = new CF2ForloopDrop(c30.length)
              for (const property_values of c30) {
                const forloop = fl31
                const property_id = property_values.property_id;
                const select_index = forloop.index0;
                html += `    `
                html += `<div class="elSelectWrapper"><select class="elVariantSelector" name="property_${property_id}">`
                const c32 = property_values.value_ids
                const fl33 = new CF2ForloopDrop(c32.length)
                for (const value_id of c32) {
                  const forloop = fl33
                  html += `<option `
                  if (variant.property_value_ids[select_index] == value_id) {
                    html += `selected`
                  }
                  html += ` value="${value_id}">${product.all_properties_values[value_id]}</option>`
                  forloop.next();
                }
                html += `</select><div class="elSelectArrow"><i class="fas fa-chevron-down"></i></div></div>`
                forloop.next();
              }
              html += `</div>`
            }
          }
          if (showManageOrders) {
            const sortedOrderIds = Checkout.orderDetailsByProductIdGet[product.id].sortedOrderIds;
            let active_order = false;
            let canceled_order = false;
            const amount_of_orders = sortedOrderIds.length;
            const c34 = sortedOrderIds
            const fl35 = new CF2ForloopDrop(c34.length)
            for (const sortedOrderId of c34) {
              const forloop = fl35
              const order = Checkout.orderDetailsByProductIdGet[product.id].orders[sortedOrderId];
              if (order.reactivation == true) {
                canceled_order = true;
              } else {
                active_order = true;
              }
              forloop.next();
            }
            if (amount_of_orders && amount_of_orders > 0) {
              html += `<div class="elProductCardSubscriptionsBanner"><i class="fas fa-sync-alt"></i>`
              if (active_order && canceled_order) {
                if (amount_of_orders > 1) {
                  html += `<span>${amount_of_orders} Existing Subscriptions</span>`
                } else {
                  html += `<span>${amount_of_orders} Existing Subscription</span>`
                }
              } else if (canceled_order) {
                html += ` `
                if (amount_of_orders > 1) {
                  html += `<span>${amount_of_orders} Canceled Subscriptions</span>`
                } else {
                  html += `<span>${amount_of_orders} Canceled Subscription</span>`
                }
              } else {
                if (amount_of_orders > 1) {
                  html += `<span>${amount_of_orders} Active Subscriptions</span>`
                } else {
                  html += `<span>${amount_of_orders} Active Subscription</span>`
                }
              }
              html += `</div>`
              html += `<div class="elProductCardManageOrders`
              if (!isChecked) {
                html += ` elHide`
              }
              html += `"><div class="elProductCardOrdersWrapper"><div class="elProductCardTitle"><span>Manage Subscriptions</span><div></div></div><div class="elProductCardOrders">`
              const c36 = sortedOrderIds
              const fl37 = new CF2ForloopDrop(c36.length)
              for (const sortedOrderId of c36) {
                const forloop = fl37
                const order = Checkout.orderDetailsByProductIdGet[product.id].orders[sortedOrderId];
                html += `<div class="elProductCardOrder`
                if (selectedOrderId == order.id) {
                  html += ` elProductCardOrderSelected`
                }
                html += `" data-order-id="${sortedOrderId}"><div class="elProductCardOrderDetails"><div class="elProductCardOrderDetailsNumber"><i class="fas fa-receipt"></i><span>Order ${order.number}</span></div>`
                if (order.reactivation) {
                  html += `<span class="elProductCardOrderDetailsReactivate">Reactivate</span>`
                } else {
                  html += `<span class="elProductCardOrderDetailsManage">Manage</span>`
                }
                html += `</div>`
                if (selectedOrderId == order.id) {
                  html += `<div class="elProductCardOrderActions"><a data-page-element="LinkNode" class="elTypographyLink elProductCardShowOrderDetailsLink id-LinkNode" href="#show-selected-order-details" target="_self" data-selected="{% is_current_path '#show-selected-order-details' %}" data-liquid-replace="item"><span>View Order Details</span></a>`
                  const updatableCartItemIds = Checkout.orderDetailsByProductIdGet[product.id].orders[order.id].updatableCartItemIds;
                  const selectedUpdatableCartItem = Checkout.itemByCartItemIdGet[selectedUpdatableCartItemId];
                  if (updatableCartItemIds && updatableCartItemIds.length > 0) {
                    html += `<div class="elCustomSelect"><div class="elCustomSelectPreview"><span class="elCustomSelectPreviewText"><span class="elCustomSelectPreviewDirection" data-direction="${selectedUpdatableCartItem.orderDetails.extraData.direction}">${selectedUpdatableCartItem.orderDetails.extraData.direction} To </span> <span class="elCustomSelectPreviewInfo">${selectedUpdatableCartItem.variant.name} - ${selectedUpdatableCartItem.price.name}</span></span><div class="elSelectArrow"><i class="fas fa-chevron-down"></i></div></div><select name="upgradeDowngrade">`
                    const c38 = updatableCartItemIds
                    const fl39 = new CF2ForloopDrop(c38.length)
                    for (const updatableCartItemId of c38) {
                      const forloop = fl39
                      const updatableItem = Checkout.itemByCartItemIdGet[updatableCartItemId];
                      html += `<option `
                      if (updatableCartItemId == selectedUpdatableCartItemId) {
                        html += `selected`
                      }
                      html += ` value="${updatableCartItemId}">${updatableItem.orderDetails.extraData.direction} To ${updatableItem.variant.name} - ${updatableItem.price.name}</option>`
                      forloop.next();
                    }
                    html += `</select></div>`
                  } else {
                    if (customVariants.length > 1) {
                      html += `<div class="elCustomSelect"><div class="elCustomSelectPreview"><span class="elCustomSelectPreviewText"><span class="elCustomSelectPreviewDirection" data-direction="Reactivate">Reactivate</span> <span class="elCustomSelectPreviewInfo">${variant.name}</span></span><div class="elSelectArrow"><i class="fas fa-chevron-down"></i></div></div><select class="elVariantSelector">`
                      const c40 = product.variants
                      const fl41 = new CF2ForloopDrop(c40.length)
                      for (const product_variant of c40) {
                        const forloop = fl41
                        let combined_variant_name = "";
                        const c42 = product.sorted_property_values
                        const fl43 = new CF2ForloopDrop(c42.length)
                        for (const property_values of c42) {
                          const forloop = fl43
                          const index0 = forloop.index0;
                          const property_id = property_values.property_id;
                          const property_value_id = product_variant.property_value_ids[index0];
                          if (combined_variant_name != "") {
                            combined_variant_name = combined_variant_name.toString().concat(", ");
                          }
                          combined_variant_name = combined_variant_name.toString().concat(product.all_properties[property_id]);
                          combined_variant_name = combined_variant_name.toString().concat(": ");
                          combined_variant_name = combined_variant_name.toString().concat(product.all_properties_values[property_value_id]);
                          forloop.next();
                        }
                        html += `<option value="${product_variant.property_value_ids}" `
                        if (product_variant.id == variant.id) {
                          html += `selected`
                        }
                        html += `>${combined_variant_name}</option>`
                        forloop.next();
                      }
                      html += `</select></div>`
                    }
                    if (priceDisplayType == "single" && variant.prices.length > 1) {
                      html += `<div class="elProductSelectSelectorsWrapper"><div class="elSelectWrapper"><select name="variant_price">`
                      const c44 = variant.prices
                      const fl45 = new CF2ForloopDrop(c44.length)
                      for (const price of c44) {
                        const forloop = fl45
                        html += `<option `
                        if (selected_price.id == price.id) {
                          html += `selected`
                        }
                        html += ` value="${price.id}">${price.name}</option>`
                        forloop.next();
                      }
                      html += `</select><div class="elSelectArrow"><i class="fas fa-chevron-down"></i></div></div></div>`
                    }
                  }
                  html += `</div>`
                }
                html += `</div>`
                forloop.next();
              }
              html += `</div></div>`
              if (can_purchase_again) {
                html += `<div class="elProductCardNewOrderWrapper"><div class="elProductCardTitle"><span>Or Purchase Again</span><div></div></div><div class="elProductCardNewOrder`
                if (newOrder == true) {
                  html += ` elProductCardOrderSelected`
                }
                html += `"><div class="elProductCardNewOrderTitle"><i class="fas fa-plus-circle"></i><span>New Order</span></div>`
                if (newOrder == true) {
                  html += `<div class="elProductCardOrderActions">`
                  if (customVariants.length > 1) {
                    html += `<div class="elProductSelectSelectorsWrapper">`
                    const c46 = product.sorted_property_values
                    const fl47 = new CF2ForloopDrop(c46.length)
                    for (const property_values of c46) {
                      const forloop = fl47
                      const property_id = property_values.property_id;
                      const select_index = forloop.index0;
                      html += `    `
                      html += `<div class="elSelectWrapper"><select class="elVariantSelector" name="property_${property_id}">`
                      const c48 = property_values.value_ids
                      const fl49 = new CF2ForloopDrop(c48.length)
                      for (const value_id of c48) {
                        const forloop = fl49
                        html += `<option `
                        if (variant.property_value_ids[select_index] == value_id) {
                          html += `selected`
                        }
                        html += ` value="${value_id}">${product.all_properties_values[value_id]}</option>`
                        forloop.next();
                      }
                      html += `</select><div class="elSelectArrow"><i class="fas fa-chevron-down"></i></div></div>`
                      forloop.next();
                    }
                    html += `</div>`
                  }
                  if (priceDisplayType == "single" && variant.prices.length > 1) {
                    html += `<div class="elProductSelectSelectorsWrapper"><div class="elSelectWrapper"><select name="variant_price">`
                    const c50 = variant.prices
                    const fl51 = new CF2ForloopDrop(c50.length)
                    for (const price of c50) {
                      const forloop = fl51
                      html += `<option `
                      if (selected_price.id == price.id) {
                        html += `selected`
                      }
                      html += ` value="${price.id}">${price.name}</option>`
                      forloop.next();
                    }
                    html += `</select><div class="elSelectArrow"><i class="fas fa-chevron-down"></i></div></div></div>`
                  }
                  html += `</div>`
                }
                html += `</div></div>`
              }
              html += `</div>`
            }
          }
        } else {
          html += `<div class="elProductLayoutBackground"></div>`
          if (selectType == "quantity") {
            html += `<div class="elProductInputWrapper elProductCardQuantityContainer"><div class="elProductInputControls"><button class="elProductCardInput">-</button><input class="elProductCardInput elProductCardShortInput" name="product" value="${quantity ?? 0}" min="0" type="number"/><button class="elProductCardInput">+</button></div></div>`
          } else if (selectType == "quantity-forced") {
            html += `<div class="elProductInputWrapper elSelectWrapper elForcedQuantityWrapper"><select name="product" class="elProductCardInput elProductInputControls">`
            const step = product.step ?? 2;
            const min = product.min ?? 0;
            const max = product.max ?? 5;
            const item_count_step_1 = max- min;
            const item_count_step_2 = item_count_step_1/ step;
            const item_count_step_3 = item_count_step_2| 0;
            const range = Array.apply(0, Array(item_count_step_3)).map((element, index) => index + 0);;
            const c52 = range
            const fl53 = new CF2ForloopDrop(c52.length)
            for (const i of c52) {
              const forloop = fl53
              const value = i* step+ min;
              html += `<option value="${value}">${value}</option>`
              forloop.next();
            }
            html += `</select><div class="elSelectArrow elForcedQuantitySelectArrow"><i class="fas fa-chevron-down"></i></div></div>`
          }
          const isClassic = layout == "classic";
          const isQuantityType = selectType == "quantity";
          html += `<div class="elProductCardSelectInput`
          if (isClassic == false || isQuantityType == true) {
            html += ` hide`
          }
          html += `">`
          if (selectType == "single") {
            if (isChecked) {
              html += `<div data-page-element="Radio/V1" class="elRadioWrapper id-Radio/V1" data-liquid-replace="item" data-checked="true" data-trigger-click-on-wrapper="false"><label class="elRadioLabel"><input type="radio" value="" name="product" checked class="elRadioInput"/><div class="elRadio"><div class="elRadioIcon"></div></div><span class="elRadioText"></span></label></div>`
            } else {
              html += `<div data-page-element="Radio/V1" class="elRadioWrapper id-Radio/V1" data-liquid-replace="item" data-checked="" data-trigger-click-on-wrapper="false"><label class="elRadioLabel"><input type="radio" value="" name="product"  class="elRadioInput"/><div class="elRadio"><div class="elRadioIcon"></div></div><span class="elRadioText"></span></label></div>`
            }
          } else if (selectType == "multiple" || selectType == "quantity") {
            if (isChecked) {
              html += `<div data-page-element="Checkbox/V1" type="" class="elCheckboxWrapper elFormItemWrapper de-input-block id-Checkbox/V1" data-liquid-replace="item" data-prevent-click-on-label="false" data-trigger-click-on-wrapper="false" data-checked="true"><label class="elCheckboxLabel"><input type="checkbox" value="${product.id}" name="product" checked data-prevent-garlic="false" class="elCheckboxInput inputHolder"/><div class="elCheckbox"><div class="elCheckboxIcon"><div class="elCheckboxIconSquare hide"></div><i class="elCheckboxIconCheck fas fa-check"></i></div></div><span class="elCheckboxText"></span></label><div data-input-status-type="true" data-error-container="true"></div></div>`
            } else {
              html += `<div data-page-element="Checkbox/V1" type="" class="elCheckboxWrapper elFormItemWrapper de-input-block id-Checkbox/V1" data-liquid-replace="item" data-prevent-click-on-label="false" data-trigger-click-on-wrapper="false" data-checked=""><label class="elCheckboxLabel"><input type="checkbox" value="${product.id}" name="product"  data-prevent-garlic="false" class="elCheckboxInput inputHolder"/><div class="elCheckbox"><div class="elCheckboxIcon"><div class="elCheckboxIconSquare hide"></div><i class="elCheckboxIconCheck fas fa-check"></i></div></div><span class="elCheckboxText"></span></label><div data-input-status-type="true" data-error-container="true"></div></div>`
            }
          }
          html += `</div>`
          if (showImage) {
            const imageUrl = variant.image ?? product.image;
            if (imageUrl && imageUrl != "") {
              html += `<div class="elProductCardImage"><div data-page-element="Image/V2" class="elImageWrapper de-image-block id-Image/V2`
              if (true && !imageUrl && !null && false == false) {
                html += ` forceHide`
              }
              html += `" data-liquid-replace="item">`
              if (imageUrl || !!null) {
                html += `<img class="elImage" src="${imageUrl ?? null}"/>`
              } else if (false) {
                html += `<div class="image-placeholder" title="This element will render with content once configured."><span class="image-placeholder-header">[Replaced by Content]</span></div>`
              }
              html += `</div></div>`
            }
          }
          html += `<div class="elProductCardNameContainer">`
          if (featured && highlight_text) {
            html += `<div class="elProductCardHighlight">${highlight_text}</div>`
          }
          if (customVariants.length > 1) {
            html += `<span class="elProductCardInfoName">${product.default_variant.name}</span>`
          } else {
            html += `<span class="elProductCardInfoName">${variant.name}</span>`
          }
          if (showDescription) {
            html += `<span class="elProductCardInfoDescription">${(product.description_override ?? variant.description ?? product.default_variant.description ?? "").replaceAll(`
`,"<br>")}</span>`
          }
          html += `</div>`
          html += `<div class="elProductCardPriceContainer">`
          if (priceDisplayType == "multiple") {
            if (variant.prices.length == 1) {
              html += `<div class="elProductCardInfoPrice">`
              if (showCompareAtPrice) {
                const compare_at_amount = selected_price.compare_at_amount;
                if (compare_at_amount) {
                  html += `<span class="elProductCardAnchorPrice">${compare_at_amount}</span>`
                }
              }
              html += `<span class="elProductCardFinalPrice">${selected_price.name}</span></div>`
            } else {
              html += `<div class="elSelectWrapper"><select name="variant_price">`
              const c54 = variant.prices
              const fl55 = new CF2ForloopDrop(c54.length)
              for (const price of c54) {
                const forloop = fl55
                html += `<option `
                if (selected_price.id == price.id) {
                  html += `selected`
                }
                html += ` value="${price.id}">${price.name}</option>`
                forloop.next();
              }
              html += `</select><div class="elSelectArrow"><i class="fas fa-chevron-down"></i></div></div>`
            }
          } else if (priceDisplayType == "single") {
            html += `<div class="elProductCardInfoPrice">`
            if (showCompareAtPrice) {
              const compare_at_amount = selected_price.compare_at_amount;
              if (compare_at_amount) {
                html += `<span class="elProductCardAnchorPrice">${compare_at_amount}</span>`
              }
            }
            html += `<span class="elProductCardFinalPrice">${selected_price.name}</span></div>`
          }
          html += `</div>`
          if (showProductVariantSelection) {
            if (customVariants.length > 1) {
              html += `<div class="elProductSelectSelectorsWrapper">`
              const c56 = product.sorted_property_values
              const fl57 = new CF2ForloopDrop(c56.length)
              for (const property_values of c56) {
                const forloop = fl57
                const property_id = property_values.property_id;
                const select_index = forloop.index0;
                html += `    `
                html += `<div class="elSelectWrapper"><select class="elVariantSelector" name="property_${property_id}">`
                const c58 = property_values.value_ids
                const fl59 = new CF2ForloopDrop(c58.length)
                for (const value_id of c58) {
                  const forloop = fl59
                  html += `<option `
                  if (variant.property_value_ids[select_index] == value_id) {
                    html += `selected`
                  }
                  html += ` value="${value_id}">${product.all_properties_values[value_id]}</option>`
                  forloop.next();
                }
                html += `</select><div class="elSelectArrow"><i class="fas fa-chevron-down"></i></div></div>`
                forloop.next();
              }
              html += `</div>`
            }
          }
          if (showManageOrders) {
            const sortedOrderIds = Checkout.orderDetailsByProductIdGet[product.id].sortedOrderIds;
            let active_order = false;
            let canceled_order = false;
            const amount_of_orders = sortedOrderIds.length;
            const c60 = sortedOrderIds
            const fl61 = new CF2ForloopDrop(c60.length)
            for (const sortedOrderId of c60) {
              const forloop = fl61
              const order = Checkout.orderDetailsByProductIdGet[product.id].orders[sortedOrderId];
              if (order.reactivation == true) {
                canceled_order = true;
              } else {
                active_order = true;
              }
              forloop.next();
            }
            if (amount_of_orders && amount_of_orders > 0) {
              html += `<div class="elProductCardSubscriptionsBanner"><i class="fas fa-sync-alt"></i>`
              if (active_order && canceled_order) {
                if (amount_of_orders > 1) {
                  html += `<span>${amount_of_orders} Existing Subscriptions</span>`
                } else {
                  html += `<span>${amount_of_orders} Existing Subscription</span>`
                }
              } else if (canceled_order) {
                html += ` `
                if (amount_of_orders > 1) {
                  html += `<span>${amount_of_orders} Canceled Subscriptions</span>`
                } else {
                  html += `<span>${amount_of_orders} Canceled Subscription</span>`
                }
              } else {
                if (amount_of_orders > 1) {
                  html += `<span>${amount_of_orders} Active Subscriptions</span>`
                } else {
                  html += `<span>${amount_of_orders} Active Subscription</span>`
                }
              }
              html += `</div>`
              html += `<div class="elProductCardManageOrders`
              if (!isChecked) {
                html += ` elHide`
              }
              html += `"><div class="elProductCardOrdersWrapper"><div class="elProductCardTitle"><span>Manage Subscriptions</span><div></div></div><div class="elProductCardOrders">`
              const c62 = sortedOrderIds
              const fl63 = new CF2ForloopDrop(c62.length)
              for (const sortedOrderId of c62) {
                const forloop = fl63
                const order = Checkout.orderDetailsByProductIdGet[product.id].orders[sortedOrderId];
                html += `<div class="elProductCardOrder`
                if (selectedOrderId == order.id) {
                  html += ` elProductCardOrderSelected`
                }
                html += `" data-order-id="${sortedOrderId}"><div class="elProductCardOrderDetails"><div class="elProductCardOrderDetailsNumber"><i class="fas fa-receipt"></i><span>Order ${order.number}</span></div>`
                if (order.reactivation) {
                  html += `<span class="elProductCardOrderDetailsReactivate">Reactivate</span>`
                } else {
                  html += `<span class="elProductCardOrderDetailsManage">Manage</span>`
                }
                html += `</div>`
                if (selectedOrderId == order.id) {
                  html += `<div class="elProductCardOrderActions"><a data-page-element="LinkNode" class="elTypographyLink elProductCardShowOrderDetailsLink id-LinkNode" href="#show-selected-order-details" target="_self" data-selected="{% is_current_path '#show-selected-order-details' %}" data-liquid-replace="item"><span>View Order Details</span></a>`
                  const updatableCartItemIds = Checkout.orderDetailsByProductIdGet[product.id].orders[order.id].updatableCartItemIds;
                  const selectedUpdatableCartItem = Checkout.itemByCartItemIdGet[selectedUpdatableCartItemId];
                  if (updatableCartItemIds && updatableCartItemIds.length > 0) {
                    html += `<div class="elCustomSelect"><div class="elCustomSelectPreview"><span class="elCustomSelectPreviewText"><span class="elCustomSelectPreviewDirection" data-direction="${selectedUpdatableCartItem.orderDetails.extraData.direction}">${selectedUpdatableCartItem.orderDetails.extraData.direction} To </span> <span class="elCustomSelectPreviewInfo">${selectedUpdatableCartItem.variant.name} - ${selectedUpdatableCartItem.price.name}</span></span><div class="elSelectArrow"><i class="fas fa-chevron-down"></i></div></div><select name="upgradeDowngrade">`
                    const c64 = updatableCartItemIds
                    const fl65 = new CF2ForloopDrop(c64.length)
                    for (const updatableCartItemId of c64) {
                      const forloop = fl65
                      const updatableItem = Checkout.itemByCartItemIdGet[updatableCartItemId];
                      html += `<option `
                      if (updatableCartItemId == selectedUpdatableCartItemId) {
                        html += `selected`
                      }
                      html += ` value="${updatableCartItemId}">${updatableItem.orderDetails.extraData.direction} To ${updatableItem.variant.name} - ${updatableItem.price.name}</option>`
                      forloop.next();
                    }
                    html += `</select></div>`
                  } else {
                    if (customVariants.length > 1) {
                      html += `<div class="elCustomSelect"><div class="elCustomSelectPreview"><span class="elCustomSelectPreviewText"><span class="elCustomSelectPreviewDirection" data-direction="Reactivate">Reactivate</span> <span class="elCustomSelectPreviewInfo">${variant.name}</span></span><div class="elSelectArrow"><i class="fas fa-chevron-down"></i></div></div><select class="elVariantSelector">`
                      const c66 = product.variants
                      const fl67 = new CF2ForloopDrop(c66.length)
                      for (const product_variant of c66) {
                        const forloop = fl67
                        let combined_variant_name = "";
                        const c68 = product.sorted_property_values
                        const fl69 = new CF2ForloopDrop(c68.length)
                        for (const property_values of c68) {
                          const forloop = fl69
                          const index0 = forloop.index0;
                          const property_id = property_values.property_id;
                          const property_value_id = product_variant.property_value_ids[index0];
                          if (combined_variant_name != "") {
                            combined_variant_name = combined_variant_name.toString().concat(", ");
                          }
                          combined_variant_name = combined_variant_name.toString().concat(product.all_properties[property_id]);
                          combined_variant_name = combined_variant_name.toString().concat(": ");
                          combined_variant_name = combined_variant_name.toString().concat(product.all_properties_values[property_value_id]);
                          forloop.next();
                        }
                        html += `<option value="${product_variant.property_value_ids}" `
                        if (product_variant.id == variant.id) {
                          html += `selected`
                        }
                        html += `>${combined_variant_name}</option>`
                        forloop.next();
                      }
                      html += `</select></div>`
                    }
                    if (priceDisplayType == "single" && variant.prices.length > 1) {
                      html += `<div class="elProductSelectSelectorsWrapper"><div class="elSelectWrapper"><select name="variant_price">`
                      const c70 = variant.prices
                      const fl71 = new CF2ForloopDrop(c70.length)
                      for (const price of c70) {
                        const forloop = fl71
                        html += `<option `
                        if (selected_price.id == price.id) {
                          html += `selected`
                        }
                        html += ` value="${price.id}">${price.name}</option>`
                        forloop.next();
                      }
                      html += `</select><div class="elSelectArrow"><i class="fas fa-chevron-down"></i></div></div></div>`
                    }
                  }
                  html += `</div>`
                }
                html += `</div>`
                forloop.next();
              }
              html += `</div></div>`
              if (can_purchase_again) {
                html += `<div class="elProductCardNewOrderWrapper"><div class="elProductCardTitle"><span>Or Purchase Again</span><div></div></div><div class="elProductCardNewOrder`
                if (newOrder == true) {
                  html += ` elProductCardOrderSelected`
                }
                html += `"><div class="elProductCardNewOrderTitle"><i class="fas fa-plus-circle"></i><span>New Order</span></div>`
                if (newOrder == true) {
                  html += `<div class="elProductCardOrderActions">`
                  if (customVariants.length > 1) {
                    html += `<div class="elProductSelectSelectorsWrapper">`
                    const c72 = product.sorted_property_values
                    const fl73 = new CF2ForloopDrop(c72.length)
                    for (const property_values of c72) {
                      const forloop = fl73
                      const property_id = property_values.property_id;
                      const select_index = forloop.index0;
                      html += `    `
                      html += `<div class="elSelectWrapper"><select class="elVariantSelector" name="property_${property_id}">`
                      const c74 = property_values.value_ids
                      const fl75 = new CF2ForloopDrop(c74.length)
                      for (const value_id of c74) {
                        const forloop = fl75
                        html += `<option `
                        if (variant.property_value_ids[select_index] == value_id) {
                          html += `selected`
                        }
                        html += ` value="${value_id}">${product.all_properties_values[value_id]}</option>`
                        forloop.next();
                      }
                      html += `</select><div class="elSelectArrow"><i class="fas fa-chevron-down"></i></div></div>`
                      forloop.next();
                    }
                    html += `</div>`
                  }
                  if (priceDisplayType == "single" && variant.prices.length > 1) {
                    html += `<div class="elProductSelectSelectorsWrapper"><div class="elSelectWrapper"><select name="variant_price">`
                    const c76 = variant.prices
                    const fl77 = new CF2ForloopDrop(c76.length)
                    for (const price of c76) {
                      const forloop = fl77
                      html += `<option `
                      if (selected_price.id == price.id) {
                        html += `selected`
                      }
                      html += ` value="${price.id}">${price.name}</option>`
                      forloop.next();
                    }
                    html += `</select><div class="elSelectArrow"><i class="fas fa-chevron-down"></i></div></div></div>`
                  }
                  html += `</div>`
                }
                html += `</div></div>`
              }
              html += `</div>`
            }
          }
        }

      }

      this.element.innerHTML = html;

      if (initializeChildrenInstances) {
        CF2Component.hydrateTree(this.element);
      }
    }


}

window["CheckoutProductCardV2"] = CheckoutProductCardV2

