
          
          
          import { CF2ComponentSingleton } from 'javascript/lander/runtime'

  class CheckoutHelpersCartV1 extends CF2ComponentSingleton {
    ITEM_TYPES = {
      REGULAR: 'regularItem',
      UPGRADE_DOWNGRADE: 'upgradeDowngradeItem',
      REACTIVATE: 'reactivateItem',
    }

    buildCartItemId(productId, variantId, priceId) {
      return `${this.ITEM_TYPES.REGULAR}_${productId}_${variantId}_${priceId}`
    }

    buildCartUpdatableItemId(type, orderId, lineItemId, productId, variantId, priceId) {
      return `${type}_${orderId}_${lineItemId}_${productId}_${variantId}_${priceId}`
    }

    isCartItemIdUpdatable(id) {
      return [this.ITEM_TYPES.UPGRADE_DOWNGRADE, this.ITEM_TYPES.REACTIVATE].includes(this.getCartItemType(id))
    }

    isCartItemIdReactivate(id) {
      return [this.ITEM_TYPES.REACTIVATE].includes(this.getCartItemType(id))
    }

    getCartIdDetails(id) {
      if (this.isCartItemIdUpdatable(id)) {
        const [type, orderId, lineItemId, productId, variantId, priceId] = id.split('_')
        return {
          type,
          orderId,
          lineItemId,
          productId,
          variantId,
          priceId,
        }
      } else {
        const [type, productId, variantId, priceId] = id.split('_')
        return {
          type,
          productId,
          variantId,
          priceId,
        }
      }
    }

    storeCartItem(id, product, variant, price, orderDetails = null) {
      const data = this.buildCartItemData(product, variant, price, orderDetails)
      Checkout.store.itemByCartItemId.setKey(id, data)
    }

    buildCartItemData(product, variant, price, orderDetails = null) {
      return { product, variant, price, orderDetails }
    }

    getCartItemType(id) {
      return id.split('_')[0]
    }

    add(id, quantity = 1) {
      return {
        type: 'add',
        id,
        payload: {
          quantity,
        }
      }
    }

    remove(id) {
      return {
        type: 'remove',
        id,
      }
    }

    replace(id, newId) {
      return {
        type: 'replace',
        id,
        payload: {
          newId,
        }
      }
    }

    dispatchEvents(events) {
      events = Array.isArray(events) ? events : [events]
      let clickedCartItemIds = [...Checkout.store.clickedCartItemIds.get()]
      let cart = [...Checkout.store.cart.get()]
      let updatePerformed = false
      events.forEach((event) => {
        const index = cart.findIndex((item) => event.id == item.id)
        const clickedCartItemIndex = clickedCartItemIds.findIndex((cartId) => event.id)

        switch (event.type) {
          case 'add': {
            const quantity = event.payload.quantity
            if (index == -1) {
              updatePerformed = true
              clickedCartItemIds.push(event.id)
              cart.push({
                id: event.id,
                quantity,
              })
            } else {
              updatePerformed = updatePerformed || cart[index].quantity != quantity
              if (cart[index].quantity != quantity) {
                clickedCartItemIds.push(event.id)
              }
              cart[index].quantity = quantity
            }
            break
          }
          case 'remove': {
            if (index > -1) {
              updatePerformed = true
              clickedCartItemIds.splice(clickedCartItemIndex, 1)
              cart.splice(index, 1)
            }
            break
          }
          case 'replace': {
            if (index > -1) {
              updatePerformed = true
              const newId = event.payload.newId
              clickedCartItemIds[clickedCartItemIndex] = newId
              cart[index].id = newId
            }
            break
          }
        }
      })
      if(updatePerformed) {
        Checkout.store.cart.set(cart)
        // NOTE: eliminate duplicates which occur in the begin of array
        clickedCartItemIds = clickedCartItemIds.reverse().filter((v, index, arr) => arr.indexOf(v) == index).reverse()
        Checkout.store.clickedCartItemIds.set(clickedCartItemIds)
      }
    }
  }
  const checkoutHelpersCartV1 = CheckoutHelpersCartV1.getInstance()
  export default checkoutHelpersCartV1
        